import React, { useState } from 'react'
import { Buffer } from "buffer";

// Material Dashboard 2 PRO React examples
import MDBox from "components/MDBox";

// @mui material components
import { Card, Grid, Icon } from "@mui/material";

//Hooks
import { useMassiveStore } from "hooks";
import { useUserStore } from 'hooks';

// Telerik
import { ComboBox } from "@progress/kendo-react-dropdowns";
import { filterBy } from "@progress/kendo-data-query";
import { Button } from "@progress/kendo-react-buttons";
import { ChunkProgressBar } from "@progress/kendo-react-progressbars";
import { process } from "@progress/kendo-data-query";
import { Grid as GridTelerik, GridToolbar, GridColumn as Column } from "@progress/kendo-react-grid";
import { Dialog } from "@progress/kendo-react-dialogs";
import { Notification, NotificationGroup } from "@progress/kendo-react-notification";
import { Loader } from "@progress/kendo-react-indicators";
import { Form, FormElement } from "@progress/kendo-react-form";
import { Stepper, Step } from "@progress/kendo-react-layout";


//import './stylestep.css';

export const ActivationData = () => {

    const { loadCompanyData, apiCompanyData, errorUserMessage, statusUserLoad } = useUserStore();
    const { listUnsentEmails, forwardMassiveEmails, sendMassiveEmails, apiSendEmails, statusMassiveEmails,
        apiUnsentEmails,
        apiForwardEmails,
        errorSendEmails,
        errorUnsentEmails, syncMassiveTower,
        errorForwardEmails,

        //Sync Tower
        apiSyncTower,
        errorSyncTower,
        statusSyncTower,

        //Activation Tower
        statusActivationTower,
        apiActivationTower,
        errorActivationTower,
        activationTower,

        //Clear Status
        clearStatusTowerMassive,
    } = useMassiveStore();

    const initialDataState = {
        sort: [
            {
                field: "locality.id_localidad",
                dir: "desc",
            },
        ],
        take: 5,
        skip: 0,
    };

    const position = {
        bottomRight: {
            bottom: 0,
            right: 0,
            alignItems: "flex-end",
        },
        topRight: {
            top: 5,
            left: "95%",
            alignItems: "flex-end",
        },
        bottomLeft: {
            bottom: 0,
            left: "96%",
            alignItems: "flex-start",
        },
    }

    const darkModeStyle = {
        // color: darkMode ? "rgb(255, 255, 255)" : "rgb(0, 0, 0)",
        color: "rgb(0, 0, 0)",
    };

    const base64dataEnterprises = localStorage.getItem("key1");
    const Enterprises = Buffer.from(base64dataEnterprises, "base64").toString(
        "ascii"
    );
    const dataEnterprises = JSON.parse(Enterprises);


    const userData = localStorage.getItem("user");

    const [enterprise, setEnterprise] = React.useState(dataEnterprises[0]);
    const handleEntChange = (event) => {
        setEnterprise(event.target.value);
        setStep(0)
        setIconColors(0)
        setIconSyncColors(0)
        setActivationFinish(0)
        setCompany("")
        setFilter("")

    };

    const base64dataCompanies = localStorage.getItem("key2");
    const companies = Buffer.from(base64dataCompanies, "base64").toString(
        "ascii"
    );
    const dataCompanies = JSON.parse(companies);
    const [loading, setLoading] = React.useState(false);

    const [company, setCompany] = React.useState(dataCompanies[0]);
    const [filter, setFilter] = React.useState(dataCompanies[0].company_name);
    const [adaptiveFilter, setAdaptiveFilter] = React.useState("");

    //Se setea los valores de los steps para el proceso de sync
    const [value, setValue] = React.useState(20);
    const [step, setStep] = React.useState(0);
    const [credentialsButtons, setCredentialsButtons] = React.useState(false)
    const [dialogDetails, setDialogDetails] = React.useState(false)
    const [syncNext, setSyncNext] = React.useState(true)
    const [stepButtons, setStepButtons] = React.useState(true)
    const [credentialsNext, setCredentialsNext] = React.useState(false)
    const [credentialsNextDisable, setCredentialsNextDisable] = React.useState(false)
    const [credentialsDetails, setCredentialsDetails] = React.useState(false)
    const [iconColors, setIconColors] = React.useState(0)
    const [iconSyncColors, setIconSyncColors] = React.useState(0)
    const [activationFinish, setActivationFinish] = React.useState(0)
    //Finaliza los steps

    const handleConChange = (event) => {
        setCompany(event.target.value);
        setFilter(event.value.company_name);
        setStep(0)
        setIconColors(0)
        setIconSyncColors(0)
        setActivationFinish(0)
        setAdaptiveFilter("");
    };

    const [companiesData, setCompaniesData] = React.useState([]);
    const filterData = (f) => filterBy(companiesData.slice(), f);
    const filterChange = (event) => {
        //console.log(event)
        const request_companies = {
            user_uuid: userData,
            enterprise_uuid: enterprise.enterprise_uuid,
        };
        if (event.filter.value === '') {

            loadCompanyData(request_companies)
        }
        if (event.target.mobileMode) {
            setAdaptiveFilter(event.filter.value);
        } else {
            setFilter(event.filter.value);
            //loadCompanyData(request_companies)
        }
        setCompaniesData(filterData(event.filter))
    };

    const handleClose = () => {
        setAdaptiveFilter("");
        setStep(0)
        setIconColors(0)
        setIconSyncColors(0)
        setActivationFinish(0)
    };

    const handleClickCompany = (event) => {
        const request_companies = {
            user_uuid: userData,
            enterprise_uuid: enterprise.enterprise_uuid,
        };
        loadCompanyData(request_companies)
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        const request_tower_data = {
            uuid_company: company.company_uuid
        }
        //loadMassiveFileData(request_tower_data)
    }


    const [notificationError, setNotificationError] = React.useState(false)
    const [notificationSuccess, setNotificationSuccess] = React.useState(false);
    const viewNotificationSuccess = (state) => {
        setNotificationSuccess(state)
    }
    const [content, setContent] = React.useState(false)
    const [syncRecord, setSyncRecord] = React.useState("")
    const viewNotificationError = (state) => {
        setNotificationError(state);
    };
    const [step2, setStep2] = React.useState(0);
    const [formState, setFormState] = React.useState({});
    const [steps, setSteps] = React.useState([
        {
            //icon: "sync_rounded",
            label: "Sincronización",
            isValid: undefined,
            //number: "1"
            //icon: "k-i-lock",
        },
        {
            // icon: "email_rounded",
            label: "Credenciales",
            isValid: undefined,
            //number: "2"
            //icon: "k-i-lock",
        },
        {
            //icon: "task_alt_rounded",
            label: "Activación",
            isValid: undefined,
            //number: "3"
            //icon: "k-i-lock"
        }
    ]);


    const lastStepIndex = steps.length - 1;
    const isLastStep = lastStepIndex === step;
    const mediumStep = step === 1;
    const isPreviousStepsValid =
        steps
            .slice(0, step)
            .findIndex((currentStep) => currentStep.isValid === false) === -1;


    const onSyncSubmit = () => {
        setLoading(true)
        if (adaptiveFilter === "" && filter === "") {
            setLoading(false)
            setContent("Seleccione una Empresa y Compañía");
            viewNotificationError(true);
            setTimeout(() => {
                viewNotificationError(false);
            }, 6000);
        } else {
            const sync_request = {
                uuid_company: company.company_uuid,
                user_process: userData,
            };
            //console.log(sync_request)
            syncMassiveTower(sync_request)
        }
    }


    const onEmailSubmit = () => {
        setLoading(true)
        if (adaptiveFilter === "" && filter === "") {
            setLoading(false)
            setContent("Seleccione una Empresa y Compañía");
            viewNotificationError(true);
            setTimeout(() => {
                viewNotificationError(false);
            }, 6000);
        } else {
            const send_email_request = {
                uuid_massive_sync_record: syncRecord,
                user_process: userData,
            };
            //console.log(send_email_request)
            sendMassiveEmails(send_email_request)
        }
    }

    const onActivationSubmit = () => {
        setLoading(true)
        if (adaptiveFilter === "" && filter === "") {
            setLoading(false)
            setContent("Seleccione una Empresa y Compañía");
            viewNotificationError(true);
            setTimeout(() => {
                viewNotificationError(false);
            }, 6000);
        } else {
            const activate_service_request = {

                uuid_company: company.company_uuid,
                user_process: userData,
            }
            //console.log(activate_service_request)
            activationTower(activate_service_request)
        }

    }





    const onClickDetails = (event) => {
        //console.log(event)
        setDialogDetails(true)
        const request_details = {
            uuid_massive_sync_record: syncRecord,
        }
        //console.log(request_details)
        listUnsentEmails(request_details)
    }

    const onPrevClick = React.useCallback(
        (event) => {
            event.preventDefault();
            setStep(() => Math.max(step - 1, 0));
            //setStep2(() => Math.max(step - 1, 0));
        },
        [step, setStep]
    );



    const retryEmailClick = (event) => {
        setLoading(true)
        const request_retry = {
            uuid_massive_sync_record: syncRecord,
            user_process: userData,
        }
       // console.log(request_retry)
        forwardMassiveEmails(request_retry)
    }

    const handleCloseDialog = (event) => {
        //console.log(event)
        setDialogDetails(false)
    }

    const handleChange = e => {
        setValue(e.value);
    };

    const gridProcess = (
        <>
            <MDBox marginTop={4}>
                <Card style={{
                    width: "100%",
                    height: "70vh"
                }}>
                    <Grid container spacing={3} p={2}>
                        <Grid item xs={12} md={12} lg={12}>
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    marginTop: "22vh"
                                }}
                            >
                                <div style={{
                                    marginLeft: "22.5vh"
                                }}>
                                    <Icon fontSize="large" sx={iconSyncColors === 0 ? { color: "rgb(26, 115, 232)" } : iconSyncColors === 2 ? { color: "rgb(232, 26, 26)" } : { color: "rgb(26, 160, 71)" }}>
                                        sync_rounded
                                    </Icon>
                                    <Icon fontSize="large" sx={iconColors === 0 ? { color: "rgb(26, 115, 232)" } : iconColors === 2 ? { color: "rgb(232, 26, 26)" } : { color: "rgb(26, 160, 71)" }}
                                        style={{
                                            marginLeft: "45vh"
                                        }}>
                                        email_rounded
                                    </Icon>
                                    <Icon fontSize="large" sx={activationFinish === 0 ? { color: "rgb(26, 115, 232)" } : activationFinish === 2 ? { color: "rgb(232, 26, 26)" } : { color: "rgb(26, 160, 71)" }}
                                        style={{
                                            marginLeft: "45.5vh"
                                        }}>
                                        task_alt_rounded
                                    </Icon>

                                </div>

                                <Stepper
                                    // value={step}
                                    // items={steps}
                                    //className="custom-stepper"
                                    value={step}
                                    //onChange={handleChange}
                                    items={steps}
                                //item={CustomStep}

                                />
                                <span />
                                <span
                                    style={{
                                        marginTop: "40px",
                                    }}
                                    className={"k-form-separator"}
                                />
                                <div
                                    style={{
                                        justifyContent: "space-between",
                                        alignContent: "center",

                                    }}
                                    className={
                                        "k-form-buttons k-button k-button-md k-rounded-md k-button-solid k-button-solid-bases-end"
                                    }
                                >
                                    <span
                                        style={{
                                            alignSelf: "center",
                                        }}
                                    >
                                        Paso {step + 1} de 3
                                    </span>
                                    {stepButtons && (
                                        <div>

                                            {credentialsDetails && (
                                                <>
                                                    <button
                                                        title="DETALLE"
                                                        className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                                                        style={{
                                                            marginRight: "10px",
                                                        }}
                                                        onClick={onClickDetails}
                                                    >
                                                        <Icon fontSize="medium" sx={{ color: "rgb(235, 234, 232)" }}>
                                                            description
                                                        </Icon>
                                                        DETALLE
                                                    </button>
                                                </>
                                            )
                                            }
                                            {credentialsNextDisable && (
                                                <>
                                                    <button
                                                        title="REINTENTAR"
                                                        className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                                                        style={{
                                                            marginRight: "10px",
                                                        }}
                                                        onClick={isLastStep ? onActivationSubmit : mediumStep ? retryEmailClick : onSyncSubmit}
                                                    >
                                                        <Icon fontSize="medium" sx={{ color: "rgb(235, 234, 232)" }}>
                                                            replay
                                                        </Icon>
                                                        REINTENTAR
                                                    </button>
                                                </>
                                            )}

                                            <Button
                                                themeColor={"primary"}
                                                disabled={credentialsNextDisable !== false}
                                                onClick={isLastStep ? onActivationSubmit : mediumStep ? onEmailSubmit : onSyncSubmit}
                                            //onClick={formRenderProps.onSubmit}
                                            >
                                                {isLastStep ? "ACTIVAR" : mediumStep ? "ENVIAR" : "SIGUIENTE"}
                                            </Button>

                                        </div>

                                    )}
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </Card>
            </MDBox>

        </>
    );




    React.useEffect(() => {
        // console.log(statusMassiveEmails)
        // console.log(apiSendEmails?.data)
        if (statusSyncTower === '1') {
            setIconSyncColors(1)
            setStep(1)
            steps[0].isValid = true
            setCredentialsNext(true)
            setSyncNext(false)
            setCredentialsDetails(false)
            setCredentialsNextDisable(false)
            setSyncRecord(apiSyncTower.data.uuid_sync_record)
            setLoading(false)
            setContent("Sincronización de Torre " + filter + " Exitosa");
            viewNotificationSuccess(true)
            setTimeout(() => {
                viewNotificationSuccess(false);
            }, 6000);
        }
        if (statusMassiveEmails === '1' || statusMassiveEmails === '3') {
            setStep(2)
            //steps[1].icon = "k-i-unlock"
            steps[1].isValid = true
            setIconColors(1)
            setContent("Envío de Credenciales Exitoso");
            setLoading(false)
            viewNotificationSuccess(true)
            setTimeout(() => {
                viewNotificationSuccess(false);
            }, 6000);
        }
        if (statusActivationTower === '1') {
            steps[2].isValid = true
            setActivationFinish(1)
            setContent("Activación De Torre " + filter + "Exitosa");
            setLoading(false)
            viewNotificationSuccess(true)
            setTimeout(() => {
                viewNotificationSuccess(false);
            }, 6000);

        }
        clearStatusTowerMassive()
    }, [statusMassiveEmails, statusSyncTower, statusActivationTower])

    const [unsentEmail, setUnsentEmail] = React.useState([])
    React.useEffect(() => {
        //console.log(apiUnsentEmails.data)
        if (apiUnsentEmails?.data !== undefined) {
            setUnsentEmail(apiUnsentEmails.data)
        }
    }, [apiUnsentEmails])

    // React.useEffect(() => {
    //     console.log(statusMassiveEmails)
    //     console.log(apiForwardEmails.data)
    //     if (statusMassiveEmails === '3') {
    //         setStep(2)
    //         steps[1].icon = "k-i-unlock"
    //     }
    // }, [statusMassiveEmails])



    React.useEffect(() => {
        console.log(errorSendEmails)
        if (errorSendEmails !== undefined) {
            console.log(errorSendEmails )
            setLoading(false)
            setCredentialsButtons(true)
            steps[1].isValid = false
            //console.log(steps[1].isValid = false)
            setCredentialsDetails(true)
            setCredentialsNextDisable(true)
            setCredentialsNext(false)
            setContent(errorSendEmails);
            viewNotificationError(true);
            setTimeout(() => {
                viewNotificationError(false);
            }, 6000);
            setNotificationError([]);
            setIconColors(2)
        }
    }, [errorSendEmails])

    React.useEffect(() => {
        if (errorForwardEmails !== undefined) {
            setLoading(false)
            console.log(errorForwardEmails + "  Error en el reintento")
            steps[1].isValid = false
            console.log(steps[1].isValid = false)
            setCredentialsNextDisable(true)
            setCredentialsDetails(true)
            setCredentialsNext(false)
            setContent(errorSendEmails);
            viewNotificationError(true);
            //setIconColors(2)
            setTimeout(() => {
                viewNotificationError(false);
            }, 6000);
            setNotificationError([]);
        }
    }, [errorForwardEmails])

    React.useEffect(() => {
        if (errorSyncTower !== undefined) {
            setLoading(false)
            console.log(errorSyncTower + "  Error en el reintento")
            steps[0].isValid = false
            console.log(steps[0].isValid = false)
            setCredentialsNextDisable(true)
            //setCredentialsDetails(false)
            setCredentialsNext(false)
            setContent(errorSyncTower);
            viewNotificationError(true);
            setIconSyncColors(2)
            setTimeout(() => {
                viewNotificationError(false);
            }, 6000);
            setNotificationError([]);

        }
    }, [errorSyncTower])

    React.useEffect(() => {
        if (errorActivationTower !== undefined && errorActivationTower !== "Compañía ya Registra Proceso de Activación de Servicio") {
            setLoading(false)
            console.log(errorActivationTower + "  Error en el reintento")
            steps[2].isValid = false
            console.log(steps[2].isValid = false)
            setCredentialsNextDisable(true)
            //setCredentialsDetails(false)
            setCredentialsNext(false)
            setContent(errorActivationTower);
            viewNotificationError(true);
            setActivationFinish(2)
            setTimeout(() => {
                viewNotificationError(false);
            }, 6000);
            setNotificationError([]);

        } if (errorActivationTower === "Compañía ya Registra Proceso de Activación de Servicio") {
            setLoading(false)
            steps[2].isValid = true
            setActivationFinish(1)
            setContent(errorActivationTower);
            viewNotificationSuccess(true)
            setTimeout(() => {
                viewNotificationSuccess(false);
            }, 6000);
        }
    }, [errorActivationTower])


    React.useEffect(() => {
        //console.log(statusUserLoad)
        if (statusUserLoad === '1') {
            setCompaniesData(apiCompanyData?.data)
        } else {
            setCompaniesData([])
        }
    }, [apiCompanyData, statusUserLoad])

    return (
        <>
            <form className="k-form" onSubmit={handleSubmit}
            >
                <MDBox>
                    <Card>
                        <Grid container spacing={3} p={2}>
                            <Grid item xs={12} md={6} lg={6}>
                                <MDBox>
                                    <h6 style={darkModeStyle}>EMPRESA</h6>
                                    <ComboBox
                                        id="cbxEnterprise"
                                        style={darkModeStyle}
                                        data={dataEnterprises}
                                        textField="enterprise_name"
                                        onChange={handleEntChange}
                                        placeholder="Seleccione Empresa"
                                        value={enterprise}
                                        fillMode="outline"
                                        required
                                        validationMessage="Obligatorio"
                                    />
                                </MDBox>
                            </Grid>
                            <Grid item xs={12} md={5} lg={5}>
                                <MDBox onClick={handleClickCompany} >
                                    <h6 style={darkModeStyle}>COMPAÑÍA</h6>
                                    <ComboBox
                                        id="cbxCompany"
                                        style={darkModeStyle}
                                        data={companiesData}
                                        textField="company_name"
                                        onChange={handleConChange}
                                        placeholder="Seleccione Compañía"
                                        value={company}
                                        fillMode="outline"
                                        required
                                        validationMessage="Obligatorio"
                                        filterable={true}
                                        filter={filter}
                                        adaptiveFilter={adaptiveFilter}
                                        onFilterChange={filterChange}
                                        onClose={handleClose}
                                    />
                                </MDBox>
                            </Grid>
                            {/* <Grid item xs={12} md={1} lg={1}>
                                <MDBox>
                                    <h6 style={darkModeStyle}>BUSCAR</h6>
                                    <Button type="submit" title="Buscar" fillMode="flat">
                                        <Icon fontSize="medium" sx={{ color: "rgb(26, 115, 232, 0.8)" }}>
                                            search
                                        </Icon>
                                    </Button>
                                </MDBox>
                            </Grid> */}
                        </Grid>
                    </Card>
                </MDBox>

            </form>

            {gridProcess}
            {dialogDetails && (
                <Dialog
                    onClose={handleCloseDialog}
                    title={"DETALLE DE CORREOS NO ENVIADOS"}
                    maxWidth={"auto"}
                >
                    <GridTelerik
                        style={{
                            maxWidth: "80vh",
                            height: "auto",
                        }}
                        data={unsentEmail}
                        take={5}
                        fixedScroll={true}
                    >
                        <Column
                            field="mue_massive_sync_id"
                            title="TIPO"
                            //cell={cellWithNotificationType}
                            width="80px"
                        />
                        <Column
                            field="mue_mail"
                            title="EMAIL"
                            //cell={cellWithNotificationType}
                            width="320px"
                        />
                        <Column
                            field="mue_reason"
                            title="RAZON"
                        //cell={cellWithNotification}
                        />
                    </GridTelerik>
                </Dialog>
            )}
            {notificationError && (
                <NotificationGroup style={position.bottomRight}>
                    <Notification
                        key="error"
                        type={{
                            style: "error",
                            icon: true,
                        }}
                        closable={true}
                        onClose={() => viewNotificationError(false)}
                    >
                        {content}
                    </Notification>
                </NotificationGroup>
            )}

            {notificationSuccess && (
                <NotificationGroup
                    style={
                        position.bottomRight
                    }>
                    <Notification
                        key="error"
                        type={{
                            style: "success",
                            //margin: "center"
                        }}

                        closable={true}
                        onClose={() => viewNotificationSuccess(false)}
                    >
                        {content}
                    </Notification>
                </NotificationGroup>
            )}

            {loading && (
                <Dialog>
                    <Loader
                        size={"large"}
                        type={"converging-spinner"}
                    />
                </Dialog>
            )}
        </>
    )
}
