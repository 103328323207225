import { useDispatch, useSelector } from "react-redux"
import {
  onLoadVehicleData, onVehicleDelete, onLoadVehicleDescription, clearVehicleData, onErrorVehicleData, clearErrorVehicleMessage, clearErrorBlacklistMessage, onErrorBlackListData, clearErrorContactPhoneMessage, onErrorContactPhone, clearResidentData, onLoadResidentData, clearerrorMessageResident, onUpdateContactPhone, onContactDelete, onNewContact, onErrorData, onNewResidentLocality, onLoadContactLocalityList,
  onUpdateResidentData, onUpdateVehicle, onUpdateResident, clearBlackListData, clearVehicleStatus, onNewVehicle, onLoadBlackListResident, onBlackListDelete, onUpdateBlackList, onNewBlackList, clearLocalityContactData, clearLocalityContactStatus, clearBlackListDataStatus
} from "store/resident";
import { tokenApi, residentApi } from "api";

export const useResidentStore = () => {

  const dispatch = useDispatch();
  const {
    statusLoad, apiVehicleEdit,
    apiResidentData, apiDataNewResident, apiDataResidentEdit, apiVehicleData, errorMessageVehicle,
    errorMessageResident, errorMessageContactPhone, errorMessageBlackList,
    apiLocalityContactData, apiLocalityContactSave, apiVehicleDescription,
    apiLocalityContactEdit, apiLocalityResidentSave, apiVehicleSave, 
    apiBlackListResident, apiBlackListResidentEdit, apiBlackListResidentSave } = useSelector(state => state.resident);

  const clearResidentLoadData = async () => {
    dispatch(clearResidentData());
  }

  const searchResident = async ({ company_uuid, identification, name, surnames, mail }) => {
    try {
      let bear_token = localStorage.getItem('token')
      //se llama al servicio de cambio de estado de la localidad 
      const { data } = await residentApi.post('/resident/list/filter', { company_uuid, identification, name, surnames, mail }, {
        headers: {
          'Security-Token': bear_token
        }
      });
      //console.log(data)
      dispatch(onLoadResidentData({ data: data.data }));
    }
    catch (error) {
      // error.response.data?.details_error || error.response.data?.message || 
      dispatch(onErrorData(error.response.data?.details_error || error.response.data?.message || 'Lo sentimos. Algunos de nuestros procesos no funcionó.'));
      setTimeout(() => {
        dispatch(clearerrorMessageResident());
      }, 10);
    }
  }

  const newResidentUpdate = async ({ person_uuid, locality_id, identification_type, identification, names, email, surnames, creator_id, enterprise_uuid, company_uuid }) => {
    try {
      let bear_token = localStorage.getItem('token')
      const { data } = await residentApi.post('locality/person/update', { person_uuid, locality_id, identification_type, identification, names, email, surnames, creator_id, enterprise_uuid, company_uuid }, {
        headers: {
          'Security-Token': bear_token
        }
      });
      //console.log(data)
      dispatch(onUpdateResident({ data: data.data }));
    } catch (error) {
      console.log(error)
      // error.response.data?.details_error || error.response.data?.message || 
      dispatch(onErrorData(error.response.data?.details_error || error.response.data?.message || 'Lo sentimos. Algunos de nuestros procesos no funcionó.'));
      //dispatch(onErrorData('Lo sentimos. Algunos de nuestros procesos no funcionó.'));
      setTimeout(() => {
        dispatch(clearerrorMessageResident());
      }, 10);
    }
  }

  const newResidentSave = async ({ locality_id, identification_type, identification, names, email, surnames, creator_id, enterprise_uuid, company_uuid }) => {
    try {
      let bear_token = localStorage.getItem('token')
      const { data } = await residentApi.post('locality/person/save', { locality_id, identification_type, identification, names, email, surnames, creator_id, enterprise_uuid, company_uuid }, {
        headers: {
          'Security-Token': bear_token
        }
      });
      //console.log(data)
      dispatch(onNewResidentLocality({ data: data.data }));
    } catch (error) {
      // error.response.data?.details_error || error.response.data?.message || 
      dispatch(onErrorData(error.response.data?.details_error || error.response.data?.message || 'Lo sentimos. Algunos de nuestros procesos no funcionó.'));
      setTimeout(() => {
        dispatch(clearerrorMessageResident());
      }, 10);
    }
  }

  const updateResidentInformation = async ({ uuid, identification_type, identification, name, surnames, person_type, modifier_id, enterprise_uuid, company_uuid }) => {
    try {
      let bear_token = localStorage.getItem('token')
      const { data } = await residentApi.patch('resident/update', [{ uuid, identification_type, identification, name, surnames, person_type, modifier_id, enterprise_uuid, company_uuid }], {
        headers: {
          'Security-Token': bear_token
        }
      });
      dispatch(onUpdateResidentData());
    } catch (error) {
      // error.response.data?.details_error || error.response.data?.message || 
      dispatch(onErrorData(error.response.data?.details_error || error.response.data?.message || 'Lo sentimos. Algunos de nuestros procesos no funcionó.'));
      setTimeout(() => {
        dispatch(clearerrorMessageResident());
      }, 10);
    }
  }

  //Servicios ContactPhone

  const contactLocalityList = async (locality_uuid) => {
    try {
      let bear_token = localStorage.getItem('token')
      const { data } = await residentApi.post('contact/locality/list', { locality_uuid }, {
        headers: {
          'Security-Token': bear_token
        }
      });
      dispatch(onLoadContactLocalityList({ data: data.data }));
    } catch (error) {
      dispatch(onErrorContactPhone(error.response.data?.details_error || error.response.data?.message || 'Lo sentimos. Algunos de nuestros procesos no funcionó.'));
      //dispatch(onErrorData('Lo sentimos. Algunos de nuestros procesos no funcionó.'));
      setTimeout(() => {
        dispatch(clearErrorContactPhoneMessage());
      }, 10);
    }
  }

  const contactSave = async ({ contact_phone_locality_id, contact_phone_number, contact_phone_priority, contact_phone_description, contact_phone_file, contact_phone_creator_id }) => {
    try {
      let bear_token = localStorage.getItem('token')
      const { data } = await residentApi.post('contact/save', { contact_phone_locality_id, contact_phone_number, contact_phone_priority, contact_phone_description, contact_phone_file, contact_phone_creator_id }, {
        headers: {
          'Security-Token': bear_token
        }
      });
      //console.log(data);
      dispatch(onNewContact({ data: data.data }));
    } catch (error) {
      // error.response.data?.details_error || error.response.data?.message || 
      dispatch(onErrorData(error.response.data?.details_error || error.response.data?.message || 'Lo sentimos. Algunos de nuestros procesos no funcionó.'));
      //dispatch(onErrorData('Lo sentimos. Algunos de nuestros procesos no funcionó.'));
      setTimeout(() => {
        dispatch(clearerrorMessageResident());
      }, 10);
    }
  }

  const contactDelete = async ({ contact_phone_uuid, contact_phone_deletion_id }) => {
    try {
      let bear_token = localStorage.getItem('token')
      const { data } = await residentApi.post('contact/delete', { contact_phone_uuid, contact_phone_deletion_id }, {
        headers: {
          'Security-Token': bear_token
        }
      });
     // console.log(data)
      dispatch(onContactDelete());
    } catch (error) {
      // error.response.data?.details_error || error.response.data?.message || 
      dispatch(onErrorData(error.response.data?.details_error || error.response.data?.message || 'Lo sentimos. Algunos de nuestros procesos no funcionó.'));
      //dispatch(onErrorData('Lo sentimos. Algunos de nuestros procesos no funcionó.'));
      setTimeout(() => {
        dispatch(clearerrorMessageResident());
      }, 10);
    }
  }

  const updateContactPhone = async ({ contact_phone_uuid, contact_phone_locality_id, contact_phone_number, contact_phone_priority, contact_phone_description, contact_phone_last_modifier_id }) => {
    try {
      let bear_token = localStorage.getItem('token')
      const { data } = await residentApi.patch('contact/update', { contact_phone_uuid, contact_phone_locality_id, contact_phone_number, contact_phone_priority, contact_phone_description, contact_phone_last_modifier_id }, {
        headers: {
          'Security-Token': bear_token
        }
      });
      //console.log(data);
      dispatch(onUpdateContactPhone({ data: data.data }));
    } catch (error) {
      console.log(error)
      // error.response.data?.details_error || error.response.data?.message || 
      dispatch(onErrorData(error.response.data?.details_error || error.response.data?.message || 'Lo sentimos. Algunos de nuestros procesos no funcionó.'));
      setTimeout(() => {
        dispatch(clearerrorMessageResident());
      }, 10);
    }
  }


  //Servicios BlackList

  const blackListResident = async ({ bli_gbl_locality_id, bli_gbl_company_uuid }) => {
    try {
      let bear_token = localStorage.getItem('token')
      const { data } = await residentApi.post('blackList/all/list', { bli_gbl_locality_id, bli_gbl_company_uuid }, {
        headers: {
          'Security-Token': bear_token
        }
      });
      //console.log(data)
      dispatch(onLoadBlackListResident({ data: data.data }));
    } catch (error) {
      dispatch(onErrorBlackListData(error.response.data?.details_error || error.response.data?.message || 'Lo sentimos. Algunos de nuestros procesos no funcionó.'));
      setTimeout(() => {
        dispatch(clearErrorBlacklistMessage());
      }, 10);
    }
  }

  const removeBlackList = async ({ bli_gbl_id, bli_gbl_uuid, bli_gbl_deletion_id, bli_gbl_environment, bli_gbl_locality_id, bli_gbl_company_uuid }) => {
    try {
      let bear_token = localStorage.getItem('token')
      const { data } = await residentApi.post('blackList/delete', { bli_gbl_id, bli_gbl_uuid, bli_gbl_deletion_id, bli_gbl_environment, bli_gbl_locality_id, bli_gbl_company_uuid }, {
        headers: {
          'Security-Token': bear_token
        }
      });
      //console.log(data)
      dispatch(onBlackListDelete());
    } catch (error) {
      dispatch(onErrorData(error.response.data?.details_error || error.response.data?.message || 'Lo sentimos. Algunos de nuestros procesos no funcionó.'));
      setTimeout(() => {
        dispatch(clearerrorMessageResident());
      }, 10);
    }
  }

  const updateBlackList = async ({ bli_gbl_id, bli_gbl_uuid, bli_gbl_value, bli_gbl_description, bli_gbl_environment, bli_gbl_locality_id, bli_gbl_modifier_id, bli_gbl_company_uuid }) => {
    try {
      let bear_token = localStorage.getItem('token')
      const { data } = await residentApi.patch('blackList/update', { bli_gbl_id, bli_gbl_uuid, bli_gbl_value, bli_gbl_description, bli_gbl_environment, bli_gbl_locality_id, bli_gbl_modifier_id, bli_gbl_company_uuid }, {
        headers: {
          'Security-Token': bear_token
        }
      });
      //console.log(data);
      dispatch(onUpdateBlackList({ data: data.data }));
    } catch (error) {
      console.log(error)
      // error.response.data?.details_error || error.response.data?.message || 
      dispatch(onErrorData(error.response.data?.details_error || error.response.data?.message || 'Lo sentimos. Algunos de nuestros procesos no funcionó.'));
      setTimeout(() => {
        dispatch(clearerrorMessageResident());
      }, 10);
    }
  }

  const blackListLocalitySave = async ({ bli_type, bli_value, bli_description, bli_file, bli_locality_id, bli_enterprise_id, bli_creator_id, bli_company_uuid }) => {
    try {
      let bear_token = localStorage.getItem('token')
      const { data } = await residentApi.post('/blackList/save', { bli_type, bli_value, bli_description, bli_file, bli_locality_id, bli_enterprise_id, bli_creator_id, bli_company_uuid }, {
        headers: {
          'Security-Token': bear_token
        }
      });
      //console.log(data);
      dispatch(onNewBlackList({ data: data.data }));
    } catch (error) {
      dispatch(onErrorData(error.response.data?.details_error || error.response.data?.message || 'Lo sentimos. Algunos de nuestros procesos no funcionó.'));
      setTimeout(() => {
        dispatch(clearerrorMessageResident());
      }, 10);
    }
  }

  const blackListGeneralSave = async ({ gbl_type, gbl_value, gbl_description, gbl_black_list_type, gbl_enterprise_id, gbl_locality_id, gbl_creator_id, gbl_company_uuid }) => {
    try {
      let bear_token = localStorage.getItem('token')
      const { data } = await residentApi.post('blackList/general/save', { gbl_type, gbl_value, gbl_description, gbl_black_list_type, gbl_enterprise_id, gbl_locality_id, gbl_creator_id, gbl_company_uuid }, {
        headers: {
          'Security-Token': bear_token
        }
      });
      //console.log(data);
      dispatch(onNewBlackList({ data: data.data }));
    } catch (error) {
      dispatch(onErrorData(error.response.data?.details_error || error.response.data?.message || 'Lo sentimos. Algunos de nuestros procesos no funcionó.'));
      setTimeout(() => {
        dispatch(clearerrorMessageResident());
      }, 10);
    }
  }

  const getToken = async () => {
    let dataToken = '';
    let bear_token = localStorage.getItem('token')
    try {
      const requestToken = {
        username: 'APP_SAFEENTRY',
        password: '',
        source: {
          name: 'APP_SAFEENTRY',
          originID: 'APP_SAFEENTRY',
          tipoOriginID: 'dev'
        }
      };
      const token = await tokenApi.post('/generate', requestToken, {
        headers: {
          'Authorization': 'Bearer ' + bear_token
        }
      })
      dataToken = token.data.token;
    } catch (error) {
      console.log(error.message);
    } finally {
      return dataToken;
    }
  }

  //Servicios Vehicles - Safe Resident
  const vehicleResident = async ({ locality_id }) => {
    try {
      let bear_token = localStorage.getItem('token')
      const { data } = await residentApi.post('vehicle/locality/list', { locality_id }, {
        headers: {
          'Security-Token': bear_token
        }
      });
     // console.log(data)
      dispatch(onLoadVehicleData({ data: data.data }));
    } catch (error) {
      dispatch(onErrorVehicleData(error.response.data?.details_error || error.response.data?.message || 'Lo sentimos. Algunos de nuestros procesos no funcionó.'));
      setTimeout(() => {
        dispatch(clearErrorVehicleMessage());
      }, 10);
    }
  }

  const vehicleSave = async ({ veh_plate, veh_color, veh_vehicle_catalog_id, veh_vehicle_locality_id, veh_creator_id, veh_company_uuid, veh_enterprise_uuid, veh_vehicle_catalog_description }) => {
    try {
      let bear_token = localStorage.getItem('token')
      const { data } = await residentApi.post('/vehicle/save', { veh_plate, veh_color, veh_vehicle_catalog_id, veh_vehicle_locality_id, veh_creator_id, veh_company_uuid, veh_enterprise_uuid, veh_vehicle_catalog_description }, {
        headers: {
          'Security-Token': bear_token
        }
      });
      //console.log(data);
      dispatch(onNewVehicle({ data: data.data }));
    } catch (error) {
      dispatch(onErrorData(error.response.data?.details_error || error.response.data?.message || 'Lo sentimos. Algunos de nuestros procesos no funcionó.'));
      setTimeout(() => {
        dispatch(clearerrorMessageResident());
      }, 10);
    }
  }

  const loadVehicleData = async (vehicle_plate) => {
    try {
      let bear_token = localStorage.getItem('token')
      const { data } = await residentApi.get(`vehicle/list/plate/${vehicle_plate}`, {
        headers: {
          'Security-Token': bear_token
        }
      });
      //console.log(data)
      dispatch(onLoadVehicleDescription({ data: data.data }));
    } catch (error) {
      // error.response.data?.details_error || error.response.data?.message || 
      dispatch(onErrorData(error.response.data?.details_error || error.response.data?.message || 'Lo sentimos. Algunos de nuestros procesos no funcionó.'));
      setTimeout(() => {
        dispatch(clearerrorMessageResident());
      }, 10);
    }
  }

  const updateVehicle = async ({ veh_uuid, veh_plate, veh_color, veh_vehicle_catalog_id, veh_vehicle_locality_id, veh_last_modifier_id, veh_company_uuid, veh_enterprise_uuid, veh_vehicle_catalog_description }) => {
    try {
      let bear_token = localStorage.getItem('token')
      const { data } = await residentApi.patch('/vehicle/update', { veh_uuid, veh_plate, veh_color, veh_vehicle_catalog_id, veh_vehicle_locality_id, veh_last_modifier_id, veh_company_uuid, veh_enterprise_uuid, veh_vehicle_catalog_description }, {
        headers: {
          'Security-Token': bear_token
        }
      });
      //console.log(data);
      dispatch(onUpdateVehicle({ data: data.data }));
    } catch (error) {
      dispatch(onErrorData(error.response.data?.details_error || error.response.data?.message || 'Lo sentimos. Algunos de nuestros procesos no funcionó.'));
      setTimeout(() => {
        dispatch(clearerrorMessageResident());
      }, 10);
    }
  }

  const removeVehicle = async ({ lve_vehicle_locality_id, lve_locality_id, veh_deleter_id, veh_company_uuid }) => {
    try {
      let bear_token = localStorage.getItem('token')
      const { data } = await residentApi.post('vehicle/delete', { lve_vehicle_locality_id, lve_locality_id, veh_deleter_id, veh_company_uuid }, {
        headers: {
          'Security-Token': bear_token
        }
      });
      //console.log(data)
      dispatch(onVehicleDelete());
    } catch (error) {
      dispatch(onErrorData(error.response.data?.details_error || error.response.data?.message || 'Lo sentimos. Algunos de nuestros procesos no funcionó.'));
      setTimeout(() => {
        dispatch(clearerrorMessageResident());
      }, 10);
    }
  }

  const clearLocalityContactPhone = async () => {
    dispatch(clearLocalityContactData());
  }

  const clearLocalityContactPhoneStatus = async () => {
    dispatch(clearLocalityContactStatus());
  }

  const clearBlackList = async () => {
    dispatch(clearBlackListData());
  }

  const clearBlackListStatus = async () => {
    dispatch(clearBlackListDataStatus())
  }

  const clearVehicles = async () => {
    dispatch(clearVehicleData());
  }

  const clearVehicleDataStatus = async () => {
    dispatch(clearVehicleStatus())
  }

  


  return {
    // * Properties
    apiResidentData,
    statusLoad,
    errorMessageResident,
    errorMessageVehicle,
    errorMessageContactPhone,
    errorMessageBlackList,
    apiDataNewResident,
    apiLocalityContactData,
    apiLocalityContactSave,
    apiLocalityContactEdit,
    apiLocalityResidentSave,
    apiBlackListResident,
    apiBlackListResidentEdit,
    apiBlackListResidentSave,
    apiDataResidentEdit,
    apiVehicleData,
    apiVehicleSave,
    apiVehicleDescription,
    apiVehicleEdit,

    // * Methods
    clearResidentLoadData,
    searchResident,
    newResidentSave,
    updateResidentInformation,
    newResidentUpdate,
    contactLocalityList,
    contactSave,
    contactDelete,
    updateContactPhone,
    blackListResident,
    removeBlackList,
    updateBlackList,
    blackListLocalitySave,
    blackListGeneralSave,
    vehicleResident,
    vehicleSave,
    updateVehicle,
    loadVehicleData,
    removeVehicle,
    clearLocalityContactPhone,
    clearLocalityContactPhoneStatus,
    clearBlackListStatus,
    clearBlackList,
    clearVehicles,
    clearVehicleDataStatus,
  }
}