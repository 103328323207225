import * as React from "react";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";


import { IntlProvider, load, LocalizationProvider, loadMessages } from "@progress/kendo-react-intl";
import likelySubtags from "cldr-core/supplemental/likelySubtags.json";
import currencyData from "cldr-core/supplemental/currencyData.json";
import weekData from "cldr-core/supplemental/weekData.json";
import numbers from "cldr-numbers-full/main/es/numbers.json";
import currencies from "cldr-numbers-full/main/es/currencies.json";
import caGregorian from "cldr-dates-full/main/es/ca-gregorian.json";
import dateFields from "cldr-dates-full/main/es/dateFields.json";
import timeZoneNames from "cldr-dates-full/main/es/timeZoneNames.json";
import esMessages from "assets/globalization/es.json";
import { UploadPreauthorizedData} from "./UploadPreauthorizedData"

load( likelySubtags, currencyData, weekData, numbers, currencies, caGregorian, dateFields, timeZoneNames);

loadMessages(esMessages, "es-ES");

export const UploadPreauthorizedPage = () => {  

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <LocalizationProvider language="es-ES">
        <IntlProvider locale="es">
          <UploadPreauthorizedData/>
          {/* <UploadDataFile /> */}
        </IntlProvider>
      </LocalizationProvider>
      <Footer />
    </DashboardLayout>
  )
}