import { createSlice } from "@reduxjs/toolkit";

export const userSlice = createSlice({
    name: 'user',
    initialState: {
        statusUserLoad: '0',
        statusUserCode: '0',
        apiPersonData: {},
        apiPasswordData: {},
        apiCodePassword: {},
        apiCompanyData: {},
        errorUserMessage: undefined,
        errorCodeMessage: undefined,
    },

    reducers: {

        onLoadCompany : (state, { payload }) => {
            state.statusUserLoad = '1';
            state.apiCompanyData = payload;
            state.errorUserMessage = undefined;
        },

        onLoadUser: (state, { payload }) => {
            state.statusUserLoad = '1';
            state.apiPersonData = payload;
            state.errorUserMessage = undefined;
        },

        onChangePassword: (state, { payload }) => {
            state.statusUserLoad = '1';
            state.apiPasswordData = payload;
            state.errorUserMessage = undefined;
        },

        onCodePassword: (state, { payload }) => {
            state.statusUserCode = '1';
            state.apiCodePassword = payload;
            state.errorCodeMessage = undefined;
        },

        onErrorUserData: (state, { payload }) => {
            state.statusUserLoad = '0';
            state.errorUserMessage = payload;
        },

        onErrorCodeData: (state, { payload }) => {
            state.statusUserCode = '0';
            state.errorCodeMessage = payload;
        },

        clearErrorUserMessage: (state) => {
            state.errorUserMessage = undefined;
        },

        clearErrorCodeMessage: (state) => {
            state.errorCodeMessage = undefined;
        },

        clearRecoveryData: (state) => {
            state.statusUserLoad = '0';
            state.apiPasswordData = undefined
        },

        clearRecoveryCode: (state) => {
            state.statusUserCode = '0';
            state.apiCodePassword = undefined
        },
    }
});

export const { onLoadCompany, clearRecoveryCode, clearErrorCodeMessage, onErrorCodeData, clearRecoveryData, onLoadUser, onChangePassword, onCodePassword, onErrorUserData, clearErrorUserMessage } = userSlice.actions;