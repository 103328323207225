import { useDispatch, useSelector } from "react-redux";
import { massiveApi } from "api";
import {
    //Activation Tower
    clearStatusActivation,
    onActivationTower,
    onErrorActivationTower,
    clearErrorActivationTower,

    clearErrorMassiveEmails, clearErrorSyncTower,
    onLoadLogsData, onErrorLogsData, onLoadFileLogData, onErrorFileLogsData, onLoadTowerData, onErrorSendEmails, onErrorUnsentEmails, onErrorForwardingEmails,
    onErrorTowerData, clearLogsDataStatus, clearDownloadDataStatus, clearErrorMassiveData, onDownloadFile, onDeleteFile, onUploadFile, onErrorUploadData,
    onLoadPreFileLogData, onErrorDownloadPreData, onErrorPreFileLogsData, onLoadLogsPreData, onErrorLogsPreData, onDownloadPreFile, onUploadPreFile, onErrorUploadPreData, onDeletePreFile,
    clearDownloadPreDataStatus, clearLogsPreDataStatus, clearErrorMassivePreData, onSendEmails, onUnsentEmails, onForwardingEmails, onSyncTower, onErrorSyncTower
} from 'store/safeentry'

export const useMassiveStore = () => {

    const dispatch = useDispatch();

    const { 
        //Activation Tower
        statusActivationTower,
        apiActivationTower,
        errorActivationTower,
        statusMassiveLoad, apiTowereData, apiFileLogData, apiLogsData, apiUploadData, apiSendEmails, apiUnsentEmails, apiForwardEmails, errorSendEmails,
        errorUnsentEmails, errorForwardEmails, statusMassiveEmails,
        errorMassiveDataMessage, apiDownlaodData, errorUploadDataMessage, errorLogsDataMessage, apiPreFileLogData, apiLogsPreData,
        apiDownloadPreData,
        apiUploadPreData,
        errorMassivePreDataMessage,
        errorLogsPreDataMessage,
        errorUploadPreDataMessage,
        errorDownloadPreFileMessage, statusSyncTower, apiSyncTower,
        errorSyncTower 
    } = useSelector(state => state.massive);

    const clearDownloadData = async () => {
        dispatch(clearDownloadDataStatus());
    }

    const clearLogsData = async () => {
        dispatch(clearLogsDataStatus());
    }

    const loadTowerData = async ({ uuid_company }) => {
        try {
            let bear_token = localStorage.getItem('token')
            const { data } = await massiveApi.post('/tower/list', { uuid_company }, {
                headers: {
                    'Authorization': bear_token
                }
            });
            dispatch(onLoadTowerData({ data: data.data }));
        } catch (error) {
            // error.response.data?.details_error || error.response.data?.message || 
            dispatch(onErrorTowerData(error.response.data?.details_error || error.response.data?.message || 'Lo sentimos. Algunos de nuestros procesos no funcionó.'));
            setTimeout(() => {
                dispatch(clearErrorMassiveData());
            }, 10);
        }
    }

    const loadMassiveFileData = async ({ uuid_company }) => {
        try {
            let bear_token = localStorage.getItem('token')
            const { data } = await massiveApi.post('/upload/resident/list', { uuid_company }, {
                headers: {
                    'Authorization': bear_token
                }
            });
            dispatch(onLoadFileLogData({ data: data.data }));
        } catch (error) {
            // error.response.data?.details_error || error.response.data?.message || 
            dispatch(onErrorFileLogsData(error.response.data?.details_error || error.response.data?.message || 'Lo sentimos. Algunos de nuestros procesos no funcionó.'));
            setTimeout(() => {
                dispatch(clearErrorMassiveData());
            }, 10);
        }
    }

    const loadLogFileData = async ({ mrl_uuid }) => {
        try {
            let bear_token = localStorage.getItem('token')
            const { data } = await massiveApi.post('upload/validation/listlogs', { mrl_uuid }, {
                headers: {
                    'Authorization': bear_token
                }
            });
            dispatch(onLoadLogsData({ data: data.data }));
            //console.log(data)
        } catch (error) {
            // error.response.data?.details_error || error.response.data?.message || 
            dispatch(onErrorLogsData(error.response.data?.details_error || error.response.data?.message || 'Lo sentimos. Algunos de nuestros procesos no funcionó.'));
            setTimeout(() => {
                dispatch(clearErrorMassiveData());
            }, 10);
        }
    }

    const downloadFile = async ({ uuid_company }) => {
        try {
            let bear_token = localStorage.getItem('token')
            const { data } = await massiveApi.post('download/file/resident', { uuid_company }, {
                headers: {
                    'Authorization': bear_token
                }
            });
            dispatch(onDownloadFile({ data: data.data }));
        } catch (error) {
            // error.response.data?.details_error || error.response.data?.message || 
            dispatch(onErrorLogsData(error.response.data?.details_error || error.response.data?.message || 'Lo sentimos. Algunos de nuestros procesos no funcionó.'));
            setTimeout(() => {
                dispatch(clearErrorMassiveData());
            }, 10);
        }
    }

    const deleteFile = async ({ file_name }) => {
        try {
            let bear_token = localStorage.getItem('token')
            const { data } = await massiveApi.post('delete/file/resident', { file_name }, {
                headers: {
                    'Authorization': bear_token
                }
            });
            dispatch(onDeleteFile({ data: data.data }));
        } catch (error) {
            // error.response.data?.details_error || error.response.data?.message || 
            dispatch(onErrorLogsData(error.response.data?.details_error || error.response.data?.message || 'Lo sentimos. Algunos de nuestros procesos no funcionó.'));
            setTimeout(() => {
                dispatch(clearErrorMassiveData());
            }, 10);
        }
    }

    const uploadFile = async (formData) => {
        try {
            let bear_token = localStorage.getItem('token')
            const { data } = await massiveApi.post('upload/file/resident', formData,
                {
                    headers: {
                        'Authorization': bear_token,
                        'Content-Type': 'multipart/form-data'
                    }
                });
            //console.log(formData)
            dispatch(onUploadFile({ data: data }));

        } catch (error) {
            // error.response.data?.details_error || error.response.data?.message ||
            console.log(error.response.data.data)
            dispatch(onErrorUploadData(error.response.data?.details_error || error.response.data || 'Lo sentimos. Algunos de nuestros procesos no funcionó.'));
            setTimeout(() => {
                dispatch(clearErrorMassiveData());
            }, 10);
        }
    }


    //Massive Preauthorization

    const clearDownloadPreData = async () => {
        dispatch(clearDownloadPreDataStatus());
    }

    const clearLogsPreData = async () => {
        dispatch(clearLogsPreDataStatus());
    }

    const loadMassivePreauthorizationData = async ({ uuid_company }) => {
        try {
            let bear_token = localStorage.getItem('token')
            const { data } = await massiveApi.post('/upload/preauthorization/list', { uuid_company }, {
                headers: {
                    'Authorization': bear_token
                }
            });
            dispatch(onLoadPreFileLogData({ data: data.data }));
        } catch (error) {
            // error.response.data?.details_error || error.response.data?.message || 
            dispatch(onErrorPreFileLogsData(error.response.data?.details_error || error.response.data?.message || 'Lo sentimos. Algunos de nuestros procesos no funcionó.'));
            setTimeout(() => {
                dispatch(clearErrorMassivePreData());
            }, 10);
        }
    }

    const loadLogPreauthorizationData = async ({ mpl_uuid }) => {
        try {
            let bear_token = localStorage.getItem('token')
            const { data } = await massiveApi.post('upload/preauthorization/listlogs', { mpl_uuid }, {
                headers: {
                    'Authorization': bear_token
                }
            });
            dispatch(onLoadLogsPreData({ data: data.data }));
            //console.log(data)
        } catch (error) {
            // error.response.data?.details_error || error.response.data?.message || 
            dispatch(onErrorLogsPreData(error.response.data?.details_error || error.response.data?.message || 'Lo sentimos. Algunos de nuestros procesos no funcionó.'));
            setTimeout(() => {
                dispatch(clearErrorMassivePreData());
            }, 10);
        }
    }

    const downloadPreauthorizationFile = async ({ uuid_company }) => {
        try {
            let bear_token = localStorage.getItem('token')
            const { data } = await massiveApi.post('download/file/preauthorization', { uuid_company }, {
                headers: {
                    'Authorization': bear_token
                }
            });
            dispatch(onDownloadPreFile({ data: data.data }));
        } catch (error) {
            // error.response.data?.details_error || error.response.data?.message || 
            dispatch(onErrorDownloadPreData(error.response.data?.details_error || error.response.data?.message || 'Lo sentimos. Algunos de nuestros procesos no funcionó.'));
            setTimeout(() => {
                dispatch(clearErrorMassivePreData());
            }, 10);
        }
    }

    const deletePreauthorizationFile = async ({ file_name }) => {
        try {
            let bear_token = localStorage.getItem('token')
            const { data } = await massiveApi.post('delete/file/preauthorization', { file_name }, {
                headers: {
                    'Authorization': bear_token
                }
            });
            dispatch(onDeletePreFile({ data: data.data }));
        } catch (error) {
            // error.response.data?.details_error || error.response.data?.message || 
            dispatch(onErrorLogsPreData(error.response.data?.details_error || error.response.data?.message || 'Lo sentimos. Algunos de nuestros procesos no funcionó.'));
            setTimeout(() => {
                dispatch(clearErrorMassivePreData());
            }, 10);
        }
    }

    const uploadPreauthorizationFile = async (formData) => {
        try {
            let bear_token = localStorage.getItem('token')
            const { data } = await massiveApi.post('upload/file/preauthorization', formData,
                {
                    headers: {
                        'Authorization': bear_token,
                        'Content-Type': 'multipart/form-data'
                    }
                });
            //console.log(formData)
            dispatch(onUploadPreFile({ data: data }));

        } catch (error) {
            // error.response.data?.details_error || error.response.data?.message ||
            console.log(error.response.data.data)
            dispatch(onErrorUploadPreData(error.response.data?.details_error || error.response.data || 'Lo sentimos. Algunos de nuestros procesos no funcionó.'));
            setTimeout(() => {
                dispatch(clearErrorMassivePreData());
            }, 10);
        }
    }

    //Send Emails
    const sendMassiveEmails = async ({ uuid_massive_sync_record, user_process }) => {
        try {
            let bear_token = localStorage.getItem('token')
            const { data } = await massiveApi.post('upload/send/emails', { uuid_massive_sync_record, user_process }, {
                headers: {
                    'Authorization': bear_token
                }
            });
            dispatch(onSendEmails({ data: data.data }));
        } catch (error) {
            // error.response.data?.details_error || error.response.data?.message || 
            dispatch(onErrorSendEmails(error.response.data?.details_error || error.response.data?.message || 'Lo sentimos. Algunos de nuestros procesos no funcionó.'));
            setTimeout(() => {
                dispatch(clearErrorMassiveEmails());
            }, 10);
        }
    }

    //Lista de email no enviados
    const listUnsentEmails = async ({ uuid_massive_sync_record }) => {
        try {
            let bear_token = localStorage.getItem('token')
            const { data } = await massiveApi.post('upload/list/unsent/emails', { uuid_massive_sync_record }, {
                headers: {
                    'Authorization': bear_token
                }
            });
            dispatch(onUnsentEmails({ data: data.data }));
        } catch (error) {
            // error.response.data?.details_error || error.response.data?.message || 
            dispatch(onErrorUnsentEmails(error.response.data?.details_error || error.response.data?.message || 'Lo sentimos. Algunos de nuestros procesos no funcionó.'));
            setTimeout(() => {
                dispatch(clearErrorMassiveEmails());
            }, 10);
        }
    }

    //Reenvio masivo de correos
    const forwardMassiveEmails = async ({ uuid_massive_sync_record, user_process }) => {
        try {
            let bear_token = localStorage.getItem('token')
            const { data } = await massiveApi.post('upload/forwarding/emails', { uuid_massive_sync_record, user_process }, {
                headers: {
                    'Authorization': bear_token
                }
            });

            dispatch(onForwardingEmails({ data: data.data }));
        } catch (error) {
            // error.response.data?.details_error || error.response.data?.message || 
            dispatch(onErrorForwardingEmails(error.response.data?.details_error || error.response.data?.message || 'Lo sentimos. Algunos de nuestros procesos no funcionó.'));
            setTimeout(() => {
                dispatch(clearErrorMassiveEmails());
            }, 10);
        }
    }

    //Sync con la torre
    const syncMassiveTower = async ({ uuid_company, user_process }) => {
        try {
            let bear_token = localStorage.getItem('token')
            const { data } = await massiveApi.post('sync/massive/company', { uuid_company, user_process }, {
                headers: {
                    'Authorization': bear_token
                }
            });
            dispatch(onSyncTower({ data: data.data }));
        } catch (error) {
            // error.response.data?.details_error || error.response.data?.message || 
            dispatch(onErrorSyncTower(error.response.data?.details_error || error.response.data?.message || 'Lo sentimos. Algunos de nuestros procesos no funcionó.'));
            setTimeout(() => {
                dispatch(clearErrorSyncTower());
            }, 10);
        }
    }

    //Activacion de torre
    const activationTower = async ({ uuid_company, user_process }) => {
        try {
            let bear_token = localStorage.getItem('token')
            const { data } = await massiveApi.post('activate/service/company', { uuid_company, user_process }, {
                headers: {
                    'Authorization': bear_token
                }
            });
            dispatch(onActivationTower({ data: data.data }));
        } catch (error) {
            // error.response.data?.details_error || error.response.data?.message || 
            dispatch(onErrorActivationTower(error.response.data?.details_error || error.response.data?.message || 'Lo sentimos. Algunos de nuestros procesos no funcionó.'));
            setTimeout(() => {
                dispatch(clearErrorActivationTower());
            }, 10);
        }
    }

    const clearStatusTowerMassive = async () => {
        dispatch(clearStatusActivation());
      }

    return {
        // *Properties 
        //Massive Localities
        statusMassiveLoad,
        apiTowereData,
        errorMassiveDataMessage,
        apiFileLogData,
        apiLogsData,
        apiDownlaodData,
        apiUploadData,
        errorUploadDataMessage,
        errorLogsDataMessage,

        //Massive Preauthorization
        apiPreFileLogData,
        apiLogsPreData,
        apiDownloadPreData,
        apiUploadPreData,
        errorMassivePreDataMessage,
        errorLogsPreDataMessage,
        errorUploadPreDataMessage,
        errorDownloadPreFileMessage,

        //Massive Tower
        apiSyncTower,
        errorSyncTower,
        statusSyncTower,

        //Massive Emails
        apiSendEmails,
        apiUnsentEmails,
        apiForwardEmails,
        errorSendEmails,
        errorUnsentEmails,
        errorForwardEmails,
        statusMassiveEmails,

        //Activation Tower
        statusActivationTower,
        apiActivationTower,
        errorActivationTower,

        // *Methods
        //Massive Localidades
        loadTowerData,
        loadMassiveFileData,
        loadLogFileData,
        downloadFile,
        deleteFile,
        uploadFile,
        clearDownloadData,
        clearLogsData,

        //Massive Preautorizados
        loadMassivePreauthorizationData,
        loadLogPreauthorizationData,
        downloadPreauthorizationFile,
        deletePreauthorizationFile,
        uploadPreauthorizationFile,
        clearDownloadPreData,
        clearLogsPreData,

        //Massive Emails
        listUnsentEmails,
        forwardMassiveEmails,
        sendMassiveEmails,

        //Massive Sync
        syncMassiveTower,
        clearStatusTowerMassive,
        
        //Massive
        activationTower,
        
    }
}