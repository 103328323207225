import React from 'react'
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import esMessages from "assets/globalization/es.json";
import { IntlProvider, LocalizationProvider, loadMessages } from "@progress/kendo-react-intl";
import { SearchResident } from "./SearchResident"

loadMessages(esMessages, "es-ES");


export const SearchResidentPage = () => {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <LocalizationProvider language="es-ES">
        <IntlProvider locale="es">
          <SearchResident />
        </IntlProvider>
      </LocalizationProvider>
      <Footer />
    </DashboardLayout>
  )
  
}
