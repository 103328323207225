import React from 'react'
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import esMessages from "assets/globalization/es.json";
import { IntlProvider, LocalizationProvider, loadMessages } from "@progress/kendo-react-intl";
import {SearchLocalityData} from './SearchLocalityData';
loadMessages(esMessages, "es-ES");


export const SearchLocalityPage = () => {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <LocalizationProvider language="es-ES">
        <IntlProvider locale="es">
          <SearchLocalityData />
        </IntlProvider>
      </LocalizationProvider>
      <Footer />
    </DashboardLayout>
  )
  
}
