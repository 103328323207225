import { useDispatch, useSelector } from "react-redux";
import { residentApi } from "api";
import { 
  onLoadRegisterData, onErrorRegisterData, 
  clearRegisterErrorMessage,
  onLoadRegisterPhotosData, onErrorRegisterPhotosData
} from 'store/safeentry'

export const useRegisterStore = () => {

  const dispatch = useDispatch();

  const { 
    statusRegisterLoad,
    apiRegisterData, errorRegisterMessage,
    apiRegisterPhotos, errorRegisterPhotosMessage
  } = useSelector(state => state.register);

  
  const loadRegisterData = async ( {enterprise_uuid, company_uuid, start_date, end_date, search_plate, search_island} ) => {
    try {
        let bear_token = localStorage.getItem('token')
        const { data } = await residentApi.post('register/binnacle', {enterprise_uuid, company_uuid, start_date, end_date, search_plate, search_island} , {
            headers: {
                'Security-Token': bear_token
            }
        });
        dispatch(onLoadRegisterData({ data: data.data }));
    } catch (error) {
        // error.response.data?.details_error || error.response.data?.message || 
        dispatch(onErrorRegisterData(error.response.data?.details_error || error.response.data?.message || 'Lo sentimos. Algunos de nuestros procesos no funcionó.'));
        setTimeout(() => {
            dispatch(clearRegisterErrorMessage());
        }, 10);
    }
  }

  const loadRegisterPhotosData = async ( binnacle_uuid ) => {
    try {
        let bear_token = localStorage.getItem('token')
        const { data } = await residentApi.get(`binnacle/photos/${binnacle_uuid}` , {
            headers: {
                'Security-Token': bear_token
            }
        });
        dispatch(onLoadRegisterPhotosData({ data: data.data }));
    } catch (error) {
        // error.response.data?.details_error || error.response.data?.message || 
        dispatch(onErrorRegisterPhotosData(error.response.data?.details_error || error.response.data?.message || 'Lo sentimos. Algunos de nuestros procesos no funcionó.'));
        setTimeout(() => {
            dispatch(clearRegisterErrorMessage());
        }, 10);
    }
  }

  return {
    // * Properties
    statusRegisterLoad, 
    apiRegisterData,
    errorRegisterMessage,
    apiRegisterPhotos, 
    errorRegisterPhotosMessage,

    // * Methods
    loadRegisterData,
    loadRegisterPhotosData,
  }
}
