import { configureStore } from '@reduxjs/toolkit'
import { authSlice } from './auth';
import { residentSlice } from './resident';
import { localityStateSlice, visitSlice, registerSlice, userSlice, massiveSlice} from './safeentry'


export const store = configureStore({
  reducer: {
    auth: authSlice.reducer,
    resident: residentSlice.reducer,
    localityState: localityStateSlice.reducer,
    visit: visitSlice.reducer,
    register: registerSlice.reducer,
    user: userSlice.reducer,
    massive: massiveSlice.reducer,
  }
});