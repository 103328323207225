import React from 'react'
//Telerik
import { EditFormResident } from './EditFormResident';
import { Grid as GridTelerik, GridColumn, GridToolbar } from "@progress/kendo-react-grid";
import { Dialog } from "@progress/kendo-react-dialogs";
import { Button } from "@progress/kendo-react-buttons";
import { Notification, NotificationGroup } from "@progress/kendo-react-notification";
import { Input } from "@progress/kendo-react-inputs";
import { ComboBox } from "@progress/kendo-react-dropdowns";
import { Loader } from "@progress/kendo-react-indicators";

//mui Material
import { Icon } from "@mui/material";


//Hooks
import { useLocalityStateStore, useResidentStore, useUserStore } from 'hooks';
//import { identification_types } from "./CatalogData";
export const LocalityResidents = ({ value_localityID, value_localityUuid, value_company, value_enterprise }) => {

  const { apiLocalityResidentData, statusLocalityLoad, removeResidentLocality, clearRemoveResident } = useLocalityStateStore();
  const { apiLocalityResidentSave, apiDataNewResident, newResidentUpdate, newResidentSave, statusLoad, clearResidentLoadData, errorMessageResident } = useResidentStore();
  const { loadResidentIDData, apiPersonData } = useUserStore();
  const [dataResidentLocality, setDataResidentLocality] = React.useState([]);
  const [openNewResidentForm, setOpenNewResidentForm] = React.useState(false)
  const [nameValue, setNameValue] = React.useState("");
  const [surnamesValue, setSurnamesValue] = React.useState("");
  const [emailValue, setEmailValue] = React.useState("");
  const [allowCustom, setAllowCustom] = React.useState(false);

  const darkModeStyle = {
    color: "rgb(0, 0, 0)",
  };

  const position = {
    bottomRight: {
      bottom: 0,
      right: 0,
      alignItems: "flex-end",
    }
  }

  const handleCancelDialog = () => {
    setOpenNewResidentForm(false)
    setFinalDeleteDialog(false)
    setIdentificationValue("")
    setNameValue("")
    setSurnamesValue("")
    setEmailValue("")
  }



  const user_uuid = localStorage.getItem("user");
  const [openFormResident, setOpenFormResident] = React.useState(false);
  const [editItem, setEditItem] = React.useState();
  const [loading, setLoading] = React.useState(false);

  const [residentData, setResidentData] = React.useState([]);
  const addResident = () => {
    setOpenNewResidentForm(true)
    viewNotificationSuccess(false)
    setIdentificationValue("")
    setNameValue("")
    setSurnamesValue("")
    setEmailValue("")
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    const request = {
      //person_uuid: "",
      locality_id: value_localityID,
      identification_type: event.target.identification_type.value === "CÉDULA" ? "CED" : event.target.identification_type.value === "PASAPORTE" ? "PAS" : "RUC",
      identification: (event.target.person_identification.value).toUpperCase(),
      names: (event.target.person_names.value).toUpperCase(),
      surnames: (event.target.person_surnames.value).toUpperCase(),
      email: (event.target.person_mail.value).toLowerCase(),
      company_uuid: value_company,
      creator_id: user_uuid,
      enterprise_uuid: value_enterprise
    }
    //console.log(request)
    newResidentSave(request)
    setLoading(true)
  };

  const handleCancelEdit = () => {
    setOpenFormResident(false);
  };

  const [editItemData, setEditItemData] = React.useState(undefined);

  const handleSubmitEdit = (event) => {
    setEditItemData(event);
    //console.log(event)
    const request_update = {
      person_uuid: event.person_uuid,
      locality_id: value_localityID,
      identification_type: event.person_identification_type === "CEDULA" ? "CED" : event.person_identification_type === "PASAPORTE" ? "PAS" : "RUC",
      identification: event.person_identification,
      names: (event.person_names).toUpperCase(),
      surnames: (event.person_surnames).toUpperCase(),
      email: (event.person_mail).toLowerCase(),
      company_uuid: value_company,
      creator_id: user_uuid,
      enterprise_uuid: value_enterprise
    }
    //console.log(request_update)
    newResidentUpdate(request_update)
    setLoading(true)
  };

  const [typeID, setTypeID] = React.useState()
  const handleChangeTypeIdentification = (event) => {
    //event.preventDefault();
    //setTypeID(event.target.value)
    // if (event.target.value === 'CÉDULA') {
    //   setTypeID("CED")
    // }
    // if (event.target.value === 'PASAPORTE') {
    //   setTypeID("PAS")
    // }
    // if (event.target.value === 'RUC') {
    //   setTypeID("RUC")
    // }
  }

  const [content, setContent] = React.useState("<span></span>");
  const [notificationSuccess, setNotificationSuccess] = React.useState(false);
  const viewNotificationSuccess = (state) => {
    setNotificationSuccess(state)
  }

  const [notificationError, setNotificationError] = React.useState(false);
  const viewNotificationError = (state) => {
    setNotificationError(state)
  }

  const CommandCell = (props) => {
    const { dataItem } = props;
    return <td className="k-command-cell">
      <button title="Editar Residente" className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary k-grid-edit-command" onClick={() => enterEdit(dataItem)}>
        <Icon fontSize="small" sx={{ color: "white" }}>
          edit
        </Icon>
      </button>
      <button title="Eliminar Residente" className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base k-grid-remove-command"
        onClick={() => remove(dataItem)}>
        <Icon fontSize="small" sx={{ color: "white" }}>
          delete
        </Icon>
      </button>
    </td>;
  };

  const enterEdit = (item) => {
    setOpenFormResident(true);
    setEditItem(item);
  };

  const [removeItem, setRemoveItem] = React.useState(undefined);
  const [finalDeleteDialog, setFinalDeleteDialog] = React.useState(false);
  const [deleteMessage, setDeleteMessage] = React.useState("")
  const [deleteMessage2, setDeleteMessage2] = React.useState("")
  const [personValue, setPersonValue] = React.useState("")

  //Se ibtiene los datos para eliminar al residente
  const remove = (dataItem) => {
    setRemoveItem(dataItem);
    //console.log(removeItem)
    setFinalDeleteDialog(true)
    setDeleteMessage("¿ESTÁ SEGURO QUE DESEA ELIMINAR A " + dataItem.field + " DE ESTA LOCALIDAD?\n")
    setDeleteMessage2("RECUERDE QUE TODA LA DATA RELACIONADA A " + dataItem.field + " SERÁ ELIMINADA")
    setPersonValue(dataItem.person_uuid)

  };

  //Se elimina logicamente al residente 
  const removeResident = () => {
    const request_remove = {
      uuid_locality: value_localityUuid,
      per_uuid: personValue,
      valor: true,
      description: "ELIMINACIÓN DE RESIDENTE EN LA LOCALIDAD",
      modifier_id: user_uuid
    }
    setLoading(false)
    removeResidentLocality(request_remove)
  }

  const [identificationValue, setIdentificationValue] = React.useState("");

  const handleLoadInformation = () => {
    let valuePersonID = document.getElementById("person_identification").value;
    // console.log(valuePersonID)
    // let valueComboType = document.getElementById("person_identification_type").selectedOptions[0].value
    const request_resident_id = {
      identification_type: (document.getElementById("identification_type").value) === "CEDULA" ? "CED" : (document.getElementById("identification_type").value) === "PASAPORTE" ? "PAS" : "RUC",
      identification: valuePersonID,
    }
    //console.log(request_resident_id)
    loadResidentIDData(request_resident_id)
  }

  const handleChangeIdentification = (event) => {
    setIdentificationValue(event.target.value)
  }

  const handleChangeNames = (event) => {
    setNameValue(event.target.value)
  }

  const handleChangeSurnames = (event) => {
    setSurnamesValue(event.target.value)
  }

  const handleChangeEmail = (event) => {
    setEmailValue(event.target.value)
  }

  const cleanForm = () => {
    setIdentificationValue("")
    setNameValue("")
    setSurnamesValue("")
    setEmailValue("")
  }

  React.useEffect(() => {
    if (apiPersonData?.data !== undefined) {
      setNameValue(apiPersonData?.data.names)
      setSurnamesValue(apiPersonData?.data.surnames)
      setEmailValue(apiPersonData?.data.email)
      // document.getElementById("person_names").value = apiPersonData?.data.names;
      // document.getElementById("person_surnames").value = apiPersonData?.data.surnames;
      // document.getElementById("person_mail").value = apiPersonData?.data.email;

    }
    //setOpenNewResidentForm(true)
    //setOpenNewResidentForm(true)
  }, [apiPersonData])





  React.useEffect(() => {
    if (apiLocalityResidentSave?.data !== undefined) {
      setResidentData(apiLocalityResidentSave.data)
      setDataResidentLocality(apiLocalityResidentSave.data.map((dataItem) => ({
        ...dataItem
      })));
    } else {
      setResidentData([])
      setDataResidentLocality([]);
    }
  }, [apiLocalityResidentSave])

  React.useEffect(() => {
    if (apiDataNewResident?.data !== undefined) {
      setResidentData(apiDataNewResident.data)
      setDataResidentLocality(apiDataNewResident.data.map((dataItem) => ({
        ...dataItem
      })));
    } else {
      setResidentData([])
      setDataResidentLocality([]);
    }
  }, [apiDataNewResident])


  React.useEffect(() => {
    //console.log(statusLoad, statusLocalityLoad)
    if (statusLoad === '2') {
      setContent('Registro Actualizado Exitosamente');
      viewNotificationSuccess(true);
      setTimeout(() => {
        viewNotificationSuccess(false);
      }, 4000);
      setOpenNewResidentForm(false);
      setOpenFormResident(false);
      setIdentificationValue("")
      setNameValue("")
      setSurnamesValue("")
      setEmailValue("")
      setLoading(false)
    }
    if (statusLoad === '3') {
      setContent('Registro Guardado Exitosamente');
      viewNotificationSuccess(true);
      setTimeout(() => {
        viewNotificationSuccess(false);
      }, 4000);
      setOpenNewResidentForm(false);
      //setOpenFormResident(false);
      setIdentificationValue("")
      setNameValue("")
      setSurnamesValue("")
      setEmailValue("")
      setLoading(false)
    }

    if (statusLocalityLoad === '4') {
      setLoading(false)
      setFinalDeleteDialog(false)
      const newDataState = [...dataResidentLocality];
      let index = newDataState.findIndex((record) => record.person_uuid === removeItem.person_uuid);
      newDataState.splice(index, 1);
      setDataResidentLocality(newDataState);
      setContent('Registro Eliminado Exitosamente');
      viewNotificationSuccess(true);
      setTimeout(() => {
        viewNotificationSuccess(false);
      }, 4000);
    }
    clearResidentLoadData()
    clearRemoveResident()
  }, [statusLoad, statusLocalityLoad])

  React.useEffect(() => {
    if (apiLocalityResidentData?.data !== undefined) {
      setResidentData(apiLocalityResidentData.data)
      setDataResidentLocality(apiLocalityResidentData.data.map((dataItem) => ({
        ...dataItem
      })));
    } else {
      setResidentData([])
      setDataResidentLocality([]);
    }
  }, [apiLocalityResidentData])



  React.useEffect(() => {
    console.log(errorMessageResident)
    //let stmperror = errorMessageResident.includes("SMTPServerDisconnected")
    if (errorMessageResident !== undefined) {
      viewNotificationError(true)
      setContent(errorMessageResident)
      setTimeout(() => {
        viewNotificationError(false)
      }, 4000);
      setLoading(false)
    }
  }, [errorMessageResident])


  const identifications = [
    "CÉDULA",
    "PASAPORTE",
    "RUC"
  ];

  const onChange = (event) => {
    setAllowCustom(event.target.checked);
  };

  return (
    <>
      <GridTelerik
        style={{
          height: "420px",
        }}
        data={dataResidentLocality}
      >
        <GridToolbar>
          <div>
            <button
              title="Agregar Residente"
              className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
              onClick={addResident}
            > <Icon fontSize="medium" sx={{ color: "rgb(235, 234, 232)" }}>
                add_circle
              </Icon>
              Agregar Residente
            </button>
          </div>
        </GridToolbar>
        <GridColumn
          field="person_identification"
          title="IDENTIFICACIÓN"
        //cell={IdentificationCell}
        />

        <GridColumn
          field="person_names"
          title="NOMBRES"
        //cell={NameCell}
        />
        <GridColumn
          field="person_surnames"
          title="APELLIDOS"
        //cell={SurnameCell}
        />

        <GridColumn
          field="person_mail"
          title="EMAIL"
        //cell={EmailCell}
        />
        <GridColumn title="OPCIONES" cell={CommandCell} width="120px" />
      </GridTelerik>

      {openNewResidentForm && (
        <Dialog title={"Nuevo Residente"} width={"400px"}
          onClose={handleCancelDialog}>
          <form className="k-form" onSubmit={handleSubmit}>
            <div className="mb-3">
              <label>TIPO DE IDENTIFICACIÓN*</label>
              <ComboBox
                id="identification_type"
                placeholder="Tipo de Identificación..."
                style={darkModeStyle}
                data={identifications}
                allowCustom={allowCustom}
                value={typeID}
                onChange={handleChangeTypeIdentification}
                required
              />
            </div>
            <div className="mb-3" style={{ marginTop: "3%" }}>
              <label>CÉDULA/PASAPORTE/RUC*</label>
              <Input
                id="person_identification"
                name="person_identification"
                maxLength={32}
                validationMessage="Obligatorio"
                onChange={handleChangeIdentification}
                value={identificationValue}
                style={{
                  width: "85%",
                }}
                required
              />
              {/* <input
                type="button"
                className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                value="Guardar"
                onClick={handleLoadInformation}
              /> */}

              <Button type="button" title="Buscar" fillMode="flat" onClick={handleLoadInformation}
                style={{
                  paddingTop: "10px",
                  margin: "0"
                }}>
                <Icon fontSize="medium" sx={{ color: "rgb(26, 115, 232, 0.8)" }}>
                  search
                </Icon>
              </Button>
            </div>
            <div className="mb-3" style={{ marginTop: "3%" }}>
              <label>NOMBRES*</label>
              <Input
                id={"person_names"}
                name={"person_names"}
                maxLength={60}
                validationMessage="Obligatorio"
                onChange={handleChangeNames}
                value={nameValue}
                required
                style={{
                  width: "100%",
                }}
              />
            </div>
            <div className="mb-3" style={{ marginTop: "3%" }}>
              <label>APELLIDOS*</label>
              <Input
                id={"person_surnames"}
                name={"person_surnames"}
                maxLength={60}
                validationMessage="Obligatorio"
                onChange={handleChangeSurnames}
                value={surnamesValue}
                required
                style={{
                  width: "100%",
                }}
              />
            </div>
            <div className="mb-3" style={{ marginTop: "3%" }}>
              <label>EMAIL*</label>
              <Input
                id={"person_mail"}
                name={"person_mail"}
                maxLength={128}
                validationMessage="Correo Inválido"
                value={emailValue}
                onChange={handleChangeEmail}
                required
                type="email"
                style={{
                  width: "100%",
                }}
              />
            </div>
            {/* <input
              type="submit"
              className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
              value="Guardar"
            /> */}
            <div className="k-form-buttons" style={{ marginLeft: "73%" }}>
              <Button themeColor={"primary"} type="submit" title="Guardar"
              >
                <Icon fontSize="small" sx={{ color: "white" }}>
                  save
                </Icon>
              </Button>

              <Button onClick={cleanForm}>
                <Icon fontSize="small" sx={{ color: "white" }} >
                  backspace_rounded
                </Icon>
              </Button>
            </div>
          </form>
          {notificationError && (
            <NotificationGroup
              style={
                position.bottomRight
              }>
              <Notification
                key="error"
                type={{
                  style: "error",
                }}

                closable={true}
                onClose={() => viewNotificationError(false)}
              >
                {content}
              </Notification>
            </NotificationGroup>
          )}
        </Dialog>
      )}

      {openFormResident && (
        <Dialog title={`EDITAR RESIDENTE`} onClose={handleCancelEdit} width={"400px"}>
          <EditFormResident
            onSubmit={handleSubmitEdit}
            item={editItem}
          >
          </EditFormResident>
          {notificationError && (
            <NotificationGroup
              style={
                position.bottomRight
              }>
              <Notification
                key="error"
                type={{
                  style: "error",
                }}

                closable={true}
                onClose={() => viewNotificationError(false)}
              >
                {content}
              </Notification>
            </NotificationGroup>
          )}
        </Dialog>
      )}

      {notificationError && (
        <NotificationGroup
          style={
            position.bottomRight
          }>
          <Notification
            key="error"
            type={{
              style: "error",
            }}

            closable={true}
            onClose={() => viewNotificationError(false)}
          >
            {content}
          </Notification>
        </NotificationGroup>
      )}

      {notificationSuccess && (
        <NotificationGroup
          style={
            position.bottomRight
          }>
          <Notification
            key="error"
            type={{
              style: "success",
              //margin: "center"
            }}

            closable={true}
            onClose={() => viewNotificationSuccess(false)}
          >
            {content}
          </Notification>
        </NotificationGroup>
      )}

      {loading && (
        <Dialog>
          <Loader
            size={"large"}
            type={"converging-spinner"}
          />
        </Dialog>
      )}

      {finalDeleteDialog && (
        <Dialog title={"ELIMINAR RESIDENTE"}
          onClose={handleCancelDialog}>
          {deleteMessage}
          <br />
          {deleteMessage2}
          <br />
          <div className="k-form-buttons" style={{ marginLeft: "85%" }}>
            <button title="ACEPTAR" className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary k-grid-edit-command"
              onClick={removeResident}
            >
              <Icon fontSize="small" sx={{ color: "white" }}>
                check_circle
              </Icon>
            </button>
            <button title="CANCELAR" className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary k-grid-edit-command"
              onClick={handleCancelDialog}
            >
              <Icon fontSize="small" sx={{ color: "white" }}>
                cancel
              </Icon>
            </button>
          </div>
       
        </Dialog>
        
      )
      }

    </>
  )
}
