/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState, useEffect } from "react";
import { Buffer } from "buffer";

// Material Dashboard 2 PRO React examples
import MDBox from "components/MDBox";

// @mui material components
import { Card, Grid, Icon } from "@mui/material";

// Telerik
import { ComboBox } from "@progress/kendo-react-dropdowns";
import { filterBy } from "@progress/kendo-data-query";
import { DateTimePicker } from "@progress/kendo-react-dateinputs";
import { Button } from "@progress/kendo-react-buttons";
import { Grid as GridTelerik, GridColumn as Column, GridToolbar } from "@progress/kendo-react-grid";
import { Dialog } from "@progress/kendo-react-dialogs";
import { Loader } from "@progress/kendo-react-indicators";
import { process } from "@progress/kendo-data-query";
import { Notification, NotificationGroup } from "@progress/kendo-react-notification";
import { ExcelExport } from "@progress/kendo-react-excel-export";
import { ScrollView } from "@progress/kendo-react-scrollview";

import { useRegisterStore } from "hooks";

import { useUserStore } from 'hooks';
import { Input } from "@progress/kendo-react-inputs";

import { DefaultPhoto } from "config/config";

import excelImage from "assets/images/safe-entry/excel.png";

export const RegisterData = () => {
  const {
    apiRegisterData, errorRegisterMessage,
    apiRegisterPhotos, errorRegisterPhotosMessage,
    loadRegisterData, loadRegisterPhotosData
  } = useRegisterStore();

  const { loadCompanyData, apiCompanyData, errorUserMessage, statusUserLoad } = useUserStore();

  const userData = localStorage.getItem("user");
  const base64dataEnterprises = localStorage.getItem("key1");
  const Enterprises = Buffer.from(base64dataEnterprises, "base64").toString(
    "ascii"
  );
  const dataEnterprises = JSON.parse(Enterprises);

  const [enterprise, setEnterprise] = useState(dataEnterprises[0]);
  const handleEntChange = (event) => {
    setEnterprise(event.target.value);
    setCompany("")
    setFilter("")
  };

  const base64dataCompanies = localStorage.getItem("key2");
  const companies = Buffer.from(base64dataCompanies, "base64").toString(
    "ascii"
  );
  const dataCompanies = JSON.parse(companies);

  const [company, setCompany] = useState(dataCompanies[0]);
  const [filter, setFilter] = React.useState(dataCompanies[0].company_name);
  const [adaptiveFilter, setAdaptiveFilter] = React.useState("");

  const handleConChange = (event) => {
    setCompany(event.target.value);
    setFilter(event.value.company_name);
    setAdaptiveFilter("");
  };

  const today = new Date();
  today.setHours("0", "0", "0", "0")

  const todayn = new Date();

  const [formState, setFormState] = useState({
    startDate: today,
    endDate: today,
    searchPlate: "",
    searchIsland: ""
  });

  const [companiesData, setCompaniesData] = React.useState([]);
  const filterData = (f) => filterBy(companiesData.slice(), f);
  const filterChange = (event) => {
    //console.log(event)
    const request_companies = {
      user_uuid: userData,
      enterprise_uuid: enterprise.enterprise_uuid,
    };
    if (event.filter.value === '') {

      loadCompanyData(request_companies)
    }
    if (event.target.mobileMode) {
      setAdaptiveFilter(event.filter.value);
    } else {
      setFilter(event.filter.value);
      //loadCompanyData(request_companies)
    }
    setCompaniesData(filterData(event.filter))
  };

  const handleClose = () => {
    setAdaptiveFilter("");
  };

  const handleChange = (event) => {
    setFormState({ ...formState, [event.target.name]: event.target.value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoaderState(true);
    // Validar las fechas de incio y fin
    if (formState.endDate < formState.startDate) {
      setVisits([]);
      setLoaderState(false);
      setContent('Fecha de Fin debe ser mayor o igual a la Fecha de Inicio');
      viewNotification(true);
      setTimeout(() => {
        viewNotification(false);
      }, 6000);
    } else {
      let diff = formState.endDate - formState.startDate;
      let days = diff / (1000 * 60 * 60 * 24);
      if (parseInt(days) <= 1) {
        let start_date_data = formState.startDate
        let start_mm = start_date_data.getMonth() + 1;
        let start_dd = start_date_data.getDate();
        let start_yy = start_date_data.getFullYear();
        let start_locale_time = formState.startDate.toLocaleTimeString('en-US', { hour12: false });

        let end_date_data = formState.endDate
        let end_mm = end_date_data.getMonth() + 1;
        let end_dd = end_date_data.getDate();
        let end_yy = end_date_data.getFullYear();
        let end_locale_time = formState.endDate.toLocaleTimeString('en-US', { hour12: false });

        const request_register = {
          enterprise_uuid: enterprise.enterprise_uuid,
          company_uuid: company.company_uuid,
          start_date: start_yy + '-' + start_mm + '-' + start_dd + ' ' + start_locale_time,
          end_date: end_yy + '-' + end_mm + '-' + end_dd + ' ' + end_locale_time,
          search_plate: (formState.searchPlate).toUpperCase(),
          search_island: (formState.searchIsland).toUpperCase()
        };
        console.log({
          start_date: start_yy + '-' + start_mm + '-' + start_dd + ' ' + start_locale_time,
          end_date: end_yy + '-' + end_mm + '-' + end_dd + ' ' + end_locale_time
        });
        loadRegisterData(request_register);
      } else {
        setLoaderState(false);
        setVisits([]);
        setContent('Consultar un día como máximo');
        viewNotification(true);
        setTimeout(() => {
          viewNotification(false);
        }, 6000);
      }
    }
  };

  const darkModeStyle = {
    // color: darkMode ? "rgb(255, 255, 255)" : "rgb(0, 0, 0)",
    color: "rgb(0, 0, 0)",
  };

  const [dataState, setDataState] = React.useState({
    skip: 0,
    take: 5,
  });

  const dataStateChange = (event) => {
    setDataState(event.dataState);
  };

  const handleClickCompany = (event) => {
    const request_companies = {
      user_uuid: userData,
      enterprise_uuid: enterprise.enterprise_uuid,
    };
    loadCompanyData(request_companies)
  }


  const cellWithAccess = (props) => {
    const accessName =
      props.dataItem.access?.access_description !== "AUTORIZADO";
    const icon = accessName ? (
      <span className="k-icon k-i-lock" />
    ) : (
      <span className="k-icon k-i-unlock" />
    );
    const style = {
      color: accessName ? "rgb(255, 128, 0, 0.9)" : "rgb(0, 115, 0, 0.9)",
      textAlign: "center",
    };
    return (
      <td style={{
        textAlign: "center"
      }}>
        {props.dataItem.access?.access_description}
        <p style={style}>{icon}</p>
      </td>
    );
  };

  const cellWithQualification = (props) => {
    const value_qualification = props.dataItem.visit?.qualification;
    return (
      <td
        style={{
          textAlign: "center",
        }}
      >
        {value_qualification === "PULGAR ARRIBA" ? (
          <Icon fontSize="small" sx={{ color: "rgb(0, 115, 0, 0.9)" }}>
            thumb_up
          </Icon>
        ) : value_qualification === "PULGAR ABAJO" ? (
          <Icon fontSize="small" sx={{ color: "rgb(250, 0, 0, 0.9)" }}>
            thumb_down
          </Icon>
        ) : (
          <Icon fontSize="small" sx={{ color: "rgb(156, 158, 160, 0.9)" }}>
            remove
          </Icon>
        )}
      </td>
    );
  };

  const VisitPhotos = ({ dataItem }) => {
    return (
      <td style={{
        textAlign: "center"
      }}>
        <Button
          title="Fotos"
          onClick={() => modalPhotos(dataItem)}
          fillMode="flat"
        >
          <Icon fontSize="small" sx={{ color: "rgb(26, 115, 232, 0.8)" }}>
            photo
          </Icon>
        </Button>
      </td>
    );
  };

  const modalPhotos = (item) => {
    loadRegisterPhotosData(item.binnacle.binnacle_uuid)
  };

  //Notificacion
  const [notification, setNotification] = React.useState(false);
  const viewNotification = (state) => {
    setNotification(state)
  }

  const position = {
    bottomRight: {
      bottom: 5,
      right: 23,
      alignItems: "flex-end",
    },
  }

  const photoBase64 = DefaultPhoto;
  const [photosDialog, setPhotosDialog] = useState(false);
  const [photos, setPhotos] = useState([]);

  const photosToggleDialog = () => {
    setPhotosDialog(!photosDialog);
  };

  useEffect(() => {
    if (apiRegisterPhotos?.data !== undefined) {
      setPhotos(apiRegisterPhotos.data);
      setPhotosDialog(true);
    } else {
      setPhotos([]);
    }
  }, [apiRegisterPhotos])

  const [content, setContent] = useState("<span></span>")

  const [loaderState, setLoaderState] = useState(false)

  const [visits, setVisits] = useState([])

  useEffect(() => {
    if (apiRegisterData?.data !== undefined) {
      setVisits(apiRegisterData.data);
    } else {
      setVisits([]);
    }
    setLoaderState(false);
    setPhotosDialog(false);
  }, [apiRegisterData])

  React.useEffect(() => {
    //console.log(statusUserLoad)
    //console.log(apiCompanyData?.data)
    if (statusUserLoad === '1') {
      setCompaniesData(apiCompanyData?.data)
    } else {
      setCompaniesData([])
    }
  }, [apiCompanyData, statusUserLoad])

  useEffect(() => {
    if (errorRegisterMessage !== undefined) {
      console.log(errorRegisterMessage);
      setLoaderState(false);
      setContent('No existe datos de las fechas seleccionadas');
      viewNotification(true);
      setTimeout(() => {
        viewNotification(false);
      }, 6000);
      setVisits([]);
    }
    if (errorRegisterPhotosMessage !== undefined) {
      console.log(errorRegisterPhotosMessage);
      setContent('Lo sentimos. No se puede obtener las fotos');
      viewNotification(true);
      setTimeout(() => {
        viewNotification(false);
      }, 6000);
      setPhotos([]);
    }
  }, [errorRegisterMessage, errorRegisterPhotosMessage])

  const [loading, setLoading] = React.useState(false);

  const _export = React.useRef(null);

  const excelExport = () => {
    setLoading(true);
    if (_export.current !== null) {
      _export.current.save();
    }
  };

  const handleExport = () => {
    setLoading(false);
  };

  const gridVisit = (
    <div>
      <ExcelExport
        data={visits}
        fileName="Registros.xlsx"
        filterable={true}
        ref={_export}
        onExportComplete={handleExport}>
        <GridTelerik
          style={{
            height: "53vh",
          }}
          pageable={{
            pageSizes: [5, 7, 10, 100, 500, 1000],
            buttonCount: 5,
          }}
          sortable={true}
          filterable={true}
          groupable={false}
          reorderable={false}
          data={process(visits, dataState)}
          {...dataState}
          onDataStateChange={dataStateChange}
        >
          <GridToolbar>
            <button
              title="Exportar Excel"
              className="k-button k-button-md k-rounded-md k-button-solid"
              onClick={excelExport}
            >
              {/* <span className="k-icon k-i-file-excel k-icon-32"></span>Excel
               */}
              <img src={excelImage} width="auto" height="25px" alt="" />
              Excel
            </button>
          </GridToolbar>
          <Column
            field="visit.service_type"
            title="ETAPA"
          />
          <Column
            field="vehicle.plate"
            title="PLACA"
            width="180px"
          />
          <Column
            field="visit.register_date"
            filterable={false}
            filter="date"
            title="FECHA DE ACCESO"
            format="{0:d}"
            width="170px"
          />
          <Column
            field="visit.visitant"
            title="VISITANTE"
            width="250px"
          />
          <Column
            field="visit.qualification"
            filterable={false}
            title="CALIFICACIÓN"
            width="130px"
            cell={cellWithQualification}
          />
          <Column
            field="access.access_description"
            filterable={false}
            width="175px"
            title="ACCESO"
            cell={cellWithAccess}
          />
          <Column title="FOTOS" width="110px" cell={VisitPhotos} filterable={false} />
        </GridTelerik>
      </ExcelExport>
      {loading && (
        <Loader
          size={"large"}
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%,-50%)",
          }}
          type={"infinite-spinner"}
        />
      )}
    </div>
  );

  return (
    <>
      <form className="k-form" onSubmit={handleSubmit}>
        <MDBox>
          <Card>
            <Grid container spacing={3} p={2}>
              <Grid item xs={12} md={6} lg={6}>
                <MDBox>
                  <h6 style={darkModeStyle}>EMPRESA</h6>
                  <ComboBox
                    id="cbxEnterprise"
                    style={darkModeStyle}
                    data={dataEnterprises}
                    textField="enterprise_name"
                    onChange={handleEntChange}
                    placeholder="Seleccione Empresa"
                    value={enterprise}
                    fillMode="outline"
                    required
                    validationMessage="Obligatorio"
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <MDBox onClick={handleClickCompany}>
                  <h6 style={darkModeStyle}>COMPAÑÍA</h6>
                  <ComboBox
                    id="cbxCompany"
                    style={darkModeStyle}
                    data={companiesData}
                    textField="company_name"
                    onChange={handleConChange}
                    placeholder="Seleccione Compañía"
                    value={company}
                    fillMode="outline"
                    required
                    validationMessage="Obligatorio"
                    filterable={true}
                    filter={filter}
                    adaptiveFilter={adaptiveFilter}
                    onFilterChange={filterChange}
                    onClose={handleClose}
                  />
                </MDBox>
              </Grid>
            </Grid>
          </Card>
        </MDBox>
        <MDBox py={2}>
          <Card>
            <Grid container spacing={3} p={2}>
              <Grid item xs={12} md={3} lg={3.5}>
                <MDBox>
                  <h6 style={darkModeStyle}>DESDE</h6>
                  <DateTimePicker
                    width="90%"
                    name="startDate"
                    required
                    format={"dd-MM-yyyy HH:mm:ss"}
                    value={formState.startDate}
                    onChange={handleChange}
                    fillMode="flat"
                    validationMessage="Obligatorio"
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12} md={3} lg={3.5}>
                <MDBox>
                  <h6 style={darkModeStyle}>HASTA</h6>
                  <DateTimePicker
                    width="90%"
                    name="endDate"
                    required
                    format={"dd-MM-yyyy HH:mm:ss"}
                    value={formState.endDate}
                    onChange={handleChange}
                    // min={formState.startDate}
                    fillMode="flat"
                    validationMessage="Obligatorio"
                  />
                </MDBox>
              </Grid>
              <Grid item xs={6} md={1.5} lg={1.5}>
                <h6 style={darkModeStyle}>PLACA</h6>
                <Input
                  name="searchPlate"
                  style={{
                    width: "100%",
                  }}
                  maxLength={8}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={6} md={2.5} lg={2.5}>
                <h6 style={darkModeStyle}>ETAPA</h6>
                <Input
                  name="searchIsland"
                  style={{
                    width: "100%",
                  }}
                  maxLength={100}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} md={2} lg={1}>
                <MDBox>
                  <h6 style={darkModeStyle}>BUSCAR</h6>
                  <Button type="submit" title="Buscar" fillMode="flat">
                    <Icon
                      fontSize="small"
                      sx={{ color: "rgb(26, 115, 232, 0.8)" }}
                    >
                      search
                    </Icon>
                  </Button>
                </MDBox>
              </Grid>
            </Grid>
          </Card>
        </MDBox>
      </form>
      {gridVisit}
      {photosDialog && (
        <Dialog title="FOTOS" onClose={photosToggleDialog}>
          <ScrollView
            style={{
              width: 650,
              height: 450,
            }}
            automaticViewChangeInterval={5000}
            pagerOverlay="dark"
            endless
          >
            {photos.map((item, index) => (
              <div className="image-with-text" key={index}>
                <h2 style={{
                  color: "#fff",
                  textAlign: "center",
                  padding: "0px",
                  margin: "0px",
                  backgroundColor: "rgba(0, 0, 0, 0.8)",
                  fontSize: "20px"
                }}>{item.bph_description}</h2>
                <img
                  src={item.bph_photo_url}
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null;
                    currentTarget.src = photoBase64;
                  }}
                  alt={`Photo ${item.bph_id}`}
                  style={{
                    width: 650,
                    height: 450,
                  }}
                  draggable={false}
                />
              </div>
            ))}
          </ScrollView>
        </Dialog>
      )}
      {notification && (
        <NotificationGroup style={position.bottomRight}>
          <Notification
            key="error"
            type={{
              style: "error",
              icon: true,
            }}
            closable={true}
            onClose={() => viewNotification(false)}
          >
            {content}
          </Notification>
        </NotificationGroup>
      )}
      {loaderState && (
        <Dialog>
          <Loader size="large" type={"converging-spinner"}
          // style={{
          //   position: "absolute",
          //   top: "50%",
          //   left: "50%",
          //   transform: "translate(-50%,-50%)",
          // }} 
          />
        </Dialog>
      )}
    </>
  );
};
