import React from 'react'
//Telerik
import { Grid as GridTelerik, GridToolbar, GridColumn, } from "@progress/kendo-react-grid";
import { Notification, NotificationGroup } from "@progress/kendo-react-notification";
import { Dialog } from "@progress/kendo-react-dialogs";
import { Button } from "@progress/kendo-react-buttons";
import { Input, RadioButton } from "@progress/kendo-react-inputs";

//mui material
import { Icon } from "@mui/material";

//Components
import { EditFormBlackList } from './EditFormBlackList';

//Validators
//import { identificationResidentValidator, nameResidentValidator, radioGroupValidator } from "../../shared/form-validators";

//hooks
import { useResidentStore } from 'hooks';
import { useUserStore } from 'hooks';

export const BlackList = ({ value_localityID, value_company, value_enterprise, company_name }) => {
    const { loadResidentIDData, apiPersonData } = useUserStore();
    const { blackListResident, removeBlackList, updateBlackList, blackListLocalitySave, blackListGeneralSave, statusLoad, apiBlackListResident, errorMessageResident, apiBlackListResidentEdit, apiBlackListResidentSave, clearBlackListStatus } = useResidentStore();

    const user_uuid = localStorage.getItem("user");
    const [dataBlackListLocality, setDataBlackListLocality] = React.useState([]);
    const [blackListData, setBlackListData] = React.useState([]);
    const [editItem, setEditItem] = React.useState();
    const [content, setContent] = React.useState("<span></span>");
    const [notificationSuccess, setNotificationSuccess] = React.useState(false);
    const viewNotificationSuccess = (state) => {
        setNotificationSuccess(state)
    }

    const [notificationError, setNotificationError] = React.useState(false);
    const viewNotificationError = (state) => {
        setNotificationError(state)
    }

    const position = {
        bottomRight: {
            bottom: 0,
            right: 0,
            alignItems: "flex-end",
        }
    }

    const [expandMode, setExpandMode] = React.useState("multiple");
    const handleExpandModeChange = (e) => {
        setExpandMode(e.target.value);
    };

    const [openNewBlackList, setOpenNewBlackList] = React.useState(false);
    const addBlackList = () => {
        setOpenNewBlackList(true)
        setIdentificationValue("")
        setNameValue("")
    }

    const [openUpdateBlackList, setOpenUpdateBlackList] = React.useState(false);

    const enterEdit = (item) => {
        if (item.bli_gbl_environment === 'ENTERPRISE') {
            setContent("No tiene permisos para editar a esta persona")
            viewNotificationError(true);
            setTimeout(() => {
                viewNotificationError(false);
            }, 6000);
        } else {
            setOpenUpdateBlackList(true);
            setEditItem(item)
        }
    }

    const [editItemData, setEditItemData] = React.useState(undefined);
    const handleSubmitEdit = (event) => {
        setEditItemData(event);
        const request_update = {
            bli_gbl_id: event.bli_gbl_id,
            bli_gbl_uuid: event.bli_gbl_uuid,
            bli_gbl_value: event.bli_gbl_value,
            bli_gbl_description: event.bli_gbl_description,
            bli_gbl_environment: event.bli_gbl_environment,
            bli_gbl_locality_id: value_localityID,
            bli_gbl_modifier_id: user_uuid,
            bli_gbl_company_uuid: value_company
        }
        updateBlackList(request_update)
    }
    const handleCancel = () => {
        setOpenNewBlackList(false)
    }

    const handleCancelUpdate = () => {
        setOpenUpdateBlackList(false)
    }

    const CommandCell = (props) => {
        //console.log("pruebas ")
        const { dataItem } = props;
        return <td className="k-command-cell">
            <button title="Editar Persona" className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary k-grid-edit-command" onClick={() => enterEdit(dataItem)}>
                <Icon fontSize="small" sx={{ color: "white" }}>
                    edit
                </Icon>
            </button>
            <button title="Eliminar Persona" className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base k-grid-remove-command" onClick={() => remove(dataItem)}>
                <Icon fontSize="small" sx={{ color: "white" }}>
                    delete
                </Icon>
            </button>
        </td>;
    };

    const [removeItem, setRemoveItem] = React.useState(undefined);

    const remove = (dataItem) => {
        //console.log(dataItem);
        setRemoveItem(dataItem);
        if ((dataItem.bli_gbl_environment === "ENTERPRISE")) {
            setContent("No tiene permisos para eliminar a esta persona")
            viewNotificationError(true);
            setTimeout(() => {
                viewNotificationError(false);
            }, 6000);
        }
        const request_remove = {
            bli_gbl_id: dataItem.bli_gbl_id,
            bli_gbl_uuid: dataItem.bli_gbl_uuid,
            bli_gbl_deletion_id: user_uuid,
            bli_gbl_environment: dataItem.bli_gbl_environment,
            bli_gbl_locality_id: value_localityID,
            bli_gbl_company_uuid: value_company
        }
        //console.log(request_remove)
        removeBlackList(request_remove)
    };

    const blackListTypeCell = (props) => {
        return (
            <td>
                {props.dataItem.bli_gbl_environment === 'COMPANY' ? 'COMPAÑÍA' : props.dataItem.bli_gbl_environment === 'ENTERPRISE' ? 'EMPRESA' : 'LOCALIDAD'}
            </td>
        )
    }
    const [identificationValue, setIdentificationValue] = React.useState();
    const [nameValue, setNameValue] = React.useState("");

    const handleLoadInformation = () => {
        let valuePersonID = document.getElementById("bli_gbl_value").value;

        const request_loadID = {
            identification_type: "CED",
            identification: valuePersonID,
        }
        //console.log(request_loadID)
        loadResidentIDData(request_loadID)
    }

    const handleChangeIdentification = (event) => {
        setIdentificationValue(event.target.value)
    }

    const handleChangeNames = (event) => {
        setNameValue(event.target.value)
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        //console.log(selectedValue)
        const request_saveLocalBlackList = {
            bli_type: "IDENTIFICACION",
            bli_value: (event.target.bli_gbl_value.value).toUpperCase(),
            bli_description: (event.target.bli_gbl_description.value).toUpperCase(),
            bli_file: false,
            bli_locality_id: value_localityID,
            bli_enterprise_id: 1,
            bli_creator_id: user_uuid,
            bli_company_uuid: value_company
        }

        const request_saveGeneralBlackList = {
            gbl_type: "IDENTIFICACION",
            gbl_value: (event.target.bli_gbl_value.value).toUpperCase(),
            gbl_description: (event.target.bli_gbl_description.value).toUpperCase(),
            gbl_enterprise_id: 1,
            gbl_creator_id: user_uuid,
            gbl_black_list_type: 0,
            gbl_locality_id: value_localityID,
            gbl_company_uuid: value_company
        }

        if (selectedValue === 'locality') {
            blackListLocalitySave(request_saveLocalBlackList)
        } else {
            blackListGeneralSave(request_saveGeneralBlackList)
        }

    };



    React.useEffect(() => {
        if (apiPersonData?.data !== undefined) {
            setNameValue(apiPersonData?.data.names + " " + apiPersonData?.data.surnames)
        }
    }, [apiPersonData])

    React.useEffect(() => {
        if (statusLoad === '2') {
            setContent('Registro Actualizado Exitosamente');
            viewNotificationSuccess(true);
            setTimeout(() => {
                viewNotificationSuccess(false);
            }, 4000);
            setOpenUpdateBlackList(false)

        }

        if (statusLoad === '3') {
            setContent('Registro Guardado Exitosamente');
            viewNotificationSuccess(true);
            setTimeout(() => {
                viewNotificationSuccess(false);
            }, 4000);
            setOpenNewBlackList(false)
        }

        if (statusLoad === '4') {
            const newDataState = [...dataBlackListLocality];
            //console.log(record)
            let index = newDataState.findIndex((record) => record.bli_gbl_id === removeItem.bli_gbl_id);
            newDataState.splice(index, 1);
            setDataBlackListLocality(newDataState)
        }

        clearBlackListStatus()
    }, [statusLoad])


    React.useEffect(() => {
        if (apiBlackListResidentEdit?.data !== undefined) {
            setBlackListData(apiBlackListResidentEdit);
            setDataBlackListLocality(apiBlackListResidentEdit.data.map((dataItem) => ({
                ...dataItem
            })));
        } else {
            setBlackListData([])
            setDataBlackListLocality([]);
        }
    }, [apiBlackListResidentEdit])



    React.useEffect(() => {
        if (apiBlackListResidentSave?.data !== undefined) {
            setBlackListData(apiBlackListResidentSave);
            setDataBlackListLocality(apiBlackListResidentSave.data.map((dataItem) => ({
                ...dataItem
            })));
        } else {
            setBlackListData([])
            setDataBlackListLocality([]);
        }
    }, [apiBlackListResidentSave])

    React.useEffect(() => {
        if (errorMessageResident !== undefined) {
            console.log(errorMessageResident);
            setContent(errorMessageResident);
            viewNotificationError(true);
            setTimeout(() => {
                viewNotificationError(false);
            }, 4000);
        }
    }, [errorMessageResident])

    React.useEffect(() => {
        if (apiBlackListResident?.data !== undefined) {
            setBlackListData(apiBlackListResident);
            setDataBlackListLocality(apiBlackListResident.data.map((dataItem) => ({
                ...dataItem
            })));
        } else {
            setBlackListData([])
            setDataBlackListLocality([]);
        }
    }, [apiBlackListResident])


    const blackListValues = [
        {
            label: "Localidad",
            value: "locality",
            className: "task red"
        },
        {
            label: "Compañía",
            value: "company",
            className: "task orange"
        }
    ]
    const [selectedValue, setSelectedValue] = React.useState("locality");
    const handleChange = React.useCallback(
        (e) => {
            setSelectedValue(e.value);
        },
        [setSelectedValue]
    );

    const cleanForm = () => {
        setIdentificationValue("")
        setNameValue("")
    }

    return (
        <>
            <GridTelerik
                style={{
                    height: "420px",
                }}
                data={dataBlackListLocality}
            >
                <GridToolbar>
                    <div>
                        <button
                            title="Add new"
                            className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                            onClick={addBlackList}
                        >
                            <Icon fontSize="medium" sx={{ color: "rgb(235, 234, 232)" }}>
                                add_circle
                            </Icon>
                            Agregar Persona
                        </button>

                        {/* <button
                                title="Exportar Excel"
                                className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                                onClick={exportExcel}
                            >
                                <Icon fontSize="small" sx={{ color: "white" }}>
                                    file_download_outlined
                                </Icon> Excel
                            </button> */}
                    </div>
                </GridToolbar>

                <GridColumn field="bli_gbl_value" title="IDENTIFICACIÓN" />
                <GridColumn field="bli_gbl_description" title="NOMBRES" />
                <GridColumn field="bli_gbl_environment" title="TIPO" cell={blackListTypeCell} />
                <GridColumn title="OPCIONES" cell={CommandCell} width="120px" />



            </GridTelerik>

            {openNewBlackList && (
                <Dialog
                    onClose={handleCancel}
                    title={"Añadir a Lista Negra"}
                    width={"400px"}>
                    <form className="k-form" onSubmit={handleSubmit}>
                        <div className="mb-3">
                            <label>TIPO*</label>
                            <br />
                            <RadioButton
                                name="locality"
                                value="locality"
                                checked={selectedValue === "locality"}
                                label="LOCALIDAD"
                                onChange={handleChange}
                            />

                            <RadioButton
                                name="company"
                                value="company"
                                checked={selectedValue === "company"}
                                label={company_name}
                                onChange={handleChange}
                                style={{
                                    marginLeft: "42px"
                                }}
                            />
                        </div>
                        <br />
                        <div className="mb-3">
                            <label>CÉDULA/PASAPORTE/RUC*</label>
                            <Input
                                id="bli_gbl_value"
                                name="bli_gbl_value"
                                maxLength={32}
                                value={identificationValue}
                                onChange={handleChangeIdentification}
                                validationMessage="Obligatorio"

                                style={{
                                    width: "86%",
                                }}
                                required
                            />
                            <Button type="button" title="Buscar" fillMode="flat" onClick={handleLoadInformation}
                                style={{
                                    paddingTop: "10px",
                                    margin: "0"
                                }}>
                                <Icon fontSize="medium" sx={{ color: "rgb(26, 115, 232, 0.8)" }}>
                                    search
                                </Icon>
                            </Button>
                        </div>
                        <br />
                        <div className="mb-3">
                            <label>NOMBRES*</label>
                            <Input
                                id={"bli_gbl_description"}
                                name={"bli_gbl_description"}
                                maxLength={64}
                                validationMessage="Obligatorio"
                                onChange={handleChangeNames}
                                value={nameValue}
                                required
                                style={{
                                    width: "100%",
                                }}
                            />
                        </div>
                        <div className="k-form-buttons" style={{ marginLeft: "73%" }}>

                            <Button themeColor={"primary"} type="submit" title="Guardar"
                            >
                                <Icon fontSize="small" sx={{ color: "white" }}>
                                    save
                                </Icon>
                            </Button>

                            <Button onClick={cleanForm}>
                                <Icon fontSize="small" sx={{ color: "white" }} >
                                    backspace_rounded
                                </Icon>
                            </Button>

                        </div>
                    </form>

                    {notificationError && (
                        <NotificationGroup
                            style={
                                position.bottomRight
                            }>
                            <Notification
                                key="error"
                                type={{
                                    style: "error",
                                }}

                                closable={true}
                                onClose={() => viewNotificationError(false)}
                            >
                                {content}
                            </Notification>
                        </NotificationGroup>
                    )}
                </Dialog>
            )}

            {openUpdateBlackList && (
                <EditFormBlackList
                    cancelEdit={handleCancelUpdate}
                    onSubmit={handleSubmitEdit}
                    item={editItem}>

                </EditFormBlackList>
            )}


            {notificationSuccess && (
                <NotificationGroup
                    style={
                        position.bottomRight
                    }>
                    <Notification
                        key="error"
                        type={{
                            style: "success",
                            //margin: "center"
                        }}

                        closable={true}
                        onClose={() => viewNotificationSuccess(false)}
                    >
                        {content}
                    </Notification>
                </NotificationGroup>
            )}
        </>
    )
}
