import React from 'react';
import { ApiIA } from '../shared/ApiIA';

export const DataProvider = props => {
  const [data, setData] = React.useState([]);
  const intervalRef = React.useRef(null);
  const onDataReset = React.useCallback(async (volume = 8) => {
    clearInterval(intervalRef.current);
    const plateData = new ApiIA();
    setData(await plateData.generateDataLive(volume, props.formState));
  }, []);
  const onStartLiveUpdate = React.useCallback(interval => {
    clearInterval(intervalRef.current);
    const plateData = new ApiIA();
    intervalRef.current = setInterval(async () => setData(await plateData.generateDataLive(8, props.formState)), interval);
  }, []);
  return React.Children.map(props.children, child => {
    if (!React.isValidElement(child)) {
      return;
    }
    return <child.type {...child.props} data={data} onDataReset={onDataReset} onStartLiveUpdate={onStartLiveUpdate}  />;
  });
};