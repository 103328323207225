/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";

// react-router components
import { useLocation, Link } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// import { Buffer } from "buffer";

// @material-ui core components
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Icon from "@mui/material/Icon";
// import { Grid } from "@mui/material";

// import { ComboBox } from "@progress/kendo-react-dropdowns";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
// import MDInput from "components/MDInput";
// import MDBadge from "components/MDBadge";

// Material Dashboard 2 PRO React examples
import Breadcrumbs from "examples/Breadcrumbs";

// Custom styles for DashboardNavbar
import {
  navbar,
  navbarContainer,
  navbarRow,
  navbarIconButton,
  navbarDesktopMenu,
  navbarMobileMenu,
} from "examples/Navbars/DashboardNavbar/styles";

// Material Dashboard 2 PRO React context
import {
  useMaterialUIController,
  setTransparentNavbar,
  setMiniSidenav,
  // setOpenConfigurator,
} from "context";

function DashboardNavbar({ absolute, light, isMini }) {

  const [navbarType, setNavbarType] = useState();
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, transparentNavbar, fixedNavbar, darkMode } = controller;
  const route = useLocation().pathname.split("/").slice(1);

  useEffect(() => {
    // Setting the navbar type
    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }

    // A function that sets the transparent state of the navbar.
    function handleTransparentNavbar() {
      setTransparentNavbar(dispatch, (fixedNavbar && window.scrollY === 0) || !fixedNavbar);
    }

    /** 
     The event listener that's calling the handleTransparentNavbar function when 
     scrolling the window.
    */
    window.addEventListener("scroll", handleTransparentNavbar);

    // Call the handleTransparentNavbar function to set the state with the initial value.
    handleTransparentNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);

  // Styles for the navbar icons
  const iconsStyle = ({ palette: { dark, white, text }, functions: { rgba } }) => ({
    color: () => {
      let colorValue = light || darkMode ? white.main : dark.main;

      if (transparentNavbar && !light) {
        colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
      }

      return colorValue;
    },
  });

  // const darkModeStyle = {
  //   color: "rgb(0, 0, 0)",
  // };

  // const base64dataEnterprises = localStorage.getItem("key1");
  // const Enterprises = Buffer.from(base64dataEnterprises, "base64").toString("ascii");
  // const dataEnterprises = JSON.parse(Enterprises);

  // const base64dataCompanies = localStorage.getItem("key2");
  // const Companies = Buffer.from(base64dataCompanies, "base64").toString("ascii");
  // const dataCompanies = JSON.parse(Companies);

  // const [enterprise, setEnterprise] = useState(dataEnterprises[0]);
  // const handleEntChange = (event) => {
  //   setEnterprise(event.target.value);
  //   if (event.target.value !== null)
  //     localStorage.setItem('enterprise-select', event.target.value.enterprise_id);
  //   else
  //     localStorage.setItem('enterprise-select', -1);
  // };

  // const [company, setCompany] = useState(dataCompanies[0]);
  // const handleConChange = (event) => {
  //   setCompany(event.target.value);
  //   if (event.target.value !== null)
  //     localStorage.setItem('company-select', event.target.value.company_id);
  //   else
  //     localStorage.setItem('company-select', -1);
  // };

  return (
    <AppBar
      position={absolute ? "absolute" : navbarType}
      color="inherit"
      sx={(theme) => navbar(theme, { transparentNavbar, absolute, light, darkMode })}
    >
      <Toolbar sx={(theme) => navbarContainer(theme)}>
        <MDBox color="inherit" mb={{ xs: 1, md: 0 }} sx={(theme) => navbarRow(theme, { isMini })}>
          <Breadcrumbs icon="home" title={route[route.length - 1]} route={route} light={light} />
          <IconButton sx={navbarDesktopMenu} onClick={handleMiniSidenav} size="small" disableRipple>
            <Icon fontSize="medium" sx={iconsStyle}>
              {miniSidenav ? "menu_open" : "menu"}
            </Icon>
          </IconButton>
        </MDBox>
        {isMini ? null : (
          <MDBox sx={(theme) => navbarRow(theme, { isMini })}>
            {/* <MDBox>
              <Grid container spacing={1}>
                <Grid item xs={12} md={6} lg={6}>
                  <MDBox>
                    <h6 style={darkModeStyle}>EMPRESA</h6>
                    <ComboBox
                      id="cbxEnterprise"
                      style={darkModeStyle}
                      data={dataEnterprises}
                      textField="enterprise_name"
                      onChange={handleEntChange}
                      placeholder="Selecione Empresa ..."
                      value={enterprise}
                      fillMode="outline"
                      required
                    />
                  </MDBox>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <MDBox>
                    <h6 style={darkModeStyle}>COMPAÑIA</h6>
                    <ComboBox
                      id="cbxCompany"
                      style={darkModeStyle}
                      data={dataCompanies}
                      textField="company_name"
                      onChange={handleConChange}
                      placeholder="Selecione Compañia ..."
                      value={company}
                      fillMode="outline"
                      required
                    />
                  </MDBox>
                </Grid>
              </Grid>
            </MDBox> */}
            <MDBox color={light ? "white" : "inherit"}>
              <Link to="/safeentry/login">
                <IconButton sx={navbarIconButton} size="small" disableRipple>
                  <Icon sx={iconsStyle}>exit_to_app</Icon><h6> Salir</h6>
                </IconButton>
              </Link>
              <IconButton
                size="small"
                disableRipple
                color="inherit"
                sx={navbarMobileMenu}
                onClick={handleMiniSidenav}
              >
                <Icon sx={iconsStyle} fontSize="medium">
                  {miniSidenav ? "menu_open" : "menu"}
                </Icon>
              </IconButton>
            </MDBox>
          </MDBox>
        )}
      </Toolbar>
    </AppBar>
  );
}

// Setting default values for the props of DashboardNavbar
DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
};

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
};

export default DashboardNavbar;
