import * as React from "react";
import * as ReactDOM from "react-dom";
import moment from "moment";

import { toODataString } from "@progress/kendo-data-query";
import { ApiIA } from '../shared/ApiIA';

export const PlatesLoader = (props) => {

  const token = localStorage.getItem('tokenIA');

  const timestamp_startDate = moment(props.dataForm.startDate).valueOf() / 1000;
  console.log(timestamp_startDate);
  const timestamp_endDate = moment(props.dataForm.endDate).valueOf() / 1000;
  console.log(timestamp_endDate);
  let apiName = props.company !== undefined && props.company !== null ? props.company.company_name.toLowerCase(): "";

  if (apiName.toUpperCase() === 'BANCO GUAYAQUIL')
    apiName = 'bg'

  const searchLocate = props.dataForm.searchLocate;
  const searchCamera = props.dataForm.searchCamera;
  const searchPlate = props.dataForm.searchPlate;
  const searchCarType = props.dataForm.searchCarType;
  const searchMark = props.dataForm.searchMark;
  const searchColor = props.dataForm.searchColor;
  const searchDriverName = props.dataForm.searchDriverName;
  const searchDriverLastName = props.dataForm.searchDriverLastName;

  const baseUrl = `https://lpr.ai.telconet.net/${apiName}/consumer/events/${timestamp_startDate}/${timestamp_endDate}?limit=${props.dataState.take}&plate=${searchPlate}&car_type=${searchCarType}&car_color=${searchColor}&car_make=${searchMark}&locate=${searchLocate}&camera__name=${searchCamera}&driver__name=${searchDriverName}&driver__last_name=${searchDriverLastName}&no_page=false&offset=`;

  const init = {
    method: "GET",
    accept: "application/json",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  const lastSuccess = React.useRef("");
  const pending = React.useRef("");
  const requestDataIfNeeded = () => {
    if (
      pending.current ||
      toODataString(props.dataState) === lastSuccess.current
    ) {
      return;
    }
    pending.current = toODataString(props.dataState);
    // console.log('Filters: ')
    // console.log(pending.current);
    fetch(baseUrl + props.dataState.skip, init)
      .then((response) => response.json())
      .then(async (json) => {
        if(json.results === undefined){
          // Llamar al servicio de IA para generar el token
          const tokenIA = new ApiIA();
          await tokenIA.getToken();
          props.onDataReceived.call(undefined, {
            data: [],
            total: 0
          });
        }
        lastSuccess.current = pending.current;
        pending.current = "";
        if (toODataString(props.dataState) === lastSuccess.current) {
          props.onDataReceived.call(undefined, {
            data: json.results,
            total: json.count
          });
        } else {
          requestDataIfNeeded();
        }
      })
      .catch(error => {
        console.error('Error en la solicitud api:', error);
        lastSuccess.current = pending.current;
        pending.current = "";
        if (toODataString(props.dataState) === lastSuccess.current) {
          props.onDataReceived.call(undefined, {
            data: [],
            total: 0
          });
        }
      });
  };
  requestDataIfNeeded();
  return pending.current ? <LoadingPanel /> : null;
};
const LoadingPanel = () => {
  const loadingPanel = (
    <div className="k-loading-mask">
      <span className="k-loading-text">Loading</span>
      <div className="k-loading-image" />
      <div className="k-loading-color" />
    </div>
  );
  const gridContent = document && document.querySelector(".k-grid-content");
  return gridContent
    ? ReactDOM.createPortal(loadingPanel, gridContent)
    : loadingPanel;
};
