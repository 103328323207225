/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";
import { Buffer } from "buffer";

// Material Dashboard 2 PRO React examples
import MDBox from "components/MDBox";

// @mui material components
import { Card, Grid, Icon } from "@mui/material";

//hooks

// Telerik
import { ComboBox } from "@progress/kendo-react-dropdowns";
import { filterBy } from "@progress/kendo-data-query";
import { Button } from "@progress/kendo-react-buttons";
import { process } from "@progress/kendo-data-query";
import { Grid as GridTelerik, GridToolbar, GridColumn } from "@progress/kendo-react-grid";
import { Dialog } from "@progress/kendo-react-dialogs";
import { Notification, NotificationGroup } from "@progress/kendo-react-notification";
import { Loader } from "@progress/kendo-react-indicators";

//pictures

import excelImage from "assets/images/safe-entry/excel.png";

//Hooks
import { useMassiveStore } from "hooks";
import { useUserStore } from 'hooks';

//
import './stylesfile.css';



export const UploadData = () => {

  const { clearLogsData, clearDownloadData, statusMassiveLoad, loadTowerData, apiTowereData, loadMassiveFileData, deleteFile, errorMassiveDataMessage, errorUploadDataMessage,
    loadLogFileData, errorLogsDataMessage, apiFileLogData, apiLogsData, downloadFile, apiDownlaodData, uploadFile, apiUploadData } = useMassiveStore();

  const { loadCompanyData, apiCompanyData, errorUserMessage, statusUserLoad } = useUserStore();

  const initialDataState = {
    sort: [
      {
        field: "locality.id_localidad",
        dir: "desc",
      },
    ],
    take: 5,
    skip: 0,
  };

  const position = {
    bottomRight: {
      bottom: 0,
      right: 0,
      alignItems: "flex-end",
    },
    topRight: {
      top: 5,
      left: "95%",
      alignItems: "flex-end",
    },
    bottomLeft: {
      bottom: 0,
      left: "96%",
      alignItems: "flex-start",
    },
  }

  const [logDialog, setLogDialog] = React.useState(false)

  const [dataState, setDataState] = React.useState(initialDataState);
  const [notification, setNotification] = React.useState(false);
  const [massiveFiles, setMassiveFiles] = React.useState([])
  const [logs, setLogs] = React.useState([])
  const [selectFile, setSelectFile] = React.useState(false)
  const [loading, setLoading] = React.useState(false);
  const [messageFile, setMessageFile] = React.useState("SELECCIONAR ARCHIVO")
  const base64dataEnterprises = localStorage.getItem("key1");
  const Enterprises = Buffer.from(base64dataEnterprises, "base64").toString(
    "ascii"
  );
  const dataEnterprises = JSON.parse(Enterprises);
  const userData = localStorage.getItem("user");
  const [enterprise, setEnterprise] = React.useState(dataEnterprises[0]);
  const handleEntChange = (event) => {
    setEnterprise(event.target.value);
    setCompany("")
    setFilter("")
  };

  const base64dataCompanies = localStorage.getItem("key2");
  const companies = Buffer.from(base64dataCompanies, "base64").toString(
    "ascii"
  );
  const dataCompanies = JSON.parse(companies);

  const [company, setCompany] = React.useState(dataCompanies[0]);
  const [filter, setFilter] = React.useState(dataCompanies[0].company_name);
  const [adaptiveFilter, setAdaptiveFilter] = React.useState("");

  const handleConChange = (event) => {
    setCompany(event.target.value);
    setFilter(event.value.company_name);
    setAdaptiveFilter("");
  };
  
  const [companiesData, setCompaniesData] = React.useState([]);
  const filterData = (f) => filterBy(companiesData.slice(), f);
  const filterChange = (event) => {
    //console.log(event)
    const request_companies = {
      user_uuid: userData,
      enterprise_uuid: enterprise.enterprise_uuid,
    };
    if (event.filter.value === ''){
      
      loadCompanyData(request_companies)
    }
    if (event.target.mobileMode) {
      setAdaptiveFilter(event.filter.value);
    } else {
      setFilter(event.filter.value);
      //loadCompanyData(request_companies)
    }
    setCompaniesData(filterData(event.filter))
  };

  const handleClose = () => {
    setAdaptiveFilter("");
  };

  const handleClickCompany = (event) => {
    const request_companies = {
      user_uuid: userData,
      enterprise_uuid: enterprise.enterprise_uuid,
    };
    loadCompanyData(request_companies)
  }


  const [notificationSuccess, setNotificationSuccess] = React.useState();
  const viewNotificationSuccess = (state) => {
    setNotificationSuccess(state)
  }

  const [errorNotification, setErrorNotification] = React.useState();
  const viewNotification = (state) => {
    setNotification(state)
  }

  const [content, setContent] = React.useState();

  const [tower, setTower] = React.useState("")
  const handleTowerChange = (event) => {
    //setTower(event.target.value)
  }

  const darkModeStyle = {
    // color: darkMode ? "rgb(255, 255, 255)" : "rgb(0, 0, 0)",
    color: "rgb(0, 0, 0)",
  };

  const [selectedFile, setSelectedFile] = React.useState([]);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    setMessageFile(file.name)
  };

  const handleSubmitFile = async (event) => {
    event.preventDefault();
    setLoading(true)
    if (selectedFile) {
      //console.log('Archivo seleccionado:', selectedFile);
      const request_upload_data = {
        uuid_company: company.company_uuid,
        uuid_enterprise: enterprise.enterprise_uuid,
        uuid_user_process: userData
      }
      const formData = new FormData();
      formData.append('data', JSON.stringify(request_upload_data));
      formData.append('file', selectedFile);
      //const formDataEntries = Array.from(formData.entries());
      uploadFile(formData)
    }
    //setSelectFile(false)
    setMessageFile("SELECCIONAR ARCHIVO")
  };

  const handleCancelUploadFile = () => {
    setSelectFile(false)
    setMessageFile("SELECCIONAR ARCHIVO")
  };

  const handleMassiveUpload = (event) => {
    setSelectFile(true)
  }

  const cellWidthState = (props) => {
    //VALIDACION DE ESTADOS
    //PROCESADO
    //ERROR
    //POR SINCRONIZAR
    const access_value = props.dataItem.mrl_state_process;
    const accessDescription =
      props.dataItem.mrl_state_process == null
        ? "N/D"
        : props.dataItem.mrl_state_process;
    switch (access_value) {
      case "PROCESADO":
        return (
          <td
            style={{
              textAlign: "center",
            }}
          >
            {/* <span>{accessDescription}</span> */}
            <span>{"POR SINCRONIZAR"}</span>
            <br />
            {/* <Icon fontSize="small" sx={{ color: "rgb(0, 115, 0, 0.9)" }}>
              sync
            </Icon> */}
            <Icon fontSize="small" sx={{ color: "rgb(235, 205, 55)" }}>
              sync
            </Icon>
          </td>
        );

      case "VALIDACIÓN CON ERRORES":
        return (
          <td
            style={{
              textAlign: "center",
            }}
          >
            {/* <span>{accessDescription}</span> */}
            <span>{"ERROR"}</span>
            <br />
            <Icon fontSize="small" sx={{ color: "rgb(235, 50, 50)" }}>
              error
            </Icon>
          </td>
        );

      case "ERROR EN PROCESO":
        return (
          <td
            style={{
              textAlign: "center",
            }}
          >
            {/* <span>{accessDescription}</span> */}
            <span>{"ERROR"}</span>
            <br />
            <Icon fontSize="small" sx={{ color: "rgb(235, 50, 50)" }}>
              error
            </Icon>
          </td>
        );
        case "VALIDADO":
          return (
            <td
              style={{
                textAlign: "center",
              }}
            >
              {/* <span>{accessDescription}</span> */}
              <span>{"INTENTE NUEVAMENTE"}</span>
              <br />
              {/* <Icon fontSize="small" sx={{ color: "rgb(0, 115, 0, 0.9)" }}>
                  sync
                </Icon> */}
              <Icon fontSize="small" sx={{ color: "rgb(26, 115, 232)" }}>
                replay
              </Icon>
            </td>
          );

      case "POR SINCRONIZAR":
        return (
          <td
            style={{
              textAlign: "center",
            }}
          >
            <span>{accessDescription}</span>
            <br />
            <Icon fontSize="small" sx={{ color: "rgb(235, 205, 55)" }}>
              sync
            </Icon>
          </td>
        );
        default:
        return (
          <td
            style={{
              textAlign: "center",
            }}
          >
            <span>{accessDescription}</span>
            <br />
            <Icon fontSize="small" sx={{ color: "rgb(235, 50, 50)" }}>
              error
            </Icon>
          </td>
        );
    }
  }

  const handleDownloadFile = (event) => {
    event.preventDefault();
    const request_download = {
      uuid_company: company.company_uuid
    }
    downloadFile(request_download)
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    const request_tower_data = {
      uuid_company: company.company_uuid
    }
    loadMassiveFileData(request_tower_data)
  }

  const LogDetail = ({ dataItem }) => {
    return (
      <td
        style={{
          textAlign: "center",
        }}
      >
        <Button
          title="Detalles"
          onClick={() => modalDetailLogs(dataItem)}
          fillMode="flat"
        >
          <Icon fontSize="small" sx={{ color: "rgb(26, 115, 232, 0.8)" }}>
            article
          </Icon>
        </Button>
      </td>
    );
  }

  const modalDetailLogs = (item) => {
    const request_logs = {
      mrl_uuid: item.mrl_uuid
    }
    loadLogFileData(request_logs)
  }

  const logToggleDialog = () => {
    setLogDialog(!logDialog);
    setSelectFile(false)
    clearLogsData();
  };

  React.useEffect(() => {
    //console.log(apiCompanyData?.data)
    if (statusUserLoad === '1') {
      setCompaniesData(apiCompanyData?.data)
    } else {
      setCompaniesData([])
    }
  }, [apiCompanyData, statusUserLoad])

  React.useEffect(() => {
    if (apiFileLogData?.data !== undefined) {
      //console.log(apiFileLogData?.data)
      setMassiveFiles(apiFileLogData?.data)
      setLoading(false)
    } else {
      setMassiveFiles([]);
    }
  }, [apiFileLogData])


  React.useEffect(() => {
    if (apiLogsData?.data !== undefined) {
      //console.log(apiLogsData?.data)
      setLogs(apiLogsData?.data)
      setLogDialog(true);
      setLoading(false)
    } else {
      setLogs([])
    }
  }, [apiLogsData])

  React.useEffect(() => {
    setLoading(false)
    if (apiUploadData?.data !== undefined) {
      setLoading(false)
      setSelectFile(false)
      setContent("Archivo Cargado Exitosamente")
      viewNotificationSuccess(true)
      setTimeout(() => {
        viewNotificationSuccess(false);
      }, 4000);
      setMessageFile("SELECCIONAR ARCHIVO")
      loadMassiveFileData({ uuid_company: company.company_uuid })
    }
  }, [apiUploadData])


  React.useEffect(() => {
    if (apiDownlaodData?.data !== undefined) {
      //saveAs(apiDownlaodData?.data, "PlantillaSafeEntry.xlsx");
      const fileURL = apiDownlaodData?.data;
      const fileName = apiDownlaodData?.data;
      const fileNamef = fileName.slice(39)
      //console.log(fileNamef)
      //console.log("ulr: " + fileURL)
      //console.log("nombredelarchivo: " + fileNamef)
      fetch(fileURL)
        .then(response => response.blob())
        .then(blob => {
          // Crear un enlace para descargar el archivo
          const downloadLink = document.createElement('a');
          downloadLink.href = URL.createObjectURL(blob);
          downloadLink.download = fileNamef;
          // Simular un clic en el enlace para iniciar la descarga
          downloadLink.click();
          // Limpiar el objeto URL creado
          URL.revokeObjectURL(downloadLink.href);

        })
        .catch(error => {
          console.error('Error al descargar el archivo:', error);
        });
      const request_delete = {
        file_name: fileURL
      }
      deleteFile(request_delete)
      clearDownloadData();
    }
  }, [apiDownlaodData?.data])


  React.useEffect(() => {
    console.log(errorUploadDataMessage?.message)
    if (errorUploadDataMessage?.message !== undefined) {
      if (errorUploadDataMessage?.message === 'Error al Validar la Cabecera del Archivo. Valor No válido par la Celda[A0]') {
        viewNotification(true)
        setLoading(false)
        setErrorNotification("Formato de Archivo Inválido")
        //console.log(errorUploadDataMessage)
        //setLogs(errorUploadDataMessage?.data)
        //setLogDialog(true)
      } if (errorUploadDataMessage?.message === 'Se encontraron errores al validar el archivo'){
        viewNotification(true)
        setLoading(false)
        setErrorNotification(errorUploadDataMessage?.message)
        console.log(errorUploadDataMessage?.data)
        setLogs(errorUploadDataMessage?.data)
        setLogDialog(true)
      } else {
        viewNotification(true)
        setLoading(false)
        setErrorNotification(errorUploadDataMessage?.message)
        console.log(errorUploadDataMessage?.data)
        //setLogs(errorUploadDataMessage?.data)
        //setLogDialog(true)
      }
      setTimeout(() => {
        viewNotification(false)
      }, 8000);
      loadMassiveFileData({ uuid_company: company.company_uuid })
    }
  }, [errorUploadDataMessage?.message])

  React.useEffect(() => {
    if (errorLogsDataMessage !== undefined) {
      viewNotification(true)
      setLoading(false)
      setErrorNotification("No Se Encontraron Detalles de Errores")
      setTimeout(() => {
        viewNotification(false)
      }, 4000);
    }
  }, [errorLogsDataMessage])

  React.useEffect(() => {
    if (errorMassiveDataMessage !== undefined) {
      //viewNotification(true)
      setLoading(false)
      setMassiveFiles([])
      //setErrorNotification("No Se Encontraron Detalles de Errores")

    }
  }, [errorMassiveDataMessage])

  return (
    <>
      <form className="k-form" onSubmit={handleSubmit}
      >
        <MDBox>
          <Card>
            <Grid container spacing={3} p={2}>
              <Grid item xs={12} md={6} lg={6}>
                <MDBox>
                <h6 style={darkModeStyle}>EMPRESA</h6>
                  <ComboBox
                    id="cbxEnterprise"
                    style={darkModeStyle}
                    data={dataEnterprises}
                    textField="enterprise_name"
                    onChange={handleEntChange}
                    placeholder="Seleccione Empresa"
                    value={enterprise}
                    fillMode="outline"
                    required
                    validationMessage="Obligatorio"
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12} md={5} lg={5}>
                <MDBox onClick={handleClickCompany} >
                <h6 style={darkModeStyle}>COMPAÑÍA</h6>
                  <ComboBox
                    id="cbxCompany"
                    style={darkModeStyle}
                    data={companiesData}
                    textField="company_name"
                    onChange={handleConChange}
                    placeholder="Seleccione Compañía"
                    value={company}
                    fillMode="outline"
                    required
                    validationMessage="Obligatorio"
                    filterable={true}
                    filter={filter}
                    adaptiveFilter={adaptiveFilter}
                    onFilterChange={filterChange}
                    onClose={handleClose}
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12} md={1} lg={1}>
                <MDBox>
                  <h6 style={darkModeStyle}>BUSCAR</h6>
                  <Button type="submit" title="Buscar" fillMode="flat">
                    <Icon fontSize="medium" sx={{ color: "rgb(26, 115, 232, 0.8)" }}>
                      search
                    </Icon>
                  </Button>
                </MDBox>
              </Grid>
            </Grid>
          </Card>
        </MDBox>

      </form>
      <MDBox py={1}>
        <GridTelerik
          pageable={{
            pageSizes: [5, 7, 10, 20, 30, 50, 100],
          }}
          resizable
          reorderable={true}
          filterable={true}
          sortable={true}
          data={process(massiveFiles, dataState)}
          {...dataState}
          onDataStateChange={(e) => {
            setDataState(e.dataState);
          }}
          style={{
            height: "60vh",
            //width: "500",
          }}
        >
          <GridToolbar>
            <button
              title="DESCARGAR PLANTILLA"
              className="k-button k-button-md k-rounded-md k-button-solid "
              onClick={handleDownloadFile}
            >
              <img src={excelImage} width="auto" height="25px" alt="" />
              DESCARGAR PLANTILLA
              {/* <DescargarArchivo url={urlDelArchivo} nombreArchivo={nombreArchivo}/> */}
            </button>

            <button
              title="CARGA MASIVA"
              className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
              onClick={handleMassiveUpload}
            >

              <Icon fontSize="medium" sx={{ color: "rgb(235, 234, 232)" }}>
                cloud_upload
              </Icon>
              CARGA MASIVA
            </button>

            {/* <button
              title="SINCRONIZACIÓN"
              className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
            >
              <Icon fontSize="medium" sx={{ color: "rgb(235, 234, 232)" }}>
                sync
              </Icon>
              SINCRONIZACIÓN
            </button> */}

          </GridToolbar>
          <GridColumn
            field="mrl_id"
            title="SECUENCIA"
            width="100px"
            filterable={false}
          />

          <GridColumn
            field="mrl_file_name"
            title="ARCHIVO"
            width="500px"
            filterable={false}
          />

          <GridColumn
            field="mrl_last_time"
            title="FECHA"
            width="280px"
            filterable={false}
          />

          <GridColumn
            field="mrl_state_process"
            title="ESTADO"
            width="280px"
            cell={cellWidthState}
            filterable={false}
          />

          <GridColumn
            //field="locality_catalog.catalog_desc"
            title="DETALLE"
            width="280px"
            filterable={false}
            cell={LogDetail}
          />

        </GridTelerik>

      </MDBox>

      {logDialog && (
        <Dialog title="DETALLES" onClose={logToggleDialog}>
          <GridTelerik
            style={{
              maxWidth: "80vh",
              height: "35vh",
            }}
            data={logs}
            take={5}
            fixedScroll={true}
          //scrollable={"virtual"}
          >
            <GridColumn
              field="log_id"
              title="SECUENCIA"
              width="100px"
            //cell={cellWithNotifiedTo}
            />
            <GridColumn
              field="log_error"
              title="DETALLE DE ERROR"
            //width="200px"
            //cell={cellWithNotifiedTo}
            />
          </GridTelerik>
          {notification && (
            <NotificationGroup
              style={
                position.bottomRight
              }>
              <Notification
                key="error"
                type={{
                  style: "error",
                  //margin: "center"
                }}

                closable={true}
                onClose={() => viewNotification(false)}
              >
                {errorNotification}
              </Notification>
            </NotificationGroup>
          )}
        </Dialog>
      )}

      {selectFile && (
        <Dialog
          onClose={handleCancelUploadFile}
          title={"CARGA DE DATOS MASIVOS"}
          width={600}
          height={300}
        >
          <form onSubmit={handleSubmitFile}>
            <div className="container-input">
              <input className="inputfile inputfile-1" id="file-input" type="file" onChange={handleFileChange} accept=".xlsx"
              />
              <label for="file-input">
                <span className="iborrainputfile">{messageFile}</span>
              </label>
            </div>
            <div className="center-div">
              <button
                title="CARGAR ARCHIVO"
                className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                type="submit"
              >
                <Icon fontSize="medium" sx={{ color: "rgb(235, 234, 232)" }}>
                  save
                </Icon>
                CARGAR ARCHIVO
              </button>
            </div>

          </form>
          {notification && (
            <NotificationGroup
              style={
                position.bottomRight
              }>
              <Notification
                key="error"
                type={{
                  style: "error",
                  //margin: "center"
                }}

                closable={true}
                onClose={() => viewNotification(false)}
              >
                {errorNotification}
              </Notification>
            </NotificationGroup>
          )}
          {loading && (
            <Dialog>
              <Loader
                size={"large"}
                type={"converging-spinner"}
              />
            </Dialog>
          )}
        </Dialog >

      )
      }
      {notificationSuccess && (
        <NotificationGroup
          style={
            position.bottomRight
          }>
          <Notification
            key="error"
            type={{
              style: "success",
              //margin: "center"
            }}

            closable={true}
            onClose={() => viewNotificationSuccess(false)}
          >
            {content}
          </Notification>
        </NotificationGroup>
      )}

      {notification && (
        <NotificationGroup
          style={
            position.bottomRight
          }>
          <Notification
            key="error"
            type={{
              style: "error",
              //margin: "center"
            }}

            closable={true}
            onClose={() => viewNotification(false)}
          >
            {errorNotification}
          </Notification>
        </NotificationGroup>
      )}

    </>
  )
}