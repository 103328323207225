import React from 'react'
import { Dialog } from "@progress/kendo-react-dialogs";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { Icon } from "@mui/material";
import {
  FormInput,
  FormComboBox,
} from "../../shared/form-components";

import {
  nameResidentValidator,
  identificationResidentValidator,
  emailResidentValidator,
  surnameResidentValidator,
  typeValidator,
} from "../../shared/form-validators";

export const EditFormBlackList = (props) => {
 // console.log(props)
  return (
    <Dialog title={`EDITAR DATOS`} onClose={props.cancelEdit} width={"400px"}>
      <Form
        onSubmit={props.onSubmit}
        initialValues={props.item}
        render={(formRenderProps) => (
          <FormElement
            style={{
              maxWidth: 500,
            }}
          >
            <fieldset className={"k-form-fieldset"}>
              <Field
                id={"bli_gbl_value"}
                name={"bli_gbl_value"}
                label={"CÉDULA/PASAPORTE/RUC*"}
                component={FormInput}
                validator={identificationResidentValidator}
                maxLength={32}
              />
              <Field
                id={"bli_gbl_description"}
                name={"bli_gbl_description"}
                label={"NOMBRES*"}
                component={FormInput}
                validator={nameResidentValidator}
                maxLength={60}
            />
            </fieldset>
            <div className="k-form-buttons" style={ {marginLeft: "73%" }}>
              <button
                type={"submit"}
                className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                disabled={!formRenderProps.allowSubmit}
              >
                <Icon fontSize="small" sx={{ color: "white" }}>
                  save
                </Icon>
              </button>
              <button
                type={"submit"}
                className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                onClick={props.cancelEdit}
              ><Icon fontSize="small" sx={{ color: "white" }}>
                  cancel
                </Icon>
              </button>
            </div>
          </FormElement>
        )}
      />
    </Dialog>
  )
}

