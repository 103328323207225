/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import moment from "moment";
// @mui material components
import { Icon } from "@mui/material";
import { ColorTranslator } from "./color-translator";

import { DefaultPhoto } from "config/config";

export const LocalityCell = (props) => {
  const { dataItem, field } = props;
  const fieldName = field || "";
  return (
    <td>
      <span>{dataItem[fieldName].toUpperCase()}</span>
    </td>
  );
};

export const CameraCell = (props) => {
  const { dataItem, field } = props;
  const fieldName = field || "";
  return (
    <td>
      <span>{dataItem[fieldName].toUpperCase()}</span>
    </td>
  );
};

export const DateCell = (props) => {
  const { dataItem, field } = props;
  const fieldName = field || "";
  const formattedDate = moment.unix(dataItem[fieldName]).format('DD/MM/YYYY HH:mm:ss');
  return (
    <td>
      <span>{formattedDate}</span>
    </td>
  );
};

export const ColorCell = (props) => {
  const { dataItem, field } = props;
  const fieldName = field || "";
  const translatedColor = new ColorTranslator();
  const colorName = translatedColor.translateColor(dataItem[fieldName]);
  return (
    <td>
      <span>{colorName}</span>
    </td>
  );
};

export const DriverCell = (props) => {
  const { dataItem, field } = props;
  const fieldName = field || "";
  return (
    <td>
      <span>
        {dataItem[fieldName].length === 0
          ? ""
          : (dataItem[fieldName][0].last_name).toUpperCase() +
          " " +
          (dataItem[fieldName][0].name).toUpperCase()}
      </span>
    </td>
  );
};

export const DriverNameCell = (props) => {
  const { dataItem, field } = props;
  const fieldName = field || "";
  return (
    <td>
      <span>
        {dataItem[fieldName].length === 0
          ? "N/D"
          : (dataItem[fieldName][0].name).toUpperCase()
        }
      </span>
    </td>
  );
};

export const DriverLastNameCell = (props) => {
  const { dataItem, field } = props;
  const fieldName = field || "";
  return (
    <td>
      <span>
        {dataItem[fieldName].length === 0
          ? "N/D"
          : (dataItem[fieldName][0].last_name).toUpperCase()
        }
      </span>
    </td>
  );
};

export const PlateImgCell = (props) => {
  const { dataItem, field } = props;
  const fieldName = field || "";

  const imageUrl = dataItem[fieldName];
  const defaultImageUrl = DefaultPhoto;

  const handleImageError = (event) => {
    event.target.src = defaultImageUrl; // Cambiar a la imagen por defecto en caso de error
  };

  return (
    <td>
      <img src={imageUrl} onError={handleImageError} style={{ height: 45 }} />
    </td>
  );
};

export const AccessCell = (props) => {
  const { dataItem, field } = props;
  const fieldName = field || "";
  switch (dataItem[fieldName]) {
    case "Autorizado":
      return (
        <td
          style={{
            textAlign: "center",
          }}
        >
          <span>{dataItem[fieldName].toUpperCase()}</span>
          <br />
          <Icon fontSize="small" sx={{ color: "rgb(0, 115, 0, 0.9)" }}>
            no_crash
          </Icon>
        </td>
      );
    case "No Autorizado":
      return (
        <td
          style={{
            textAlign: "center",
          }}
        >
          <span>{dataItem[fieldName].toUpperCase()}</span>
          <br />
          <Icon fontSize="small" sx={{ color: "rgb(255, 0, 0, 0.9)" }}>
            car_crash
          </Icon>
        </td>
      );
    default:
      return (
        <td
          style={{
            textAlign: "center",
          }}
        >
          <span>{dataItem[fieldName].toUpperCase()}</span>
          <br />
          <Icon fontSize="small" sx={{ color: "rgb(255, 130, 0, 0.9)" }}>
            car_crash
          </Icon>
        </td>
      );
  }
};

export const GenericCell = (props) => {
  const { dataItem, field } = props;
  const fieldName = field || "";
  return (
    <td>
      <span>{dataItem[fieldName] !== undefined ? dataItem[fieldName].toUpperCase() : ""}</span>
    </td>
  );
};


export const DateExcelCell = (props) => {
  const { dataItem, field } = props;
  const fieldName = field || "";
  const formattedDate = moment.unix(dataItem[fieldName]).format('DD/MM/YYYY HH:mm:ss');
  return (
    formattedDate
  );
};