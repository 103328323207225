import React from 'react'
import "./stylesVideo.css";

// Telerik
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import {
  Toolbar,
  ToolbarItem,
  ToolbarSeparator,
  ToolbarSpacer,
  Button
} from "@progress/kendo-react-buttons";

import { VideoHls } from './VideoHls';


export const VideoPlateGridLayout = (props) => {

  // console.warn(props.listCameras);

  const [changeCamera, setChangeCamera] = React.useState(true);

  const [itemsValue, setItemsValue] = React.useState(2);

  const totalItems = props.listCameras.total;
  const itemsPerRow = itemsValue;

  const rows = Math.ceil(totalItems / itemsPerRow);
  const cols = itemsPerRow;

  const gridVideo = (value) => {
    setItemsValue(value);
  }

  return (
    <>
      {props.listCameras.total !== 0 && (
        <div className="video-wrapper">
          <div className="page">
            <Toolbar>
              <ToolbarSpacer />
              <ToolbarSeparator />
              <ToolbarItem>
                <Button icon="grid" title="1" onClick={() => gridVideo(1)}>
                  1
                </Button>
              </ToolbarItem>
              <ToolbarItem>
                <Button icon="grid" title="2" onClick={() => gridVideo(2)}>
                  2
                </Button>
              </ToolbarItem>
              <ToolbarItem>
                <Button icon="grid" title="3" onClick={() => gridVideo(3)}>
                  3
                </Button>
              </ToolbarItem>
              <ToolbarItem>
                <Button icon="grid" title="4" onClick={() => gridVideo(4)}>
                  4
                </Button>
              </ToolbarItem>
            </Toolbar>
            <br/>
            <div className="content">
              <GridLayout
                rows={[...Array(rows)].map(() => ({ height: 'auto' }))}
                cols={[...Array(cols)].map(() => ({ width: 'auto' }))}
                gap={{
                  rows: 3,
                  cols: 3,
                }}
              >
                {[...Array(totalItems)].map((_, i) => (
                  <GridLayoutItem className={'box-video'} key={i} >
                    <VideoHls
                      cameraUrl={props.listCameras.total !== 0 ? props.listCameras.data[i]?.url : ""}
                      changeCamera={changeCamera}
                      setChangeCamera={setChangeCamera}
                    />
                  </GridLayoutItem>
                ))}
              </GridLayout>
            </div>
          </div>
        </div>
      )}
    </>
  )
}
