import { createSlice } from '@reduxjs/toolkit';

export const residentSlice = createSlice({
  name: 'resident',
  initialState: {
    statusLoad: '0',
    apiResidentData: {},
    apiDataNewResident:{},
    apiLocalityContactData: {},
    apiLocalityContactSave: {},
    apiLocalityContactEdit: {},
    apiLocalityResidentSave:{},
    apiVehicleData:{},
    apiVehicleDescription:{},
    apiVehicleSave: {},
    apiVehicleEdit: {},
    apiBlackListResident:{},
    apiBlackListResidentEdit: {},
    apiBlackListResidentSave: {},
    errorMessageResident: undefined,
    errorMessageContactPhone:undefined,
    errorMessageBlackList:undefined,
    errorMessageVehicle:undefined,
  },
  reducers: {
    
    onLoadVehicleData: (state, {payload}) => {
      state.statusLoad = '1'
      state.apiVehicleData = payload;
      state.errorMessageVehicle = undefined;
    },

    onLoadVehicleDescription: (state, {payload}) => {
      state.statusLoad = '1'
      state.apiVehicleDescription = payload;
      state.errorMessageVehicle = undefined;
    },

    onErrorVehicleData: (state, { payload }) => {
      state.statusLoad = '0';
      state.errorMessageVehicle = payload;
    },

    onNewVehicle: (state, { payload }) => {
      state.statusLoad = '3';
      state.apiVehicleSave = payload;
      state.errorMessageResident = undefined;
    },

    onUpdateVehicle: (state, { payload }) => {
      state.statusLoad = '2';
      state.apiVehicleEdit = payload;
      state.errorMessageResident = undefined;
    },

    onVehicleDelete:(state) => {
      state.statusLoad = '4';
      state.errorMessageResident = undefined;
    },

    onLoadBlackListResident: (state, {payload}) => {
      state.statusLoad = '1'
      state.apiBlackListResident = payload;
      state.errorMessageBlackList = undefined;
    },

    onErrorBlackListData: (state, { payload }) => {
      state.statusLoad = '0';
      state.errorMessageBlackList = payload;
    },

    onBlackListDelete: (state) => {
      state.statusLoad = '4';
      state.errorMessageResident = undefined;
    },

    onUpdateBlackList: (state, { payload }) => {
      state.statusLoad = '2';
      state.apiBlackListResidentEdit = payload;
      state.errorMessageResident = undefined;
    },

    onNewBlackList: (state, { payload }) => {
      state.statusLoad = '3';
      state.apiBlackListResidentSave = payload;
      state.errorMessageResident = undefined;
    },

    clearResidentData:(state)=> {
      state.statusLoad = '0'
    },

    onLoadResidentData: (state, { payload }) => {
      state.statusLoad = '1';
      state.apiResidentData = payload;
      state.errorMessageResident = undefined;
    },

    onUpdateResidentData: (state) => {
      state.statusLoad = '1';
      state.errorMessageResident = undefined;
    },

    onUpdateResident: (state, {payload}) => {
      state.statusLoad = '2';
      state.apiLocalityResidentSave = payload; 
      state.errorMessageResident = undefined;
    },

    onNewResidentLocality: (state, { payload }) => {
      state.statusLoad = '3';
      state.errorMessageResident = undefined;
      state.apiDataNewResident = payload;
    },

    onNewContact: (state, { payload }) => {
      state.statusLoad = '3';
      state.apiLocalityContactSave = payload;
      state.errorMessageResident = undefined;
    },

    onLoadContactLocalityList: (state, { payload }) => {
      state.statusLoad = '1';
      state.apiLocalityContactData = payload;
      state.errorMessageContactPhone = undefined;
    },

    onErrorContactPhone: (state, { payload }) => {
      state.statusLoad = '0';
      state.errorMessageContactPhone = payload;
    },

    onContactDelete: (state) => {
      state.statusLoad = '4';
      state.errorMessageResident = undefined;
    },

    onUpdateContactPhone: (state, { payload }) => {
      state.statusLoad = '2';
      state.apiLocalityContactEdit = payload;
      state.errorMessageResident = undefined;
    },

    onErrorData: (state, { payload }) => {
      state.statusLoad = '0';
      state.errorMessageResident = payload;
    },

    clearErrorContactPhoneMessage: ( state ) => {
      state.errorMessageContactPhone = undefined;
    },

    clearErrorBlacklistMessage: ( state ) => {
      state.errorMessageBlackList = undefined;
    },

    clearErrorVehicleMessage: ( state ) => {
      state.errorMessageVehicle = undefined;
    },

    clearVehicleData: (state) => {
      state.statusLoad = '0';
      state.apiVehicleData = undefined;
    },

    clearVehicleStatus: (state) => {
      state.statusLoad = '0';
    },

    clearerrorMessageResident: ( state ) => {
      state.errorMessageResident = undefined;
    },

    clearLocalityContactData: (state) => {
      state.statusLoad = '0';
      state.apiLocalityContactData = undefined;
    },

    clearLocalityContactStatus: (state) => {
      state.statusLoad = '0';
    },
    
    clearBlackListDataStatus: (state) => {
      state.statusLoad = '0';
    },

    clearBlackListData: (state) => {
      state.statusLoad = '0';
      state.apiBlackListResident = undefined;
    }
  }
});


// Action creators are generated for each case reducer function
export const { onNewVehicle, onUpdateVehicle, onVehicleDelete, clearResidentData, onErrorData, clearVehicleStatus, clearerrorMessageResident, onNewResidentLocality, onLoadVehicleData,
  onLoadResidentData, onUpdateResidentData, onContactDelete, onNewResident,onUpdateContactPhone, onErrorVehicleData, clearVehicleData,
   onNewContact, onLoadContactLocalityList, clearLocalityContactData, clearLocalityContactStatus, clearErrorVehicleMessage, onLoadVehicleDescription,
   onLoadBlackListResident, onBlackListDelete, onErrorBlackListData, onUpdateBlackList, onNewBlackList,clearBlackListDataStatus, 
   onUpdateResident, clearBlackListData, clearErrorBlacklistMessage, clearErrorContactPhoneMessage, onErrorContactPhone } = residentSlice.actions;