import React from 'react'
import { Notification, NotificationGroup } from "@progress/kendo-react-notification";
import { Dialog } from "@progress/kendo-react-dialogs";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { Icon } from "@mui/material";
import {
  FormInput,
  FormComboBox,
  FormDatePicker,
} from "../../shared/form-components";

import {
  nameResidentValidator,
  identificationResidentValidator,
  emailResidentValidator,
  surnameResidentValidator,
  typeValidator,
  requiredValidator,
} from "../../shared/form-validators";


import { DatePicker } from "@progress/kendo-react-dateinputs";

export const EditFormPreauthorized = (props) => {
  //console.log(props)
  //console.log(value_start_date)
  const [typeID, setTypeID] = React.useState()
  const handleChangeTypeIdentification = (event) => {
    if (event.target.value === 'CÉDULA') {
      setTypeID("CED")
    }
    if (event.target.value === 'PASAPORTE') {
      setTypeID("PAS")
    }
    if (event.target.value === 'RUC') {
      setTypeID("RUC")
    }
  }
  const today = new Date();
  const tomorrow = new Date();
  const [formState, setFormState] = React.useState({
    start_date: props.value_start_date,
    end_date: props.value_end_date,
    //**validar */
    //endDate: tomorrow, //reviasr
  });

  const handleChange = (event) => {
    //setFormState({ ...formState, [event.target.name]: event.target.value });
    //console.log(event.value)
    setFormState({ ...formState, [event.target.name]: event.target.value });
    // setFormState({
    //   start_date:event.value,
    //   end_date:event.value
    // })
  };

  return (
    <Dialog title={`Editar Preautorizado`} onClose={props.cancelEdit} width={"400px"}>
      <Form
        onSubmit={props.onSubmit}
        initialValues={props.item}
        render={(formRenderProps) => (
          <FormElement
            style={{
              maxWidth: 500,
            }}
          >
            <fieldset className={"k-form-fieldset"}>
              <Field
                id={"pre_value"}
                name={"pre_value"}
                label={"CÉDULA/PASAPORTE/RUC*"}
                component={FormInput}
                validator={identificationResidentValidator}
                maxLength={32}
              />
              <Field
                id={"pre_description"}
                name={"pre_description"}
                label={"NOMBRES*"}
                component={FormInput}
                validator={nameResidentValidator}
                maxLength={60}
              />
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}>
                <Field
                  //id={"start_date"}
                  name={"start_date"}
                  label={"FECHA INICIO*"}
                  component={FormDatePicker}
                  onChange={handleChange}
                  defaultValue={formState.start_date}
                  //value={formState.start_date}
                  min={today}
                  required
                  validationMessage={
                    formState.start_date === null ? "OBLIGATORIO" : "FECHA INVÁLIDA"
                }
                  //max={formState.end_date}
                  //validator={nameResidentValidator}
                  //validationMessage="FECHA INVÁLIDA"
                  maxLength={60}
                />
                <Field
                  //id={"end_date"}
                  name={"end_date"}
                  label={"FECHA FIN*"}
                  component={FormDatePicker}
                  defaultValue={formState.end_date}
                  onChange={handleChange}
                  min={today}
                  //validationMessage="FECHA INVÁLIDA"
                  valid={
                    formState.start_date !== null &&
                    formState.end_date !== null &&
                    formState.start_date.getTime() <= formState.end_date.getTime()
                  }
                  fillMode="flat"
                  validationMessage={
                    formState.end_date === null ? "OBLIGATORIO" : "FECHA INVÁLIDA"
                  }
                  required
                  //validator={requiredValidator}
                  //validator={}
                  maxLength={60}
                />

              </div>

            </fieldset>
            <div className="k-form-buttons" style={{ marginLeft: "73%" }}>
              <button
                type={"submit"}
                className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                disabled={!formRenderProps.allowSubmit}
              //onClick={formRenderProps.onSubmit}
              >
                <Icon fontSize="small" sx={{ color: "white" }}>
                  save
                </Icon>
              </button>
              <button
                type={"submit"}
                className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                onClick={props.cancelEdit}
              ><Icon fontSize="small" sx={{ color: "white" }}>
                  cancel
                </Icon>
              </button>
            </div>
          </FormElement>
        )}
      />
    </Dialog>
  )
}

