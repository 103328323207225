import React from 'react'

// Material Dashboard 2 PRO React components
import MDTypography from "components/MDTypography";

import MDBox from "components/MDBox";

// import MDInput from "components/MDInput";
import { Input } from "@progress/kendo-react-inputs";
import { Button } from "@progress/kendo-react-buttons";
import { RecoveryPasswordPage } from './RecoveryPasswordPage';
import md5 from "md5";
import { NotificationGroup, Notification } from '@progress/kendo-react-notification';
import { Checkbox } from "@progress/kendo-react-inputs";

// @mui material components


//Componentes
import LoginPage from './LoginPage';

// Hooks
import { useUserStore } from "hooks/useUserStore";

export const CodeRecoveryPage = ({ user_email }) => {

    const position = {
        bottomRight: {
            bottom: 0,
            right: 0,
            alignItems: "flex-end",
        },
        topRight: {
            top: 5,
            left: "95%",
            alignItems: "flex-end",
        },
        bottomLeft: {
            bottom: 0,
            left: "92%",
            alignItems: "flex-start",
        },
    }

    const { passwordCode, apiCodePassword, clearCodePage, errorUserMessage, errorCodeMessage, statusUserLoad, statusUserCode } = useUserStore();

    const [backRecoveryAction, setBackRecoveryAction] = React.useState(true)
    const [recoveryPasswordPage, setRecoveryPasswordPage] = React.useState(false)
    const [useremail, setUseremail] = React.useState(user_email)
    const [codeRecovery, setCodeRecovery] = React.useState()
    const [newPassword, setNewPassword] = React.useState()
    const [reconfirmPassword, setReconfirmPassword] = React.useState()
    const [content, setContent] = React.useState();
    const [notification, setNotification] = React.useState(false)
    const [notificationSucess, setNotificationSucess] = React.useState(false)
    const [loginForm, setLoginForm] = React.useState(false)
    const [recoveryAction, setRecoveryAction] = React.useState(false)

    const handleCodeRecoveryChange = (event) => {
        //console.log(event.target.value)
        setCodeRecovery(event.target.value)
    }

    const handleNewPasswordChange = (event) => {
        setNewPassword(event.target.value)
    }

    const handleReconfirmPasswordChange = (event) => {
        setReconfirmPassword(event.target.value)
    }

    const handleRecoveryCode = (event) => {
        event.preventDefault();
        if (newPassword === reconfirmPassword) {
            const request_recovery = {
                user_mail: user_email,
                password: md5(newPassword),
                code: codeRecovery,
            }
            //console.log(request_recovery)
            passwordCode(request_recovery)
        } else {
            setContent("Las Contraseñas No Coinciden")
            setNotification(true)
            setTimeout(() => {
                setNotification(false);
            }, 4000);
        }
    }

    const handleBackRecovery = (event) => {
        //console.log(event)
        setBackRecoveryAction(false)
        setRecoveryPasswordPage(true)
    }

    React.useEffect(() => {
        if (errorCodeMessage !== undefined) {
            console.log(errorCodeMessage)
            setContent("Código Incorrecto. Por Favor Validar.")
            setNotification(true)
            setTimeout(() => {
                setNotification(false);
            }, 4000);

        }
    }, [errorCodeMessage])

    React.useEffect(() => {
        if (statusUserCode === '1') {
            setContent("Contraseña Actualizada Exitosamente")
            setNotificationSucess(true)
            setTimeout(() => {
                setNotificationSucess(false);
                //setBackRecoveryAction(false)
                //setRecoveryPasswordPage(false)
            }, 2000);

            setTimeout(() => {
                //window.location.href = 'https://localhost:300/safeentry/login'
                //window.location.href = 'https://iyd.telconet.ec/safeentry/login'
                window.location.href = 'https://iyd.telconet.ec/safeentry/login'
            }, 2000)
            //setLoginForm(true)
            //setRecoveryAction(true)
        }

    }, [statusUserCode])

    // React.useEffect(() => {
    //     if (apiCodePassword?.data !== undefined) {
    //         console.log(apiCodePassword?.data)
    //        //clearCodePage();
    //     }
    // }, [apiCodePassword])


    return (
        <div className="row">
            <div className="offset-sm-12">
                {backRecoveryAction && (
                    <form className="k-form" onSubmit={handleRecoveryCode}>
                        <div>
                            <MDTypography
                                // to="/safeentry/recovery"
                                variant="h3"
                                color="light"
                                fontWeight="medium"
                                textGradient
                            >
                                Restablecer Contraseña
                            </MDTypography>
                            <br />
                            <br />
                            <MDTypography
                                // to="/safeentry/recovery"
                                variant="h6"
                                color="light"
                                fontWeight="medium"
                                textGradient
                            >
                                Ingrese el código de seguridad enviado a su correo electrónico
                            </MDTypography>
                            <br />
                            <MDTypography
                                // to="/safeentry/recovery"
                                variant="button"
                                color="light"
                                fontWeight="medium"
                                textGradient
                            >
                                Usuario
                            </MDTypography>

                            <Input
                                validityStyles={false}
                                name="email"
                                type="email"
                                style={{
                                    width: "100%",
                                    color: "rgb(255, 255, 255)",
                                    backgroundColor: "rgb(102, 105, 98, 0.6)",
                                    borderRadius: "15px",
                                    borderColor: "rgb(255, 255, 255, 0.3)",
                                }}
                                required={true}
                                value={useremail}
                                //onChange={onInputChange}
                                validationMessage="Obligatorio"
                            />

                            <MDTypography
                                // to="/safeentry/recovery"
                                variant="button"
                                color="light"
                                fontWeight="medium"
                                textGradient
                            >
                                Código
                            </MDTypography>

                            <Input
                                validityStyles={false}
                                name="codeRecovery"
                                //type="email"
                                style={{
                                    width: "100%",
                                    color: "rgb(255, 255, 255)",
                                    backgroundColor: "rgb(102, 105, 98, 0.6)",
                                    borderRadius: "15px",
                                    borderColor: "rgb(255, 255, 255, 0.3)",
                                }}
                                required
                                maxLength={6}
                                minLength={6}

                                //value={codeRecovery}
                                onChange={handleCodeRecoveryChange}
                                validationMessage="Obligatorio"
                            />
                            <br />
                            <br />
                            <MDTypography
                                // to="/safeentry/recovery"
                                variant="h6"
                                color="light"
                                fontWeight="medium"
                                textGradient
                            >
                                Ingrese su nueva contraseña
                            </MDTypography>
                            <br />
                            <MDTypography
                                // to="/safeentry/recovery"
                                variant="button"
                                color="light"
                                fontWeight="medium"
                                textGradient
                            >
                                Nueva Contraseña
                            </MDTypography>

                            <Input
                                validityStyles={false}
                                name="newPassword"
                                // type="email"
                                style={{
                                    width: "100%",
                                    color: "rgb(255, 255, 255)",
                                    backgroundColor: "rgb(102, 105, 98, 0.6)",
                                    borderRadius: "15px",
                                    borderColor: "rgb(255, 255, 255, 0.3)",
                                }}
                                required
                                type="password"
                                //value={newPassword}
                                onChange={handleNewPasswordChange}
                                validationMessage="Obligatorio"
                            />
                            <MDTypography
                                // to="/safeentry/recovery"
                                variant="button"
                                color="light"
                                fontWeight="medium"
                                textGradient
                            >
                                Confirmar Contraseña
                            </MDTypography>

                            <Input
                                validityStyles={false}
                                name="reconfirmPassword"
                                //type="email"
                                style={{
                                    width: "100%",
                                    color: "rgb(255, 255, 255)",
                                    backgroundColor: "rgb(102, 105, 98, 0.6)",
                                    borderRadius: "15px",
                                    borderColor: "rgb(255, 255, 255, 0.3)",
                                }}
                                required

                                type="password"
                                //value={reconfirmPassword}
                                onChange={handleReconfirmPasswordChange}
                                validationMessage="Obligatorio"
                            />
                        </div>
                        <div className="k-form-buttons">
                            <Button
                                style={{
                                    width: "100%",
                                    height: "42px",
                                    color: "rgb(255, 255, 255)",
                                    backgroundColor: "rgb(0, 0, 0)",
                                    borderRadius: "15px",
                                    borderColor: "rgb(102, 105, 98, 0.6)",
                                }}
                                //className="buttons-container-button"
                                // themeColor={"dark"}
                                size="small"
                                type="submit"
                            // disabled={disabledButton}
                            >
                                RESTABLECER
                            </Button>
                            <Button
                                style={{
                                    width: "100%",
                                    height: "42px",
                                    color: "rgb(0, 0, 0)",
                                    backgroundColor: "rgb(255, 255, 255)",
                                    borderRadius: "15px",
                                    borderColor: "rgb(102, 105, 98, 0.6)",
                                }}
                                //className="buttons-container-button"
                                // themeColor={"dark"}
                                size="small"
                                //type="submit"
                                onClick={handleBackRecovery}
                            //disabled={disabledButton}
                            >
                                REGRESAR
                            </Button>

                            {notification && (
                                <NotificationGroup
                                    style={
                                        position.bottomRight
                                    }>
                                    <Notification
                                        key="error"
                                        type={{
                                            style: "error",
                                            //margin: "center"
                                        }}

                                        closable={true}
                                    //onClose={() => viewNotification(false)}
                                    >
                                        {content}
                                    </Notification>

                                </NotificationGroup>
                            )
                            }
                        </div>
                    </form>

                )}
                {recoveryPasswordPage && (
                    <RecoveryPasswordPage />
                )}
                {loginForm && (
                    <LoginPage />
                )}


                {/* {recoveryForm && (
                    <RecoveryPasswordPage
                    //onSubmit={handleSubmitRecovery}

                    />
                )} */}
                {notificationSucess && (
                    <NotificationGroup
                        style={
                            position.bottomRight
                        }>
                        <Notification
                            key="success"
                            type={{
                                style: "success",
                                //margin: "center"
                            }}

                            closable={true}
                        //onClose={() => viewNotification(false)}
                        >
                            {content}
                        </Notification>

                    </NotificationGroup>
                )
                }

            </div>
        </div>
    )
}
