import { useDispatch, useSelector } from "react-redux";
import { residentApi } from "api";
import {onLoadMultitowerAccessData, onErrorMultitowerAccessData, onLoadMultitowerVisitData, onErrorMultitowerVisitData,
    onLoadPedestrianData, onErrorPedestrianData, clearPedestrianErrorMessage,
    onLoadVisitData, onErrorVisitData,
    onLoadVisitPhotosData, onErrorVisitPhotosData, onLoadVehiclePulsesData,
    onLoadVisitNotificationsData, onErrorVisitNotificationsData, onErrorPulsesData,
    clearVisitErrorMessage, onLoadVehicleData, onErrorVehicleData, clearVehicleErrorMessage,
} from 'store/safeentry'

export const useVisitStore = () => {

    const dispatch = useDispatch();

    const { statusMultitowerVisitLoad, apiMultitowerAccessData,
    errorMultitowerAccessMessage, apiMultitowerVisitData,
        errorMultitowerVisitMessage,
        statusPedestrianLoad, apiPedestrianData, errorPedestrianMessage,
        statusVisitLoad,
        apiVisitData, errorVisitMessage,
        apiVisitPhotos, errorVisitPhotosMessage,
        apiVisitNotification, errorVisitNotificationsMessage, statusVehicleLoad,
        apiVehicleData,
        apiVehiclePulses,
        errorVehicleMessage,
        errorVehiclePulsesMessage
    } = useSelector(state => state.visit);

    const loadPedestrianData = async ({ enterprise_uuid, company_uuid, start_date, end_date, search_enterprise, search_visitant }) => {
        try {
            let bear_token = localStorage.getItem('token')
            const { data } = await residentApi.post('visit/binnacle/pedestrian', { enterprise_uuid, company_uuid, start_date, end_date, search_enterprise, search_visitant }, {
                headers: {
                    'Security-Token': bear_token
                }
            });
            dispatch(onLoadPedestrianData({ data: data.data }));
            //console.log(data)
        } catch (error) {
            // error.response.data?.details_error || error.response.data?.message || 
            dispatch(onErrorPedestrianData(error.response.data?.details_error || error.response.data?.message || 'Lo sentimos. Algunos de nuestros procesos no funcionó.'));
            setTimeout(() => {
                dispatch(clearPedestrianErrorMessage());
            }, 10);
        }
    }

    //Multitower Visit Services
    const loadVisitMultitowerData = async ({ enterprise_uuid, company_uuid, access_tower, start_date, end_date, search_plate, search_visitant }) => {
        try {
            let bear_token = localStorage.getItem('token')
            const { data } = await residentApi.post('visit/multitower/binnacle', { enterprise_uuid, company_uuid, access_tower, start_date, end_date, search_plate, search_visitant }, {
                headers: {
                    'Security-Token': bear_token
                }
            });
            dispatch(onLoadMultitowerVisitData({ data: data.data }));
            // console.log(data)
        } catch (error) {
            // error.response.data?.details_error || error.response.data?.message || 
            dispatch(onErrorMultitowerVisitData(error.response.data?.details_error || error.response.data?.message || 'Lo sentimos. Algunos de nuestros procesos no funcionó.'));
            setTimeout(() => {
                dispatch(clearVisitErrorMessage());
            }, 10);
        }
    }

    const loadAccessMultitower = async ({ enterprise_uuid, company_uuid }) => {
        try {
            let bear_token = localStorage.getItem('token')
            const { data } = await residentApi.post('tower/access/services', { enterprise_uuid, company_uuid }, {
                headers: {
                    'Security-Token': bear_token
                }
            });
            dispatch(onLoadMultitowerAccessData({ data: data.data }));
            // console.log(data)
        } catch (error) {
            // error.response.data?.details_error || error.response.data?.message || 
            dispatch(onErrorMultitowerAccessData(error.response.data?.details_error || error.response.data?.message || 'Lo sentimos. Algunos de nuestros procesos no funcionó.'));
            setTimeout(() => {
                dispatch(clearVisitErrorMessage());
            }, 10);
        }
    }



    const loadVisitData = async ({ enterprise_uuid, company_uuid, start_date, end_date, search_plate, search_visitant }) => {
        try {
            let bear_token = localStorage.getItem('token')
            const { data } = await residentApi.post('visit/binnacle', { enterprise_uuid, company_uuid, start_date, end_date, search_plate, search_visitant }, {
                headers: {
                    'Security-Token': bear_token
                }
            });
            dispatch(onLoadVisitData({ data: data.data }));
            // console.log(data)
        } catch (error) {
            // error.response.data?.details_error || error.response.data?.message || 
            dispatch(onErrorVisitData(error.response.data?.details_error || error.response.data?.message || 'Lo sentimos. Algunos de nuestros procesos no funcionó.'));
            setTimeout(() => {
                dispatch(clearVisitErrorMessage());
            }, 10);
        }
    }

    const loadVisitPhotosData = async (binnacle_uuid) => {
        try {
            let bear_token = localStorage.getItem('token')
            const { data } = await residentApi.get(`binnacle/photos/${binnacle_uuid}`, {
                headers: {
                    'Security-Token': bear_token
                }
            });
            dispatch(onLoadVisitPhotosData({ data: data.data }));
        } catch (error) {
            // error.response.data?.details_error || error.response.data?.message || 
            dispatch(onErrorVisitPhotosData(error.response.data?.details_error || error.response.data?.message || 'Lo sentimos. Algunos de nuestros procesos no funcionó.'));
            setTimeout(() => {
                dispatch(clearVisitErrorMessage());
            }, 10);
        }
    }

    const loadVisitNotificationsData = async (visit_uuid) => {
        try {
            let bear_token = localStorage.getItem('token')
            const { data } = await residentApi.get(`visit/notifications/${visit_uuid}`, {
                headers: {
                    'Security-Token': bear_token
                }
            });
            dispatch(onLoadVisitNotificationsData({ data: data.data }));
        } catch (error) {
            // error.response.data?.details_error || error.response.data?.message || 
            dispatch(onErrorVisitNotificationsData(error.response.data?.details_error || error.response.data?.message || 'Lo sentimos. Algunos de nuestros procesos no funcionó.'));
            setTimeout(() => {
                dispatch(clearVisitErrorMessage());
            }, 10);
        }
    }

    const loadVehicleData = async ({ enterprise_uuid, company_uuid, start_date, end_date, search_plate, search_resident }) => {
        try {
            let bear_token = localStorage.getItem('token')
            const { data } = await residentApi.post('binnacle/tag', { enterprise_uuid, company_uuid, start_date, end_date, search_plate, search_resident }, {
                headers: {
                    'Security-Token': bear_token
                }
            });
            dispatch(onLoadVehicleData({ data: data.data }));
        } catch (error) {
            // error.response.data?.details_error || error.response.data?.message || 
            dispatch(onErrorVehicleData(error.response.data?.details_error || error.response.data?.message || 'Lo sentimos. Algunos de nuestros procesos no funcionó.'));
            setTimeout(() => {
                dispatch(clearVehicleErrorMessage());
            }, 10);
        }
    }

    const loadVehiclePulsesData = async (visit_uuid) => {
        try {
            let bear_token = localStorage.getItem('token')
            const { data } = await residentApi.get(`register/pulses/${visit_uuid}`, {
                headers: {
                    'Security-Token': bear_token
                }
            });
            dispatch(onLoadVehiclePulsesData({ data: data.data }));
        } catch (error) {
            // error.response.data?.details_error || error.response.data?.message || 
            dispatch(onErrorPulsesData(error.response.data?.details_error || error.response.data?.message || 'Lo sentimos. Algunos de nuestros procesos no funcionó.'));
            setTimeout(() => {
                dispatch(clearVehicleErrorMessage());
            }, 10);
        }
    }




    return {
        // * Properties
        //VisitMultitower
        statusMultitowerVisitLoad, 
        apiMultitowerVisitData,
        errorMultitowerVisitMessage,
        apiMultitowerAccessData,
        errorMultitowerAccessMessage,

        //Visit
        statusVisitLoad,
        apiVisitData,
        errorVisitMessage,
        apiVisitPhotos,
        errorVisitPhotosMessage,
        apiVisitNotification,
        errorVisitNotificationsMessage,

        //Vehicle
        statusVehicleLoad,
        apiVehicleData,
        apiVehiclePulses,
        errorVehicleMessage,
        errorVehiclePulsesMessage,

        //Pedestrian
        statusPedestrianLoad,
        apiPedestrianData,
        errorPedestrianMessage,

        // * Methods

        //Visit Multitower
        loadAccessMultitower,
        loadVisitMultitowerData,

        //Visit
        loadVisitData,
        loadVisitPhotosData,
        loadVisitNotificationsData,
        loadVehicleData,
        loadVehiclePulsesData,

        // Pedestrian
        loadPedestrianData,
    }
}
