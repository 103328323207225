import * as React from "react";
import { Dialog } from "@progress/kendo-react-dialogs";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import {
  FormInput,
  FormMaskedTextBox,
} from "../../shared/form-components";
import {
  descriptionContactValidator,
  contactPhoneValidator
} from "../../shared/form-validators";

import { Icon } from "@mui/material";

export const EditFormContactPhone = (props) => {
  return (
    <Dialog title={`Editar Teléfono de Contacto`} onClose={props.cancelEdit}>
      <Form
        onSubmit={props.onSubmit}
        initialValues={props.item}
        render={(formRenderProps) => (
          <FormElement
            style={{
              maxWidth: 500,
            }}
          >
            <fieldset className={"k-form-fieldset"}>
              <Field
                id={"cph_description"}
                name={"cph_description"}
                label={"NOMBRES Y APELLIDOS *"}
                component={FormInput}
                validator={descriptionContactValidator}
                maxLength={100}
              />
              <Field
                id={"cph_number"}
                name={"cph_number"}
                label={"TELÉFONO"}
                hint={"Sugerencia: Número de teléfono activo (0987654321 / 042123456 / 2123456)."}
                component={FormMaskedTextBox}
                maxLength={10}
                validator={contactPhoneValidator}
                mask={"0000000000" || "000000000" || "0000000"}
              />
            </fieldset>
            <div className="k-form-buttons" style={ {marginLeft: "76%" }}>
              <button
                type={"submit"}
                className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                disabled={!formRenderProps.allowSubmit}
              >
                <Icon fontSize="small" sx={{ color: "white" }}>
                    save
                </Icon>
              </button>
              <button
                type={"submit"}
                className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                onClick={props.cancelEdit}
              >
                <Icon fontSize="small" sx={{ color: "white" }}>
                    cancel
                </Icon>
              </button>
            </div>
          </FormElement>
        )}
      />
    </Dialog>
  )
}
