import * as React from "react";
import moment from "moment";

import {
  Grid as GridTelerik,
  GridColumn as Column,
} from "@progress/kendo-react-grid";
import {
  Card as CardTelerik,
  CardTitle,
  CardBody,
  CardImage,
} from "@progress/kendo-react-layout";

import columns from '../shared/columns';
import { CustomColumnMenu } from '../shared/customColumnMenu';

import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout"

// Material Dashboard 2 PRO React examples
import MDBox from "components/MDBox";

// @mui material components
import { Card, Grid, Icon } from "@mui/material";

import { ColorTranslator } from "../shared/color-translator";

import { DefaultPhoto } from "config/config";

import {
  PlateImgCell,
  DriverNameCell,
  DriverLastNameCell
} from "../shared/custom-cells";

const VOLUME_INITIAL = 6;
const UPDATES_TIME = 1000;

export const RegisterPlateData = (props) => {
  const volumeRef = React.useRef(VOLUME_INITIAL);
  React.useEffect(() => {
    props.onDataReset(volumeRef.current);
    props.onStartLiveUpdate(UPDATES_TIME);
  }, []);

  const ColorTranslate = (data) => {
    const translatedColor = new ColorTranslator();
    return translatedColor.translateColor(data);
  };

  const Driver = (data) => {
    return data === undefined
      ? "N/D"
      : data.length === 0
        ? "N/D"
        : data[0].last_name + " " + data[0].name;
  };

  const Access = (data) => {
    switch (data) {
      case "Autorizado":
        return (
          "rgb(0, 115, 0, 0.9)"
        );
      case "No autorizado":
        return (
          "rgb(255, 0, 0, 0.9)"
        );
      case "No identificado":
        return (
          "rgb(255, 130, 0, 0.9)"
        );
      default:
        return (
          "rgb(255, 255, 255)"
        );
    }
  };

  const photoBase64 = DefaultPhoto;

  const [stateColumns, setStateColumns] = React.useState(columns);

  const onColumnsSubmit = (columnsState) => {
    setStateColumns(columnsState);
  };

  const [imageError, setImageError] = React.useState(false);

  const handleImageError = () => {
    setImageError(true);
  };

  return (
    <>
      <MDBox>
        <Card>
          <Grid container spacing={3} p={2}>
            <Grid item xs={12} md={6} lg={6}>
              <MDBox>
                <CardTelerik
                  style={{
                    width: "auto",
                  }}
                >
                  <CardBody>
                    <CardTitle>ÚLTIMO EVENTO  - {(props.data[0]?.plate)} </CardTitle>
                    <GridLayout
                      rows={[
                        {
                          height: "18vh",
                        }
                      ]}
                    >
                      <GridLayoutItem className="box" col={1} row={1}>
                        <div>
                          <b> <Icon fontSize="small">date_range</Icon> FECHA:</b> {props.data[0]?.ts !== undefined ? (moment.unix(props.data[0]?.ts).format('DD/MM/YYYY HH:mm:ss')) : ''}
                        </div>
                        <div>
                          <b> <Icon fontSize="small">videocam</Icon> CÁMARA:</b> {props.data[0]?.camera !== undefined ? (props.data[0]?.camera).toUpperCase() : ''}
                        </div>
                        <div>
                          <b> <Icon fontSize="small">money</Icon> PLACA:</b> {(props.data[0]?.plate)}
                        </div>
                        <div>
                          <b> <Icon fontSize="small">color_lens</Icon> COLOR:</b> {ColorTranslate(props.data[0]?.color)}
                        </div>
                        <div>
                          <b> <Icon fontSize="small">person</Icon> PROPIETARIO:</b>
                        </div>
                        <div>
                          {Driver(props.data[0]?.drivers).toUpperCase()}
                        </div>
                      </GridLayoutItem>
                      <GridLayoutItem className="box" col={2} row={1}>
                        <div>
                          <b> <Icon fontSize="small">security</Icon> ACCESO:</b> {props.data[0]?.permission !== undefined ? (props.data[0]?.permission).toUpperCase() : ''}
                        </div>
                        <div
                          style={{
                            width: "21vh",
                            height: "14vh",
                            backgroundColor: Access(props.data[0]?.permission),
                            borderRadius: "10px",
                            borderWidth: "1px",
                            borderStyle: "solid",
                            borderColor: "black",
                          }}
                        ></div>
                      </GridLayoutItem>
                    </GridLayout>
                  </CardBody>
                </CardTelerik>
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <MDBox>
                <CardTelerik
                  style={{
                    width: "auto",
                    display: "grid",
                    placeItems: "center",
                  }}
                >
                  <CardBody>
                    <img
                      src={imageError ? photoBase64 : props.data[0]?.stage}
                      onError={handleImageError}
                      alt="Placa"
                      style={{
                        width: '50vh',
                        height: '21vh',
                        borderRadius: '8px',
                      }}
                    />
                    {/* <CardImage
                      src={props.data[0]?.stage !== undefined ? props.data[0]?.stage : photoBase64}
                      style={{
                        width: "50vh",
                        height: "21vh",
                        borderRadius: "8px"
                      }}
                    /> */}
                  </CardBody>
                </CardTelerik>
              </MDBox>
            </Grid>
          </Grid>
        </Card>
      </MDBox>
      <br />
      <GridTelerik
        style={{
          height: "72vh",
        }}
        data={props.data}
        resizable={false}
      >
        {stateColumns.map(
          (column, idx) =>
            column.show && (
              <Column
                key={idx}
                field={column.field}
                title={column.title}
                cell={column.cell}
                columnMenu={(props) => (
                  <CustomColumnMenu
                    {...props}
                    columns={stateColumns}
                    onColumnsSubmit={onColumnsSubmit}
                  />
                )}
              />
            )
        )}
        <Column field={"drivers"} title="NOMBRE PROPIETARIO" cell={DriverNameCell} />
        <Column field={"drivers"} title="APELLIDO PROPIETARIO" cell={DriverLastNameCell} />
        <Column field={"crop"} title="IMAGEN PLACA" cell={PlateImgCell} />
      </GridTelerik>
    </>
  );
};
