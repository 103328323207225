// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";
// import Card from "@mui/material/Card";
// import Box from '@mui/material/Box'
// import Avatar from '@mui/material/Avatar'

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
// import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import PageLayout from "./PageLayout";
import Footer from "./FooterLogin";
//import Footer from "examples/Footer";

// Material Dashboard 2 PRO React context
import { useMaterialUIController } from "context";
// import bgImage from "assets/images/safe-entry/logo-safeentry.png";

function IllustrationLayoutRecoveryPassword({
  header,
  title,
  description,
  illustration,
  children,
}) {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  return (
    <PageLayout background="white">
      <Grid
        container
        sx={{
          backgroundColor: ({ palette: { background, white } }) =>
            darkMode ? background.default : white.main,
        }}
      >
        <Grid item xs={12} lg={8.5}>
          <MDBox
            position="absolute"
            width="100%"
            minHeight="100vh"
            sx={{
              backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
                illustration &&
                `${linearGradient(
                  rgba(gradients.dark.main, 0),
                  rgba(gradients.dark.state, 0)
                )}, url(${illustration})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
            }}

          >
          </MDBox>
        </Grid>
        <Grid item xs={11} sm={8} md={6} lg={4} xl={3} sx={{ mx: "auto" }}>
          <MDBox display="flex" flexDirection="column" justifyContent="center" height="100vh" >
            {/* <MDBox
                variant="gradient"
                bgColor="dark"
                borderRadius="lg"
                coloredShadow="info"
                mx={2}
                mt={-3}
                p={2}
                mb={1}
                textAlign="center"
                src="assets/images/safe-entry/logo-safeentry.png"
              >
                <MDTypography
                  variant="h4"
                  fontWeight="medium"
                  color="white"
                  mt={1}
                >
                  Acceder
                </MDTypography> 
              </MDBox> */}
            <MDBox p={3}>{children}</MDBox>

          </MDBox>
        </Grid>

        
      </Grid>
      <Grid>
      </Grid>
    </PageLayout>
  );
}

// Setting default values for the props of IllustrationLayout
IllustrationLayoutRecoveryPassword.defaultProps = {
  header: "",
  title: "",
  description: "",
  illustration: "",
};

// Typechecking props for the IllustrationLayout
IllustrationLayoutRecoveryPassword.propTypes = {
  header: PropTypes.node,
  title: PropTypes.string,
  description: PropTypes.string,
  children: PropTypes.node.isRequired,
  illustration: PropTypes.string,
};

export default IllustrationLayoutRecoveryPassword;
