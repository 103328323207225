/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";

import { Buffer } from "buffer";

// Material Dashboard 2 PRO React examples
import MDBox from "components/MDBox";

// @mui material components
import { Card, Grid, Icon } from "@mui/material";

// Telerik
import { filterBy } from "@progress/kendo-data-query";
import { ComboBox } from "@progress/kendo-react-dropdowns";
import { Button } from "@progress/kendo-react-buttons";
import { Dialog } from "@progress/kendo-react-dialogs";
import { Loader } from "@progress/kendo-react-indicators";
import { DateTimePicker } from "@progress/kendo-react-dateinputs";
import {
  Notification,
  NotificationGroup,
} from "@progress/kendo-react-notification";

import { RecordingList } from './RecordingList'

import { ApiIA } from '../shared/ApiIA';

export const RecordingData = () => {

  const api = new ApiIA();

  const darkModeStyle = {
    color: "rgb(0, 0, 0)",
  };

  const userData = localStorage.getItem("user");

  const today = new Date();
  const todayStart = new Date();
  todayStart.setHours(todayStart.getHours() - 1)
  const [formState, setFormState] = React.useState({
    startDate: todayStart,
    endDate: today
  });

  // TODO: Datos de las empresas segun el usuario
  const base64dataEnterprises = localStorage.getItem("key1");
  const enterprises = Buffer.from(base64dataEnterprises, "base64").toString(
    "ascii"
  );

  const jsonEntData = JSON.parse(enterprises);

  const [dataEnterprises, setDataEnterprises] = React.useState(jsonEntData.slice());
  const [enterprise, setEnterprise] = React.useState();

  const filterEntData = (filter) => {
    const data = jsonEntData.slice();
    return filterBy(data, filter);
  };
  const filterEntChange = (event) => {
    const newData =
      event.filter.value.length > 2
        ? filterEntData(event.filter)
        : jsonEntData.slice();
    setDataEnterprises(newData);
  };

  const handleEntChange = React.useCallback(async (event) => {
    setListRecordings([]);
    resetComLocCam();
    const value = event.target.value;
    setEnterprise(value);
    if (value !== null) {
      const request_companies = {
        user_uuid: userData,
        enterprise_uuid: value.enterprise_uuid,
      };
      const response = await api.getListCompanies(request_companies);
      if (response !== undefined) {
        setDataCompanies(response);
        setDataCompaniesFilter(response.slice());
      } else {
        // Notificación cuando no se envia las compañias
        setContent('No hay datos disponibles.');
        viewNotification(true);
        setTimeout(() => {
          viewNotification(false);
        }, 4000);
      }
    }
  }, []);

  const resetComLocCam = () => {
    resetCom();
    resetLoc();
    resetCam();
  }

  const resetCom = () => {
    setDataCompanies([]);
    setDataCompaniesFilter([]);
    setCompany(null);
  }

  const resetLoc = () => {
    setLocations([]);
    setValueLocate(null);
  }

  const resetCam = () => {
    setCameras([]);
    setValueCamera(null);
  }

  // TODO: Datos de las compañias segun el usuario

  const [dataCompaniesFilter, setDataCompaniesFilter] = React.useState();
  const [dataCompanies, setDataCompanies] = React.useState();
  const [company, setCompany] = React.useState();

  const filterConData = (filter) => {
    const data = dataCompaniesFilter.slice();
    return filterBy(data, filter);
  };
  const filterConChange = (event) => {
    const newData =
      event.filter.value.length > 2
        ? filterConData(event.filter)
        : dataCompaniesFilter.slice();
    setDataCompanies(newData);
  };

  const handleConChange = React.useCallback((event) => {
    setListRecordings([]);
    resetLoc();
    resetCam();
    const value = event.target.value;
    setCompany(value);
    if (value !== null) {
      getCamerasLocate(value);
    }
  }, []);

  // TODO: Datos de las localidades y las camaras

  const [locations, setLocations] = React.useState([]);
  const [valueLocate, setValueLocate] = React.useState();
  const [cameras, setCameras] = React.useState([]);
  const [valueCamera, setValueCamera] = React.useState();

  const handleChangeLocate = (event) => {
    setListRecordings([]);
    resetCam();
    const value = event.value;
    setValueLocate(value);
    if( value !== null){
      // Obtener las camaras de acuerdo a locate
      getCamerasByLocate(value);
    }
  };

  const getCamerasLocate = async (valueCompany) => {
    const request = {
      apiName: valueCompany.company_name.toLowerCase(),
    }
    const response = await api.getListCameras(request);
    if (response !== undefined && response !== null && response !== 0) {
      getUniqueLocations(response);
      viewNotification(false);
    } else {
      setLocations([]);
      setValueLocate(null);
      setContent('No hay datos disponibles.');
      viewNotification(true);
      setTimeout(() => {
        viewNotification(false);
      }, 5000);
    }
    setTimeout(() => {
      setLoading(false);
    }, 100);
  };

  // Listado de las localidades
  const getUniqueLocations = (dataResponse) => {
    setLocations([]);
    setValueLocate(null);
    const locationList = [];
    dataResponse.results.forEach(item => {
      if (!locationList.includes(item.locate)) {
        locationList.push(item.locate);
      }
    });
    setLocations(locationList);
  };

  const getCamerasByLocate = async (locate) => {
    const request = {
      apiName: company.company_name.toLowerCase(),
      locate: locate
    }
    const response = await api.getListCamerasByLocate(request);
    if (response !== undefined && response !== null && response !== 0) {
      viewNotification(false);
      setCameras(response.results);
    } else {
      setContent('No hay datos disponibles.');
      setCameras([]);
      viewNotification(true);
      setTimeout(() => {
        viewNotification(false);
      }, 5000);
    }
  };

  const handleChangeCamera = (event) => {
    const value = event.value;
    setValueCamera(value);
  };

  const handleChange = (event) => {
    setFormState({ ...formState, [event.target.name]: event.target.value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    let diff = formState.endDate - formState.startDate;
    let days = diff / (1000 * 60 * 60 * 24);
    if (parseInt(days) <= 1) {
      // Listar las grabaciones 
      getListRecordings(company.company_name.toLowerCase(), valueLocate, valueCamera.name);
    } else {
      setContent('Consultar un día como máximo');
      viewNotification(true);
      setTimeout(() => {
        viewNotification(false);
      }, 6000);
    }
  };

  const getListRecordings = async (company, locate, camera) => {
    setLoading(true);
    const startDateRequest = new Date(formState.startDate);
    startDateRequest.setHours(startDateRequest.getHours() - 5)
    const endDateRequest = new Date(formState.endDate);
    endDateRequest.setHours(endDateRequest.getHours() - 5)
    const request = {
      apiName: company,
      cameraLocate: locate,
      cameraName: camera,
      startDate: startDateRequest,
      endDate: endDateRequest
    }
    const response = await api.getListRecordings(request);
    if (response !== undefined && response !== null && response !== 0) {
      convertJsonToData(response);
      viewNotification(false);
    } else {
      setContent('No hay datos disponibles.');
      setListRecordings([]);
      viewNotification(true);
      setLoading(false);
      setTimeout(() => {
        viewNotification(false);
      }, 5000);
    }
  };

  // Listado de las grabaciones de acurdo al formta de IU
  const convertJsonToData = (json) => {
    const result = [];
    for (const cameraId in json) {
      const camera = json[cameraId];
      for (const date in camera.days) {
        const dateItem = {
          text: `${camera.name} - ${cameraId} - ${date}`,
          expanded: true,
          items: camera.days[date].map(record => ({
            text: record.route,
          })),
        };
        result.push(dateItem);
      }
    }
    setListRecordings(result);
    setLoading(false);
    setShowListComponent(true);
    setShowDivComponent(false);
  };

  // UI
  const [listRecordings, setListRecordings] = React.useState([]);
  const [showListComponent, setShowListComponent] = React.useState(false);
  const [showDivComponent, setShowDivComponent] = React.useState(true);
  const [loading, setLoading] = React.useState(false);

  //Notificacion
  const [notification, setNotification] = React.useState(false);
  const viewNotification = (state) => {
    setNotification(state);
  };

  const position = {
    bottomRight: {
      bottom: 5,
      right: 23,
      alignItems: "flex-end",
    },
  };

  const [content, setContent] = React.useState("<span></span>");

  return (
    <>

      <form className="k-form" onSubmit={handleSubmit}>
        <MDBox py={2}>
          <Card>
            <MDBox p={2}>
              <Grid container spacing={1}>
                <Grid item xs={12} md={6} lg={3}>
                  <MDBox>
                    <h6 style={darkModeStyle}>
                      <b> EMPRESA </b>
                    </h6>
                    <ComboBox
                      data={dataEnterprises}
                      textField="enterprise_name"
                      filterable={true}
                      onFilterChange={filterEntChange}
                      onChange={handleEntChange}
                      placeholder="Seleccione Empresa"
                      style={darkModeStyle}
                      value={enterprise}
                      fillMode="outline"
                      required
                      validationMessage="Obligatorio"
                    />
                  </MDBox>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <MDBox>
                    <h6 style={darkModeStyle}>
                      <b> COMPAÑÍA </b>
                    </h6>
                    <ComboBox
                      data={dataCompanies}
                      textField="company_name"
                      filterable={true}
                      onFilterChange={filterConChange}
                      onChange={handleConChange}
                      placeholder="Seleccione Compañía"
                      style={darkModeStyle}
                      value={company}
                      fillMode="outline"
                      required
                      validationMessage="Obligatorio"
                    />
                  </MDBox>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <MDBox>
                    <h6 style={darkModeStyle}>
                      <b> LOCALIDAD </b>
                    </h6>
                    <ComboBox
                      data={locations}
                      value={valueLocate}
                      // filterable={true}
                      // onFilterChange={filterConChange}
                      onChange={handleChangeLocate}
                      placeholder="Seleccione Localidad"
                      style={darkModeStyle}
                      fillMode="outline"
                      required
                      validationMessage="Obligatorio"
                    />
                  </MDBox>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <MDBox>
                    <h6 style={darkModeStyle}>
                      <b> CÁMARA </b>
                    </h6>
                    <ComboBox
                      data={cameras}
                      value={valueCamera}
                      textField="name"
                      onChange={handleChangeCamera}
                      placeholder="Seleccione Cámara"
                      style={darkModeStyle}
                      fillMode="outline"
                      required
                      validationMessage="Obligatorio"
                    />
                  </MDBox>
                </Grid>
              </Grid>
            </MDBox>
          </Card>
        </MDBox>
        <MDBox py={1}>
          <Card>
            <MDBox p={2}>
              <Grid container spacing={1}>
                <Grid item xs={5.5} md={5.5} lg={5.5}>
                  <MDBox>
                    <h6 style={darkModeStyle}>
                      <b> DESDE </b>
                    </h6>
                    <DateTimePicker
                      width="90%"
                      name="startDate"
                      required
                      format={"dd-MM-yyyy HH:mm:ss"}
                      value={formState.startDate}
                      onChange={handleChange}
                      fillMode="flat"
                      validationMessage={
                        formState.startDate === null
                          ? "OBLIGATORIO"
                          : "FECHA INVÁLIDA"
                      }
                    />
                  </MDBox>
                </Grid>
                <Grid item xs={5.5} md={5.5} lg={5.5}>
                  <MDBox>
                    <h6 style={darkModeStyle}>
                      <b> HASTA </b>
                    </h6>
                    <DateTimePicker
                      width="90%"
                      name="endDate"
                      required
                      format={"dd-MM-yyyy HH:mm:ss"}
                      value={formState.endDate}
                      onChange={handleChange}
                      fillMode="flat"
                      validationMessage={
                        formState.endDate === null
                          ? "OBLIGATORIO"
                          : "FECHA INVÁLIDA"
                      }
                      valid={
                        formState.startDate !== null &&
                        formState.endDate !== null &&
                        formState.startDate.getTime() <=
                        formState.endDate.getTime()
                      }
                    />
                  </MDBox>
                </Grid>
                <Grid item xs={1} md={1} lg={1}>
                  <MDBox>
                    <h6 style={darkModeStyle}>
                      <b> BUSCAR </b>
                    </h6>
                    <Button
                      type="submit"
                      title="Buscar"
                      fillMode="flat"
                      id="search_button"
                    >
                      <Icon
                        fontSize="small"
                        sx={{ color: "rgb(26, 115, 232, 0.8)" }}
                      >
                        search
                      </Icon>
                    </Button>
                  </MDBox>
                </Grid>
              </Grid>
            </MDBox>
          </Card>
        </MDBox>
      </form>

      {showDivComponent && <MDBox pt={1} px={0}> <Card style={{ height: "53vh" }}></Card> </MDBox>}
      {showListComponent && <RecordingList company={company} listRecordings={listRecordings} />}
      {loading && (
        <Dialog>
          <Loader
            size="large"
            type={"converging-spinner"}
          />
        </Dialog>
      )}
      {notification && (
        <NotificationGroup style={position.bottomRight}>
          <Notification
            key="warning"
            type={{
              style: "warning",
              icon: true,
            }}
            closable={true}
            onClose={() => viewNotification(false)}
          >
            {content}
          </Notification>
        </NotificationGroup>
      )}
    </>
  )
}
