/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";

import { Buffer } from "buffer";

// Material Dashboard 2 PRO React examples
import MDBox from "components/MDBox";

// @mui material components
import { Card, Grid, Icon } from "@mui/material";

// Telerik
import { filterBy } from "@progress/kendo-data-query";
import { ComboBox } from "@progress/kendo-react-dropdowns";
import { Dialog } from "@progress/kendo-react-dialogs";
import { Loader } from "@progress/kendo-react-indicators";
import {
  Notification,
  NotificationGroup,
} from "@progress/kendo-react-notification";

import {
  Toolbar,
  ToolbarItem,
  ToolbarSeparator,
  ToolbarSpacer,
  Button
} from "@progress/kendo-react-buttons";

import { ApiIA } from '../shared/ApiIA';

import { VideoPlateGridLayout } from './VideoPlateGridLayout';



export const VideoPlateData = () => {

  const api = new ApiIA();

  const darkModeStyle = {
    color: "rgb(0, 0, 0)",
  };

  const userData = localStorage.getItem("user");

  // TODO: Datos de las empresas segun el usuario
  const base64dataEnterprises = localStorage.getItem("key1");
  const enterprises = Buffer.from(base64dataEnterprises, "base64").toString(
    "ascii"
  );

  const jsonEntData = JSON.parse(enterprises);

  const [dataEnterprises, setDataEnterprises] = React.useState(jsonEntData.slice());
  const [enterprise, setEnterprise] = React.useState();

  const filterEntData = (filter) => {
    const data = jsonEntData.slice();
    return filterBy(data, filter);
  };
  const filterEntChange = (event) => {
    const newData =
      event.filter.value.length > 2
        ? filterEntData(event.filter)
        : jsonEntData.slice();
    setDataEnterprises(newData);
  };

  const handleEntChange = React.useCallback(async (event) => {
    setShowVideoComponent(false);
    setShowDivComponent(true);
    const value = event.target.value;
    if (value === null) {
      setDataCompanies([]);
      setDataCompaniesFilter([]);
      setCompany(null);
      setLocations([]);
      setLocate(null);
    } else {
      const request_companies = {
        user_uuid: userData,
        enterprise_uuid: value.enterprise_uuid,
      };
      const response = await api.getListCompanies(request_companies);
      if (response !== undefined) {
        setDataCompanies(response);
        setDataCompaniesFilter(response.slice());
      } else {
        // Notificación cuando no se envia las compañias
        setContent('No hay datos disponibles.');
        viewNotification(true);
        setTimeout(() => {
          viewNotification(false);
        }, 4000);
      }
    }
    setEnterprise(value);
  }, []);

  // TODO: Datos de las compañias segun el usuario

  const [dataCompaniesFilter, setDataCompaniesFilter] = React.useState();
  const [dataCompanies, setDataCompanies] = React.useState();
  const [company, setCompany] = React.useState();

  const filterConData = (filter) => {
    const data = dataCompaniesFilter.slice();
    return filterBy(data, filter);
  };
  const filterConChange = (event) => {
    const newData =
      event.filter.value.length > 2
        ? filterConData(event.filter)
        : dataCompaniesFilter.slice();
    setDataCompanies(newData);
  };

  const handleConChange = React.useCallback((event) => {
    setShowVideoComponent(false);
    setShowDivComponent(true);
    const value = event.target.value;
    setCompany(value);
    if (value !== null) {
      getCamerasLocate(value);
    } else {
      setLocations([]);
      setLocate(null);
    }
  }, []);

  // TODO: Datos de las localidades y las camaras

  const [locations, setLocations] = React.useState([]);
  const [locate, setLocate] = React.useState();

  const [listCameras, setListCameras] = React.useState({
    data: [],
    total: 0
  });

  const handleLocateChange = (event) => {
    setShowVideoComponent(false);
    setShowDivComponent(true);
    const value = event.value;
    setLocate(value);
    if (value !== null) {
      // Obtener las camaras de acuerdo a locate
      getCamerasByLocate(value);
    }
  };

  const getCamerasLocate = async (valueCompany) => {
    const request = {
      apiName: valueCompany.company_name.toLowerCase(),
    }
    const response = await api.getListCameras(request);
    if (response !== undefined && response !== null && response !== 0) {
      getUniqueLocations(response);
      viewNotification(false);
    } else {
      setLocations([]);
      setLocate(null);
      setContent('No hay datos disponibles.');
      viewNotification(true);
      setTimeout(() => {
        viewNotification(false);
      }, 5000);
    }
    setTimeout(() => {
      setLoading(false);
    }, 100);
  };

  // Listado de las localidades
  const getUniqueLocations = (dataResponse) => {
    setLocations([]);
    setLocate(null);
    const locationList = [];
    dataResponse.results.forEach(item => {
      if (!locationList.includes(item.locate)) {
        locationList.push(item.locate);
      }
    });
    setLocations(locationList);
  };

  const getCamerasByLocate = async (locate) => {
    const request = {
      apiName: company.company_name.toLowerCase(),
      locate: locate
    }
    const response = await api.getListCamerasByLocate(request);
    if (response !== undefined && response !== null && response !== 0) {
      viewNotification(false);
      setListCameras({
        data: response.results,
        total: response.count
      });
      setShowDivComponent(false);
      setShowVideoComponent(true);
    } else {
      setContent('No hay datos disponibles.');
      setListCameras({
        data: [],
        total: 0
      });
      setShowDivComponent(true);
      setShowVideoComponent(false);
      viewNotification(true);
      setTimeout(() => {
        viewNotification(false);
      }, 5000);
    }
  };

  const [loading, setLoading] = React.useState(false);

  const [showDivComponent, setShowDivComponent] = React.useState(true);
  const [showVideoComponent, setShowVideoComponent] = React.useState(false);

  //Notificacion
  const [notification, setNotification] = React.useState(false);
  const viewNotification = (state) => {
    setNotification(state);
  };

  const position = {
    bottomRight: {
      bottom: 5,
      right: 23,
      alignItems: "flex-end",
    },
  };

  const [content, setContent] = React.useState("<span></span>");

  const refreshVideo = () => {
    setShowDivComponent(true);
    setShowVideoComponent(false);
    setTimeout(() => {
      setShowDivComponent(false);
      setShowVideoComponent(true);
    }, 1000);
  }

  // React.useEffect(() => {
  //   if (statusUserLoad === "1") {
  //     setDataCompanies(apiCompanyData?.data);
  //     setCompany(apiCompanyData?.data[0]);
  //     setDataCompaniesFilter(apiCompanyData?.data.slice());
  //   } else {
  //     const request_companies = {
  //       user_uuid: userData,
  //       enterprise_uuid: enterprise.enterprise_uuid,
  //     };
  //     loadCompanyData(request_companies);
  //   }
  // }, [apiCompanyData, statusUserLoad]);

  return (
    <>
      <form className="k-form">
        <MDBox py={2}>
          <Card>
            <MDBox p={2}>
              <Grid container spacing={1}>
                <Grid item xs={12} md={4} lg={4}>
                  <MDBox>
                    <h6 style={darkModeStyle}>
                      <b> EMPRESA </b>
                    </h6>
                    <ComboBox
                      data={dataEnterprises}
                      textField="enterprise_name"
                      filterable={true}
                      onFilterChange={filterEntChange}
                      onChange={handleEntChange}
                      placeholder="Seleccione Empresa"
                      style={darkModeStyle}
                      value={enterprise}
                      fillMode="outline"
                      required
                      validationMessage="Obligatorio"
                    />
                  </MDBox>
                </Grid>
                <Grid item xs={12} md={4} lg={4}>
                  <MDBox>
                    <h6 style={darkModeStyle}>
                      <b> COMPAÑÍA </b>
                    </h6>
                    <ComboBox
                      data={dataCompanies}
                      textField="company_name"
                      filterable={true}
                      onFilterChange={filterConChange}
                      onChange={handleConChange}
                      placeholder="Seleccione Compañía"
                      style={darkModeStyle}
                      value={company}
                      fillMode="outline"
                      required
                      validationMessage="Obligatorio"
                    />
                  </MDBox>
                </Grid>
                <Grid item xs={12} md={4} lg={4}>
                  <MDBox>
                    <h6 style={darkModeStyle}>
                      <b> LOCALIDAD </b>
                    </h6>
                    <ComboBox
                      data={locations}
                      value={locate}
                      // filterable={true}
                      // onFilterChange={filterConChange}
                      onChange={handleLocateChange}
                      placeholder="Seleccione Localidad"
                      style={darkModeStyle}
                      fillMode="outline"
                      required
                      validationMessage="Obligatorio"
                    />
                  </MDBox>
                </Grid>
              </Grid>
            </MDBox>
          </Card>
        </MDBox>
      </form>

      <Toolbar>
        <ToolbarItem>
          <Button icon="refresh" title="Actualizar" onClick={() => refreshVideo()}>
            Actualizar
          </Button>
        </ToolbarItem>
        <ToolbarSpacer />
        <ToolbarSeparator />
      </Toolbar>
      <br/>

      {showDivComponent && <MDBox pt={1} px={0}> <Card style={{ height: "65vh" }}></Card> </MDBox>}
      {showVideoComponent && (<VideoPlateGridLayout listCameras={listCameras} />)}

      {loading && (
        <Dialog>
          <Loader
            size="large"
            type={"converging-spinner"}
          />
        </Dialog>
      )}
      {notification && (
        <NotificationGroup style={position.bottomRight}>
          <Notification
            key="warning"
            type={{
              style: "warning",
              icon: true,
            }}
            closable={true}
            onClose={() => viewNotification(false)}
          >
            {content}
          </Notification>
        </NotificationGroup>
      )}
    </>
  )
}
