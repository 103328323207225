import React from 'react'

//Telerik
import { Grid as GridTelerik, GridToolbar, GridColumn, } from "@progress/kendo-react-grid";
import { Notification, NotificationGroup } from "@progress/kendo-react-notification";
import { Dialog } from "@progress/kendo-react-dialogs";
import { Button } from "@progress/kendo-react-buttons";
import { Input, RadioButton } from "@progress/kendo-react-inputs";
import { DatePicker } from "@progress/kendo-react-dateinputs";
//mui material
import { Icon } from "@mui/material";

//hooks
import { useLocalityStateStore } from 'hooks';
import { useUserStore } from 'hooks';


//Components

import { EditFormPreauthorized } from './EditFormPreAuthorized';


export const PreAuthorized = ({ value_localityID, value_company, value_enterprise }) => {
    const { loadResidentIDData, apiPersonData } = useUserStore();
    const { apiPreauthorizeData, apiPreauthorizeSave,
        apiPreauthorizeEdit, errorMessage, statusLocalityLoad,
        newPreauthorized, updatePreauthorized, deletePreauthorized, clearPreauthorizedStatus } = useLocalityStateStore();

    const user_uuid = localStorage.getItem("user");

    const [editItem, setEditItem] = React.useState();
    const [dataPreauthorized, setDataPreauthorized] = React.useState([])
    const [preauthorizedData, setPreauthorizedData] = React.useState([])
    const [openNewPreauthorized, setOpenNewPreauthorized] = React.useState(false)
    const [openUpdatePreauthorized, setOpenUpdatePreauthorized] = React.useState(false)
    const [identificationValue, setIdentificationValue] = React.useState("");
    const [nameValue, setNameValue] = React.useState("")
    const [content, setContent] = React.useState("<span></span>");
    const [notificationSuccess, setNotificationSuccess] = React.useState(false);
    const viewNotificationSuccess = (state) => {
        setNotificationSuccess(state)
    }

    const [notificationError, setNotificationError] = React.useState(false);
    const viewNotificationError = (state) => {
        setNotificationError(state)
    }

    const [notificationErrorQR, setNotificationErrorQR] = React.useState(false);
    const viewNotificationErrorQR = (state) => {
        setNotificationErrorQR(state)
    }




    const position = {
        bottomRight: {
            bottom: 0,
            right: 0,
            alignItems: "flex-end",
        }
    }


    const today = new Date();
    const tomorrow = new Date();

    const [formState, setFormState] = React.useState({
        startDate: today,
        endDate: tomorrow,
        //**validar */
        //endDate: tomorrow, //reviasr
    });



    const handleSubmit = (event) => {
        event.preventDefault();
        //console.log(event.target.pre_value.value)
        let start_date_data = formState.startDate
        let start_mm = start_date_data.getMonth() + 1;
        let start_dd = start_date_data.getDate();
        let start_yy = start_date_data.getFullYear();

        let end_date_data = formState.endDate
        let end_mm = end_date_data.getMonth() + 1;
        let end_dd = end_date_data.getDate();
        let end_yy = end_date_data.getFullYear();
        const request_savePreauthorized = {
            pre_type: "IDENTIFICACION",
            pre_value: (event.target.pre_value.value).toUpperCase(),
            pre_description: (event.target.pre_description.value).toUpperCase(),
            pre_start_date: start_yy + '-' + start_mm + '-' + start_dd + ' 00:00:00',
            pre_end_date: end_yy + '-' + end_mm + '-' + end_dd + ' 23:59:59',
            pre_file: false,
            pre_locality_id: value_localityID,
            pre_relation: "RECURRENTE",
            pre_creator_id: user_uuid,
            pre_company_uuid: value_company
        }
        //console.log(request_savePreauthorized)
        newPreauthorized(request_savePreauthorized)
    }

    const handleChange = (event) => {
        setFormState({ ...formState, [event.target.name]: event.target.value });
    };

    const CommandCell = (props) => {
        const { dataItem } = props;
        return <td className="k-command-cell">
            <button title="Editar Preautorizado" className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary k-grid-edit-command" onClick={() => enterEdit(dataItem)}
            >
                <Icon fontSize="small" sx={{ color: "white" }}>
                    edit
                </Icon>
            </button>
            <button title="Eliminar Preautorizado" className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base k-grid-remove-command" onClick={() => remove(dataItem)}
            >
                <Icon fontSize="small" sx={{ color: "white" }}>
                    delete
                </Icon>
            </button>
        </td>;
    };

    const [removeItem, setRemoveItem] = React.useState(undefined);

    const remove = (dataItem) => {
        setRemoveItem(dataItem)
        const request_remove = {
            pre_uuid: dataItem.pre_uuid,
            pre_deleter_id: user_uuid,
            pre_company_uuid: value_company,
            //pre_locality_id: value_localityID
        }
        //console.log(request_remove)
        deletePreauthorized(request_remove)
    }


    const handleLoadInformation = () => {
        let valuePersonID = document.getElementById("pre_value").value;

        const request_loadID = {
            identification_type: "CED",
            identification: valuePersonID,
        }
        //console.log(request_loadID)
        loadResidentIDData(request_loadID)

    }

    const handleCancel = () => {
        setOpenNewPreauthorized(false)
        setNameValue("")
        setIdentificationValue("")
    }

    const [yearValue, setYearValue] = React.useState()
    const [monthValue, setMonthValue] = React.useState()
    const [dayValue, setDayValue] = React.useState()

    const [yearEndValue, setYearEndValue] = React.useState()
    const [monthEndValue, setMonthEndValue] = React.useState()
    const [dayEndValue, setDayEndValue] = React.useState()


    //Editar Preautorizado -- Validacion del servicio -- No permite editar Preautorizado con QR
    const enterEdit = (item) => {
        if (item.pre_type === 'QR') {
            setContent("No Puede Modificar Preautorizado QR");
            viewNotificationErrorQR(true);
            setTimeout(() => {
                viewNotificationErrorQR(false);
            }, 4000);
        }
        else {
            let start_yy_item = item.pre_start_date
            let start_yy_pr = parseInt(start_yy_item.substr(6, 4))

            setYearValue(start_yy_pr)

            let start_mm_item = item.pre_start_date
            let start_mm_pr = parseInt(start_mm_item.substr(3, 3)) - 1
            setMonthValue(start_mm_pr)

            let start_dd_item = item.pre_start_date
            let start_dd_pr = parseInt(start_dd_item.substr(0, 2))
            setDayValue(start_dd_pr)

            let end_yy_item = item.pre_end_date
            let end_yy_pr = parseInt(end_yy_item.substr(6, 4))
            setYearEndValue(end_yy_pr)

            let end_mm_item = item.pre_end_date
            let end_mm_pr = parseInt(end_mm_item.substr(3, 2)) - 1
            setMonthEndValue(end_mm_pr)

            let end_dd_item = item.pre_end_date
            let end_dd_pr = parseInt(end_dd_item.substr(0, 3))
            setDayEndValue(end_dd_pr)

            setEditItem(item)
            setOpenUpdatePreauthorized(true)
        }
    }

    const start_date_update = new Date(yearValue, monthValue, dayValue)
    const end_date_update = new Date(yearEndValue, monthEndValue, dayEndValue)

    const [editItemData, setEditItemData] = React.useState(undefined);
    const handleSubmitEdit = (event) => {
        setEditItemData(event)
        //console.log(event)


        if (event.end_date !== undefined && event.start_date === undefined) {
            let end_date_data = event.end_date
            let end_mm = end_date_data.getMonth() + 1;
            let end_dd = end_date_data.getDate();
            let end_yy = end_date_data.getFullYear();

            let start_date_data = event.pre_start_date
            let start_yy = start_date_data.substr(6, 4)
            let start_mm = start_date_data.substr(3, 2)
            let start_dd = start_date_data.substr(0, 2)
            console.log(start_yy, start_mm, start_dd)

            const request_update = {
                pre_id: event.pre_id,
                pre_uuid: event.pre_uuid,
                pre_type: event.pre_type,
                pre_description: (event.pre_description).toUpperCase(),
                pre_start_date: start_yy + '-' + start_mm + '-' + start_dd + ' 00:00:00',
                pre_value: (event.pre_value).toUpperCase(),
                pre_end_date: end_yy + '-' + end_mm + '-' + end_dd + ' 23:59:59',
                pre_relation: event.pre_relation,
                pre_modifier_id: user_uuid,
                pre_company_uuid: value_company,
                pre_locality_id: value_localityID
            }
            //console.log(request_update)
            updatePreauthorized(request_update)

        } if (event.start_date !== undefined && event.end_date === undefined) {
            let start_date_data = event.start_date
            let start_mm = start_date_data.getMonth() + 1;
            let start_dd = start_date_data.getDate();
            let start_yy = start_date_data.getFullYear();

            let end_date_data = event.pre_end_date
            let end_yy = end_date_data.substr(6, 4)
            let end_mm = end_date_data.substr(3, 2)
            let end_dd = end_date_data.substr(0, 2)

            const request_update = {
                pre_id: event.pre_id,
                pre_uuid: event.pre_uuid,
                pre_type: event.pre_type,
                pre_description: (event.pre_description).toUpperCase(),
                pre_start_date: start_yy + '-' + start_mm + '-' + start_dd + ' 00:00:00',
                pre_end_date: end_yy + '-' + end_mm + '-' + end_dd + ' 23:59:59',
                pre_relation: event.pre_relation,
                pre_modifier_id: user_uuid,
                pre_company_uuid: value_company,
                pre_locality_id: value_localityID,
                pre_value: (event.pre_value).toUpperCase(),
            }
            //console.log(request_update)
            updatePreauthorized(request_update)

        } if (event.start_date !== undefined && event.end_date !== undefined) {
            let start_date_data = event.start_date
            let start_mm = start_date_data.getMonth() + 1;
            let start_dd = start_date_data.getDate();
            let start_yy = start_date_data.getFullYear();

            let end_date_data = event.end_date
            let end_mm = end_date_data.getMonth() + 1;
            let end_dd = end_date_data.getDate();
            let end_yy = end_date_data.getFullYear();

            const request_update = {
                pre_id: event.pre_id,
                pre_uuid: event.pre_uuid,
                pre_type: event.pre_type,
                pre_description: (event.pre_description).toUpperCase(),
                pre_start_date: start_yy + '-' + start_mm + '-' + start_dd + ' 00:00:00',
                pre_end_date: end_yy + '-' + end_mm + '-' + end_dd + ' 23:59:59',
                pre_relation: event.pre_relation,
                pre_modifier_id: user_uuid,
                pre_company_uuid: value_company,
                pre_locality_id: value_localityID,
                pre_value: (event.pre_value).toUpperCase(),
            }
           // console.log(request_update)
            updatePreauthorized(request_update)

        } if (event.start_date === undefined && event.end_date === undefined) {
            let start_date_data = event.pre_start_date
            let start_yy = start_date_data.substr(6, 4)
            let start_mm = start_date_data.substr(3, 2)
            let start_dd = start_date_data.substr(0, 2)
            console.log(start_yy, start_mm, start_dd)

            let end_date_data = event.pre_end_date
            let end_yy = end_date_data.substr(6, 4)
            let end_mm = end_date_data.substr(3, 2)
            let end_dd = end_date_data.substr(0, 2)

            const request_update = {
                pre_id: event.pre_id,
                pre_uuid: event.pre_uuid,
                pre_type: event.pre_type,
                pre_description: (event.pre_description).toUpperCase(),
                pre_start_date: start_yy + '-' + start_mm + '-' + start_dd + ' 00:00:00',
                pre_end_date: end_yy + '-' + end_mm + '-' + end_dd + ' 23:59:59',
                pre_relation: event.pre_relation,
                pre_modifier_id: user_uuid,
                pre_company_uuid: value_company,
                pre_locality_id: value_localityID,
                pre_value: (event.pre_value).toUpperCase(),
            }
            //console.log(request_update)
            updatePreauthorized(request_update)
        }


    }

    const handleCancelUpdate = () => {
        setOpenUpdatePreauthorized(false)
    }

    const addPreauthorized = () => {
        setOpenNewPreauthorized(true)
        setIdentificationValue("")
        setNameValue("")
        setFormState({
            startDate: today,
            endDate: tomorrow,
        })
    }

    const handleChangeIdentification = (event) => {
        setIdentificationValue(event.target.value)
    }

    const handleChangeNames = (event) => {
        setNameValue(event.target.value)
    }

    const cleanForm = () => {
        setIdentificationValue("")
        setNameValue("")
        //formState.startDate(today)
        setFormState({
            startDate: today,
            endDate: tomorrow,
        })
    }

    React.useEffect(() => {
        if (apiPersonData?.data !== undefined) {
            setNameValue(apiPersonData?.data.names + " " + apiPersonData?.data.surnames)
        }
    }, [apiPersonData])

    React.useEffect(() => {
        if (apiPreauthorizeEdit?.data !== undefined) {
            setPreauthorizedData(apiPreauthorizeEdit);
            setDataPreauthorized(apiPreauthorizeEdit.data.map((dataItem) => ({
                ...dataItem
            })));
        } else {
            setPreauthorizedData([]);
            setDataPreauthorized([]);
        }
    }, [apiPreauthorizeEdit])

    React.useEffect(() => {
        if (apiPreauthorizeSave?.data !== undefined) {
            setPreauthorizedData(apiPreauthorizeSave);
            setDataPreauthorized(apiPreauthorizeSave.data.map((dataItem) => ({
                ...dataItem
            })));
        } else {
            setPreauthorizedData([]);
            setDataPreauthorized([]);
        }
    }, [apiPreauthorizeSave])

    React.useEffect(() => {
        if (statusLocalityLoad === '2') {
            setContent('Registro Actualizado Exitosamente');
            viewNotificationSuccess(true);
            setTimeout(() => {
                viewNotificationSuccess(false);
            }, 4000);
            setOpenUpdatePreauthorized(false)

        }

        if (statusLocalityLoad === '3') {
            setContent('Registro Guardado Exitosamente');
            viewNotificationSuccess(true);
            setTimeout(() => {
                viewNotificationSuccess(false);
            }, 4000);
            setOpenNewPreauthorized(false)

        }

        if (statusLocalityLoad === '4') {
            const newDataState = [...dataPreauthorized];
            //console.log(record)
            let index = newDataState.findIndex((record) => record.pre_id === removeItem.pre_id);
            newDataState.splice(index, 1);
            setDataPreauthorized(newDataState)
        }
        clearPreauthorizedStatus()
        //clearBlackList()
    }, [statusLocalityLoad])

    React.useEffect(() => {
        if (errorMessage !== undefined) {
            console.log(errorMessage);
            setContent(errorMessage);
            viewNotificationError(true);
            setTimeout(() => {
                viewNotificationError(false);
            }, 4000);
        }
    }, [errorMessage])



    React.useEffect(() => {
        //console.log(apiPreauthorizeData?.data)
        if (apiPreauthorizeData?.data !== undefined) {
            setPreauthorizedData(apiPreauthorizeData);
            setDataPreauthorized(apiPreauthorizeData.data.map((dataItem) => ({
                ...dataItem
            })));
        } else {
            setPreauthorizedData([])
            setDataPreauthorized([]);
        }
    }, [apiPreauthorizeData])

    return (
        <>
            <GridTelerik
                style={{
                    height: "420px",
                }}
                data={dataPreauthorized}
            >
                <GridToolbar>
                    <div>
                        <button
                            title="Add new"
                            className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                            onClick={addPreauthorized}
                        >
                            <Icon fontSize="medium" sx={{ color: "rgb(235, 234, 232)" }}>
                                add_circle
                            </Icon>
                            Agregar Persona
                        </button>
                    </div>
                </GridToolbar>

                <GridColumn field="pre_type" title="TIPO" />
                <GridColumn field="pre_value" title="IDENTIFICACIÓN" />
                <GridColumn field="pre_description" title="NOMBRES" />
                <GridColumn field="pre_start_date" title="FECHA INICIO" />
                <GridColumn field="pre_end_date" title="FECHA FIN" />
                <GridColumn title="OPCIONES" cell={CommandCell} width="120px" />
            </GridTelerik>

            {openNewPreauthorized && (
                <Dialog
                    onClose={handleCancel}
                    title={"Añadir Preautorizado"}
                    width={"400px"}>
                    <form className="k-form" onSubmit={handleSubmit}
                    >
                        <div className="mb-3">
                            <label>CÉDULA/PASAPORTE/RUC*</label>
                            <Input
                                id="pre_value"
                                name="pre_value"
                                maxLength={128}
                                value={identificationValue}
                                onChange={handleChangeIdentification}
                                validationMessage="Obligatorio"
                                style={{
                                    width: "86%",
                                }}
                                required
                            />
                            <Button type="button" title="Buscar" fillMode="flat" onClick={handleLoadInformation}
                                style={{
                                    paddingTop: "10px",
                                    margin: "0"
                                }}>
                                <Icon fontSize="medium" sx={{ color: "rgb(26, 115, 232, 0.8)" }}>
                                    search
                                </Icon>
                            </Button>
                        </div>
                        <br />
                        <div className="mb-3">
                            <label>NOMBRES*</label>
                            <Input
                                id={"pre_description"}
                                name={"pre_description"}
                                maxLength={64}
                                validationMessage="Obligatorio"
                                onChange={handleChangeNames}
                                value={nameValue}
                                required
                                style={{
                                    width: "95%",
                                }}
                            />
                        </div>
                        <br />
                        <div style={{
                            display: "flex",
                            justifyContent: "space-between",
                        }}>
                            {/* <label width="50%">FECHA INICIO*</label>
                                <label style={{ paddingLeft: "23%" }}>FECHA FIN*</label> */}

                            <DatePicker
                                //width="50%"
                                name="startDate"
                                required
                                format={"dd-MM-yyyy"}
                                value={formState.startDate}
                                label={"FECHA INICIO*"}
                                onChange={handleChange}
                                min={today}
                                fillMode="flat"
                                validationMessage={
                                    formState.startDate === null ? "OBLIGATORIO" : "FECHA INVÁLIDA"
                                }
                            />

                            <DatePicker
                                //width="50%"
                                name="endDate"
                                required
                                format={"dd-MM-yyyy"}
                                value={formState.endDate}
                                onChange={handleChange}
                                label={"FECHA FIN*"}
                                min={today}
                                valid = {
                                    formState.startDate !== null &&
                                    formState.endDate !== null &&
                                    formState.startDate.getTime() <= formState.endDate.getTime()
                                }
                                fillMode="flat"
                                validationMessage={
                                    formState.endDate === null ? "OBLIGATORIO" : "FECHA INVÁLIDA"
                                }
                            />
                        </div>

                        <div className="k-form-buttons" style={{ marginLeft: "73%" }}>

                            <Button themeColor={"primary"} type="submit" title="Guardar"
                            >
                                <Icon fontSize="small" sx={{ color: "white" }}>
                                    save
                                </Icon>
                            </Button>

                            <Button onClick={cleanForm}>
                                <Icon fontSize="small" sx={{ color: "white" }} >
                                    backspace_rounded
                                </Icon>
                            </Button>

                        </div>
                    </form>
                    {notificationError && (
                        <NotificationGroup
                            style={
                                position.bottomRight
                            }>
                            <Notification
                                key="error"
                                type={{
                                    style: "error",
                                }}

                                closable={true}
                                onClose={() => viewNotificationError(false)}
                            >
                                {content}
                            </Notification>
                        </NotificationGroup>
                    )}
                </Dialog>
            )}

            {openUpdatePreauthorized && (
                <EditFormPreauthorized
                    value_start_date={start_date_update}
                    value_end_date={end_date_update}
                    cancelEdit={handleCancelUpdate}
                    onSubmit={handleSubmitEdit}
                    item={editItem}
                >
                    {notificationError && (
                        <NotificationGroup
                            style={
                                position.bottomRight
                            }>
                            <Notification
                                key="error"
                                type={{
                                    style: "error",
                                }}

                                closable={true}
                                onClose={() => viewNotificationError(false)}
                            >
                                {content}
                            </Notification>
                        </NotificationGroup>
                    )}
                </EditFormPreauthorized>

            )}

            {notificationSuccess && (
                <NotificationGroup
                    style={
                        position.bottomRight
                    }>
                    <Notification
                        key="error"
                        type={{
                            style: "success",
                            //margin: "center"
                        }}

                        closable={true}
                        onClose={() => viewNotificationSuccess(false)}
                    >
                        {content}
                    </Notification>
                </NotificationGroup>
            )}

            {notificationErrorQR && (
                <NotificationGroup
                    style={
                        position.bottomRight
                    }>
                    <Notification
                        key="error"
                        type={{
                            style: "error",
                        }}

                        closable={true}
                        onClose={() => viewNotificationErrorQR(false)}
                    >
                        {content}
                    </Notification>
                </NotificationGroup>
            )}

        </>
    )
}
