/* eslint-disable array-callback-return */
import moment from "moment";
import JSZip from 'jszip';

export class ApiIA {
  generateDataLive = async (count, filters) => {

    // Obtener el token para consumir el api 
    const token = localStorage.getItem('tokenIA');
    const apiName = localStorage.getItem('apiNameIA');

    // Llamar al api para obtener los datos
    const data = await this.getDataLive(count, token, apiName, filters);
    return data;
  };

  getDataLive = async (event_number, token, client, filters) => {
    // console.log(filters);
    try {
      if(client !== null){
        if (client.toUpperCase() === 'BANCO GUAYAQUIL')
          client = 'bg'
      }else{
        return [];
      }
      const response = await fetch(
        `https://lpr.ai.telconet.net/${client}/consumer/events/last/${event_number}?plate=${filters.searchPlate}&car_type=${filters.searchCarType}&car_color=${filters.searchColor}&car_make=${filters.searchMark}&locate=${filters.searchLocate}&camera__name=${filters.searchCamera}&driver__name=${filters.searchDriverName}&driver__last_name=${filters.searchDriverLastName}`,
        {
          method: "GET",
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          },
        }
      );
      if (response.status === 200) {
        const jsonData = await response.json();
        return jsonData;
      } else {
        console.log('Error al obtener los datos de la API:', response.status);
        if (response.status === 401 || response.status === 422) {
          console.log('TOKEN invalido');
          await this.getToken();
        }
        return [];
      }
    } catch (error) {
      console.log("Error al obtener los datos de la API:", error);
      return [];
    }
  };

  getToken = async () => {
    try {
      const request = {
        "username": "safeentry",
        "password": "T31c0$LPR"
      }
      const response = await fetch(
        `https://lpr.ai.telconet.net/delta/consumer/login`,
        {
          method: "POST",
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(request)
        }
      );
      if (response.status === 200) {
        const jsonData = await response.json();
        localStorage.setItem('tokenIA', jsonData.token);
      } else {
        console.log('Error al obtener el token de ia:', response.status);
      }
    } catch (error) {
      console.log("Error al obtener el token:", error);
    }
  };

  getDataExcel = async (request) => {
    try {
      const token = localStorage.getItem('tokenIA');

      if (request.apiName.toUpperCase() === 'BANCO GUAYAQUIL')
        request.apiName = 'bg'

      const response = await fetch(
        `https://lpr.ai.telconet.net/${request.apiName}/consumer/events/${request.timestamp_startDate}/${request.timestamp_endDate}?limit=0&plate=${request.searchPlate}&car_type=${request.searchCarType}&car_color=${request.searchColor}&car_make=${request.searchMark}&locate=${request.searchLocate}&camera__name=${request.searchCamera}&no_page=true&offset=0`,
        {
          method: "GET",
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          },
        }
      );
      if (response.status === 200) {
        const jsonData = await response.json();
        return jsonData.results;
      } else {
        console.log('Error al obtener los datos de la API:', response.status);
        if (response.status === 401 || response.status === 422) {
          console.log('TOKEN invalido');
          await this.getToken();
          return null;
        } else if (response.status === 404) {
          return 0;
        }
        return undefined;
      }
    } catch (error) {
      console.log("Error al obtener los datos de la API:", error);
      return undefined;
    }
  };

  getListRecordings = async (request) => {
    try {
      const token = localStorage.getItem('tokenIA');

      if (request.apiName.toUpperCase() === 'BANCO GUAYAQUIL')
        request.apiName = 'bg'

      // const GMT_startDate = (moment.utc(request.startDate)).format('YYYY-MM-DD HH:mm:ss');
      // const timestamp_startDate = moment(GMT_startDate).valueOf() / 1000;
      // console.log(timestamp_startDate);
      const dateObject_startDate = new Date(request.startDate);
      const timestamp_startDate = dateObject_startDate.getTime() / 1000; 
      // console.log(timestamp_startDate);

      // const GMT_endDate = (moment.utc(request.endDate)).format('YYYY-MM-DD HH:mm:ss');
      // const timestamp_endDate = moment(GMT_endDate).valueOf() / 1000;
      // console.log(timestamp_endDate);
      const dateObject_endDate = new Date(request.endDate);
      const timestamp_endDate = dateObject_endDate.getTime() / 1000; 
      // console.log(timestamp_endDate);

      const response = await fetch(
        `https://lpr.ai.telconet.net/${request.apiName}/consumer/list_recordings?camera__name=${request.cameraName}&camera__locate=${request.cameraLocate}&since=${timestamp_startDate}&until=${timestamp_endDate}`,
        {
          method: "GET",
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          },
        }
      );
      if (response.status === 200) {
        const jsonData = await response.json();
        if(Object.keys(jsonData).length === 0){
          return 0;
        }else{
          let isDaysEmpty = false;
          Object.keys(jsonData).map((key) => {
            let item = jsonData[key];
            isDaysEmpty = Object.keys(item.days).length === 0;
          })
          if (!isDaysEmpty)
            return jsonData;
          else
            return 0;
        }
      } else {
        console.log('Error al obtener los datos de la API:', response.status);
        if (response.status === 401 || response.status === 422) {
          console.log('TOKEN invalido');
          await this.getToken();
          return null;
        } else if (response.status === 404) {
          return 0;
        }
        return undefined;
      }
    } catch (error) {
      console.log("Error al obtener los datos de la API:", error);
      return undefined;
    }
  };

  downloadRecording = async (request) => {
    try {
      const token = localStorage.getItem('tokenIA');

      if (request.apiName.toUpperCase() === 'BANCO GUAYAQUIL')
        request.apiName = 'bg'

      let url = `https://lpr.ai.telconet.net/${request.apiName}/consumer/download_recording?route=${request.videoRoute}`;

      const response = await fetch(
        url,
        {
          method: "GET",
          headers: {
            'Content-Type': 'application/json',
            // 'Authorization': `Bearer ${token}`
          },
        }
      );
      if (response.status === 200) {
        const downloadLink = document.createElement('a');
        downloadLink.href = url;
        downloadLink.download = 'video.mp4';
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
        URL.revokeObjectURL(downloadLink.href);
        return true;
      } else {
        console.log('Error al obtener los datos de la API:', response.status);
        // if(response.status === 401 || response.status === 422 ){
        //   console.log('TOKEN invalido');
        //   await this.getToken();
        // }
        return false;
      }
    } catch (error) {
      console.log("Error al obtener los datos de la API:", error);
      return false;
    }
  };

  downloadRecordingsZip = async (request) => {
    try {
      const zip = new JSZip();
      const token = localStorage.getItem('tokenIA');

      if (request.apiName.toUpperCase() === 'BANCO GUAYAQUIL')
        request.apiName = 'bg'
      // console.warn(request.videoRoute.items);
      for (const item of request.videoRoute.items) {
        let url = `https://lpr.ai.telconet.net/${request.apiName}/consumer/download_recording?route=${item.text}`;
        const response = await fetch(
          url,
          {
            method: "GET",
            headers: {
              'Content-Type': 'application/json',
              // 'Authorization': `Bearer ${token}`
            },
          }
        );

        if (response.status === 200) {
          const blob = await response.blob();
          zip.file(item.text, blob);
        } else {
          console.log('Error al obtener los datos de la API:', response.status);
        }
      }

      const zipBlob = await zip.generateAsync({ type: 'blob' });
      const downloadLink = document.createElement('a');
      downloadLink.href = URL.createObjectURL(zipBlob);
      downloadLink.download = 'videos.zip';
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
      URL.revokeObjectURL(downloadLink.href);
      return true;
    } catch (error) {
      console.log("Error al obtener los datos de la API:", error);
      return false;
    }
  };

  getListCameras = async (request) => {
    try {
      const token = localStorage.getItem('tokenIA');

      if (request.apiName.toUpperCase() === 'BANCO GUAYAQUIL')
        request.apiName = 'bg'

      const response = await fetch(
        `https://lpr.ai.telconet.net/${request.apiName}/consumer/cameras`,
        {
          method: "GET",
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          },
        }
      );
      if (response.status === 200) {
        const jsonData = await response.json();
        return jsonData;
      } else {
        console.log('Error al obtener los datos de la API:', response.status);
        if (response.status === 401 || response.status === 422) {
          console.log('TOKEN invalido');
          await this.getToken();
          return null;
        } else if (response.status === 404) {
          return 0;
        }
        return undefined;
      }
    } catch (error) {
      console.log("Error al obtener los datos de la API:", error);
      return undefined;
    }
  };

  getListCamerasByLocate = async (request) => {
    try {
      const token = localStorage.getItem('tokenIA');

      if (request.apiName.toUpperCase() === 'BANCO GUAYAQUIL')
        request.apiName = 'bg'

      const response = await fetch(
        `https://lpr.ai.telconet.net/${request.apiName}/consumer/cameras?locate=${request.locate}`,
        {
          method: "GET",
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          },
        }
      );
      if (response.status === 200) {
        const jsonData = await response.json();
        return jsonData;
      } else {
        console.log('Error al obtener los datos de la API:', response.status);
        if (response.status === 401 || response.status === 422) {
          console.log('TOKEN invalido');
          await this.getToken();
          return null;
        } else if (response.status === 404) {
          return 0;
        }
        return undefined;
      }
    } catch (error) {
      console.log("Error al obtener los datos de la API:", error);
      return undefined;
    }
  };

  getListCompanies = async (request) => {
    try {
      let bear_token = localStorage.getItem('token');

      const response = await fetch(
        'https://iyd.telconet.ec/se/user/user/enterprise/company/list',
        {
          method: "POST",
          headers: {
            'Content-Type': 'application/json',
            'Security-Token': bear_token
          },
          body: JSON.stringify(request)
        }
      );
      if (response.status === 200) {
        const jsonData = await response.json();
        return jsonData.data;
      } else {
        console.log('Error al obtener los datos de la API:', response.status);
        return undefined;
      }
    } catch (error) {
      console.log("Error al obtener los datos de la API:", error);
      return undefined;
    }
  };

}
