import { createSlice } from '@reduxjs/toolkit';

export const massiveSlice = createSlice({
    name: 'massive',
    initialState: {
        statusMassiveLoad: '0',
        statusMassivePreauthorization: '0',
        statusSyncTower: '0',
        statusMassiveEmails: '0',
        statusActivationTower: '0',

        //Massive Localidades
        apiTowereData: {},
        apiFileLogData: {},
        apiLogsData: {},
        apiDownlaodData: {},
        apiUploadData: {},
        errorMassiveDataMessage: undefined,
        errorLogsDataMessage: undefined,
        errorUploadDataMessage: undefined,
        errorDownloadFileMessage: undefined,

        //Massive Preautorizaciones
        apiPreFileLogData: {},
        apiLogsPreData: {},
        apiDownloadPreData: {},
        apiUploadPreData: {},
        errorMassivePreDataMessage: undefined,
        errorLogsPreDataMessage: undefined,
        errorUploadPreDataMessage: undefined,
        errorDownloadPreFileMessage: undefined,

        //Massive Sync Tower
        apiSyncTower: {},
        errorSyncTower: undefined,

        //Massive Emails
        apiSendEmails: {},
        apiUnsentEmails: {},
        apiForwardEmails: {},
        errorSendEmails: undefined,
        errorUnsentEmails: undefined,
        errorForwardEmails: undefined,

        //Activation Tower
        apiActivationTower: {},
        errorActivationTower: undefined,


    },
    reducers: {
        //Massive Localidades
        onLoadTowerData: (state, { payload }) => {
            state.statusMassiveLoad = '1';
            state.apiTowereData = payload;
            state.errorMassiveDataMessage = undefined;
        },

        onErrorTowerData: (state, { payload }) => {
            state.statusMassiveLoad = '0';
            state.errorMassiveDataMessage = payload;
        },

        clearErrorMassiveData: (state) => {
            state.errorMassiveDataMessage = undefined;
            state.errorLogsDataMessage = undefined;
            state.errorUploadDataMessage = undefined;
        },

        onLoadFileLogData: (state, { payload }) => {
            state.statusMassiveLoad = '1';
            state.apiFileLogData = payload;
            state.errorMassiveDataMessage = undefined;
        },

        onErrorFileLogsData: (state, { payload }) => {
            state.statusMassiveLoad = '0';
            state.errorMassiveDataMessage = payload;
        },

        onLoadLogsData: (state, { payload }) => {
            state.statusMassiveLoad = '1';
            state.apiLogsData = payload;
            state.errorLogsDataMessage = undefined;
        },

        onErrorLogsData: (state, { payload }) => {
            state.statusMassiveLoad = '0';
            state.errorLogsDataMessage = payload;
        },

        onDownloadFile: (state, { payload }) => {
            state.statusMassiveLoad = '5';
            state.apiDownlaodData = payload;
            state.errorDownloadFileMessage = undefined;
        },

        onErrorDownloadData: (state, { payload }) => {
            state.statusMassiveLoad = '0';
            state.errorDownloadFileMessage = payload;
        },

        onUploadFile: (state, { payload }) => {
            state.statusMassiveLoad = '2';
            state.apiUploadData = payload;
            state.errorUploadDataMessage = undefined;
        },

        onErrorUploadData: (state, { payload }) => {
            state.statusMassiveLoad = '0';
            state.errorUploadDataMessage = payload;
        },

        onDeleteFile: (state, { payload }) => {
            state.statusMassiveLoad = '1';
            //state.apiDownlaodData = payload;
            state.errorLogsDataMessage = undefined;
        },

        clearDownloadDataStatus: (state) => {
            state.statusMassiveLoad = '0';
            state.apiDownlaodData = undefined;
        },

        clearLogsDataStatus: (state) => {
            state.statusMassiveLoad = '0';
            state.apiLogsData = undefined;
        },

        //Massive Preautorizados

        clearErrorMassivePreData: (state) => {
            state.errorMassivePreDataMessage = undefined;
            state.errorLogsPreDataMessage = undefined;
            state.errorUploadPreDataMessage = undefined;
        },

        onLoadPreFileLogData: (state, { payload }) => {
            state.statusMassivePreauthorization = '1';
            state.apiPreFileLogData = payload;
            state.errorMassivePreDataMessage = undefined;
        },

        onErrorPreFileLogsData: (state, { payload }) => {
            state.statusMassivePreauthorization = '0';
            state.errorMassivePreDataMessage = payload;
        },

        onLoadLogsPreData: (state, { payload }) => {
            state.statusMassivePreauthorization = '1';
            state.apiLogsPreData = payload;
            state.errorLogsPreDataMessage = undefined;
        },

        onErrorLogsPreData: (state, { payload }) => {
            state.statusMassivePreauthorization = '0';
            state.errorLogsPreDataMessage = payload;
        },

        onDownloadPreFile: (state, { payload }) => {
            state.statusMassivePreauthorization = '5';
            state.apiDownloadPreData = payload;
            state.errorDownloadPreFileMessage = undefined;
        },

        onErrorDownloadPreData: (state, { payload }) => {
            state.statusMassivePreauthorization = '0';
            state.errorDownloadPreFileMessage = payload;
        },

        onUploadPreFile: (state, { payload }) => {
            state.statusMassivePreauthorization = '2';
            state.apiUploadPreData = payload;
            state.errorUploadPreDataMessage = undefined;
        },

        onErrorUploadPreData: (state, { payload }) => {
            state.statusMassivePreauthorization = '0';
            state.errorUploadPreDataMessage = payload;
        },

        onDeletePreFile: (state, { payload }) => {
            state.statusMassivePreauthorization = '1';
            //state.apiDownloadPreData = payload;
            state.errorLogsPreDataMessage = undefined;
        },

        clearDownloadPreDataStatus: (state) => {
            state.statusMassivePreauthorization = '0';
            state.apiDownloadPreData = undefined;
        },

        clearLogsPreDataStatus: (state) => {
            state.statusMassivePreauthorization = '0';
            state.apiLogsPreData = undefined;
        },

        //Massive Emails

        onSendEmails: (state, { payload }) => {
            state.statusMassiveEmails = '1';
            state.apiSendEmails = payload;
            state.errorSendEmails = undefined;
        },

        onUnsentEmails: (state, { payload }) => {
            state.statusMassiveEmails = '2';
            state.apiUnsentEmails = payload;
            state.errorUnsentEmails = undefined;
        },

        onForwardingEmails: (state, { payload }) => {
            state.statusMassiveEmails = '3';
            state.apiForwardEmails = payload;
            state.errorForwardEmails = undefined;
        },

        onErrorSendEmails: (state, { payload }) => {
            state.statusMassiveEmails = '0';
            state.errorSendEmails = payload;
        },

        onErrorUnsentEmails: (state, { payload }) => {
            state.statusMassiveEmails = '0';
            state.errorUnsentEmails = payload;
        },

        onErrorForwardingEmails: (state, { payload }) => {
            state.statusMassiveEmails = '0';
            state.errorForwardEmails = payload;
        },

        clearErrorMassiveEmails: (state) => {
            state.errorSendEmails = undefined;
            state.errorUnsentEmails = undefined;
            state.errorForwardEmails = undefined;
        },

        //Sync Massive Tower
        onSyncTower: (state, { payload }) => {
            state.statusSyncTower = '1';
            state.apiSyncTower = payload;
            state.errorSyncTower = undefined;
        },

        onErrorSyncTower: (state, { payload }) => {
            state.statusSyncTower = '0';
            state.errorSyncTower = payload;
        },

        clearErrorSyncTower: (state) => {
            state.errorSyncTower = undefined;
        },

        clearStatusActivation: (state) => {
            state.statusSyncTower = '0';
            state.statusActivationTower = '0';
            state.statusMassiveEmails = '0';
        }
,
        //Activate Tower
        onActivationTower: (state, { payload }) => {
            state.statusActivationTower = '1';
            state.apiActivationTower = payload;
            state.errorActivationTower = undefined;
        },

        onErrorActivationTower: (state, { payload }) => {
            state.statusActivationTower = '0';
            state.errorActivationTower = payload;
        },

        clearErrorActivationTower: (state) => {
            state.errorActivationTower = undefined;
        },
    }
});
// Action creators are generated for each case reducer function
export const {
    // Activation Tower
    onActivationTower,
    onErrorActivationTower,
    clearErrorActivationTower,

    //Sync Tower
    clearErrorSyncTower,
    onErrorSyncTower,
    onSyncTower,
    clearStatusActivation,

    //Massive Email
    onErrorForwardingEmails,
    clearErrorMassiveEmails,
    onErrorUnsentEmails,
    onErrorSendEmails,
    onSendEmails,
    onUnsentEmails,
    onForwardingEmails,
    clearErrorMassivePreData,

    //Massive Preauthorization
    onLoadPreFileLogData,
    onErrorDownloadPreData,
    onErrorPreFileLogsData,
    onLoadLogsPreData,
    onErrorLogsPreData,
    onDownloadPreFile,
    onUploadPreFile,
    onErrorUploadPreData,
    onDeletePreFile,
    clearDownloadPreDataStatus,
    clearLogsPreDataStatus,

    //Massive Data Cloud
    clearLogsDataStatus,
    clearDownloadDataStatus,
    onErrorUploadData,
    errorUploadDataMessage,
    onUploadFile,
    onDeleteFile,
    onDownloadFile,
    onLoadLogsData,
    onErrorLogsData,
    onLoadFileLogData,
    onErrorFileLogsData,
    onLoadTowerData,
    onErrorTowerData,
    clearErrorMassiveData,

} = massiveSlice.actions;
