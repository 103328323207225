import axios from 'axios';

const userApi = axios.create({
   baseURL: "https://iyd.telconet.ec/se/user"
});

// Todo: configuraciones de los interceptores esto es cuando el api nos devulve el token
// userApi.interceptors.request.use( config => {
//   config.headers = {
//     ...config.headers,
//     'Security-Token': localStorage.getItem('token'),
//   }
//   return config;
// })

export default userApi;