/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useMemo, useEffect, useCallback, useRef } from 'react';

import Hls from "hls.js";

export const VideoHls = ({ cameraUrl, changeCamera, setChangeCamera }) => {

  const [loadVideo, setLoadVideo] = useState(true);
  const videoRef = useRef(undefined);
  const hlsConfig = {
    debug: true,
    startPosition: 10
  }

  let hls = new Hls(hlsConfig);

  // const sourceUrl = cameraUrl;
  const sourceUrl = useMemo(() => {
    let source = cameraUrl;
    if (cameraUrl) {
      if (!cameraUrl.includes('/api')) {
        // source = API + cameraUrl.split('/api')[1];
        if (!cameraUrl.includes('stream.m3u8'))
          source = cameraUrl + 'stream.m3u8'
      }
    }
    return source;
  }, [cameraUrl]);

  useEffect(() => {
    if (Hls.isSupported() && videoRef.current && sourceUrl && loadVideo) {

      // Initialize hls.js with credentials
      const hlsConfig = {
        xhrSetup: function (xhr) {
          xhr.withCredentials = false; // Habilitar credenciales para autenticación básica
          xhr.setRequestHeader(
            'Authorization',
            'Basic ' + btoa(`${'telconet'}:${'t3lc0n3t!V1D30C4M'}`)
          );
        },
      };

      const hls = new Hls(hlsConfig);

      // bind them together
      hls.attachMedia(videoRef.current);
      // MEDIA_ATTACHED event is fired by hls object once MediaSource is ready
      hls.on(Hls.Events.MEDIA_ATTACHED, function () {
        hls.loadSource(sourceUrl);
        hls.on(Hls.Events.MANIFEST_PARSED, function (event, data) {
          // console.log('manifest loaded, found ' + data.levels.length + ' quality level');
          if (data.fatal) {
            switch (data.type) {
              case Hls.ErrorTypes.NETWORK_ERROR:
                // try to recover network error
                // console.log('fatal network error encountered, try to recover');
                hls.startLoad();
                break;
              case Hls.ErrorTypes.MEDIA_ERROR:
                // console.log('fatal media error encountered, try to recover');
                hls.recoverMediaError();
                break;
              default:
                // cannot recover
                hls.destroy();
                break;
            }
          } else setLoadVideo(false);
        })
      });
    }
  }, [videoRef.current, sourceUrl, loadVideo, changeCamera, setChangeCamera, setLoadVideo]);

  useEffect(() => {
    // destroy hls when camera changes
    if (Hls.isSupported() && videoRef.current && sourceUrl && changeCamera) {
      hls.destroy();
      setChangeCamera(false);
      setLoadVideo(true);
    }
  }, [videoRef.current, sourceUrl, changeCamera]);

  const handleVideoProgress = useCallback((e) => {
    if (hls) {
      // search errors while the video progresses
      hls.on(Hls.Events.ERROR, function (event, data) {
        const errorType = data.type;
        const errorDetails = data.details;
        const errorFatal = data.fatal;
        // console.log('ERROR', errorType, errorDetails, errorFatal, data)
        if (data.fatal) {
          switch (data.type) {
            case Hls.ErrorTypes.NETWORK_ERROR:
              // try to recover network error
              // console.log('fatal network error encountered, try to recover');
              hls.startLoad();
              break;
            case Hls.ErrorTypes.MEDIA_ERROR:
              // console.log('fatal media error encountered, try to recover');
              hls.recoverMediaError();
              break;
            default:
              // cannot recover
              hls.destroy();
              break;
          }
        } else {
          console.log('progress event de hls', errorType, errorDetails, errorFatal)
        }
      });
    }
  }, [videoRef.current?.currentTime, hls]);

  const handleVideoPaused = useCallback((e) => {
    if (videoRef.current && hls) {
      // restart the video when it's paused
      videoRef.current.play();
      videoRef.muted = true;
      // console.log('played after pause')
    }
  }, [videoRef.current])

  const handleVideoStopped = useCallback((e) => {
    if (videoRef.current && hls) {
      videoRef.current.play();
      videoRef.muted = true;
      // console.log('played after stopped')
    }
  }, [videoRef.current]);

  const handleVideoEnded = useCallback((e) => {
    if (videoRef.current && hls) {
      videoRef.current.play();
      videoRef.muted = true;
      // console.log('played after ended')
    }
  }, [videoRef.current]);

  const handleVideoError = useCallback((e) => {
    if (videoRef.current && hls) {
      videoRef.current.play();
      videoRef.muted = true;
      // console.log('played after error')
    }
  }, [videoRef.current]);

  useEffect(() => {
    if (!loadVideo) {
      const interval = setInterval(() => {
        if (videoRef.current && hls) {
          videoRef.current.play();
          videoRef.muted = true;
          // console.log('video restarted')
        }
      }, 1000); //360000 (6 min)
      return () => clearInterval(interval);
    }
  }, [videoRef?.current, loadVideo]);

  return (
    <video
      id="video"                                      // Establece un ID para el elemento de video.
      ref={videoRef}                                  // Asocia una referencia (ref) de React llamada videoRef al elemento de video.
      autoPlay={true}                                 // Habilita la reproducción automática del video cuando se carga la página.
      autoFocus={true}                                // Da el foco automático al elemento de video cuando se carga la página.         
      muted="muted"                                   // Silencia el sonido del video.
      preload="auto"                                  // Solicita al navegador que precargue el video (metadatos y algunos datos) para una reproducción más rápida.
      controls={false}                                // Oculta los controles de reproducción estándar del navegador (play, pause, volumen, etc.).
      width="100%"
      height="100%"
      onError={(e) => handleVideoError(e)}            // Establece un manejador de eventos para el evento onError que se llama cuando se produce un error en la reproducción del video.
      onProgress={(e) => handleVideoProgress(e)}      // Establece un manejador de eventos para el evento onProgress que se llama durante la reproducción del video.
      onPause={(e) => handleVideoPaused(e)}           // Establece un manejador de eventos para el evento onPause que se llama cuando se pausa el video.
      // onStop={(e) => handleVideoStopped(e)}           // Establece un manejador de eventos para el evento onStop (no es un evento estándar de HTML5 <video>).
      onEnded={(e) => handleVideoEnded(e)}            // Establece un manejador de eventos para el evento onEnded que se llama cuando el video termina de reproducirse.
    />
  )
}