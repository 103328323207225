import { useDispatch, useSelector } from "react-redux";
import {
    clearErrorMessageResident, onErrorLocalityResidentData,
    clearRemoveResidentData, clearPreauthorizedData, clearResidentData, onLoadLocalityData, onErrorData, onLoadLocalityResidentData, onErrorDescriptionData, clearErrorMessage,
    clearErrorMessageDescription, clearErrorMessagePreauthorized, onNewLocality, onLoadLocalityNotificationData, onResidentLocalityData, onUpdateLocalityNotificationData,
    onStateLocalityData, onLoadDescriptionData, onLoadSearchLocalityData, onUpdateLocalityData, onLoadLocalityType,
    onSaveResidentLocalityData, onLoadLocalityMzData, onNewMz, onLoadPreauthorizeData, clearLocalityData,  onStateResidentLocalityData, onUpdateStateLocality,
    onPreauthorizeDelete, onNewPreauthorized,  onErrorPreauthorizedData, onUpdatePreauthorized, clearLocalityPreauthorizedStatus} from 'store/safeentry';
import { tokenApi, localityApi, residentApi } from "api";

export const useLocalityStateStore = () => {

    const dispatch = useDispatch();
    const { statusLocalityLoad, apiLocalityData, apiLocalityNotificationData,
        apiDescriptionData, apiLocalityMzData, apiLocalityResidentData, apiSearchLocalityData, apiPreauthorizeData,
         apiPreauthorizeSave, errorMessageResidents, apiLocalityStateUpdate,
        apiPreauthorizeEdit, errorMessage, errorMessageDescription, errorMessagePreauthorized } = useSelector(state => state.localityState);

    

    const clearResidentLocality = async () => {
        dispatch(clearResidentData());
    }
    const clearRemoveResident = async () => {
        dispatch(clearRemoveResidentData());
    }

    const loadResidentLocalityData = async (locality_id) => {
        try {
            let bear_token = localStorage.getItem('token')
            const { data } = await residentApi.get(`locality/person/list/${locality_id}`, {
                headers: {
                    'Security-Token': bear_token
                }
            });

            dispatch(onLoadLocalityResidentData({ data: data.data }));
        } catch (error) {
            // error.response.data?.details_error || error.response.data?.message || 
            dispatch(onErrorLocalityResidentData(error.response.data?.details_error || error.response.data?.message || 'Lo sentimos. Algunos de nuestros procesos no funcionó.'));
            setTimeout(() => {
                dispatch(clearErrorMessageResident());
            }, 10);
        }
    }

    const loadLocalityData = async (enterprise_uuid, company_uuid) => {
        try {
            let bear_token = localStorage.getItem('token')
            const { data } = await localityApi.get(`locality/list/${enterprise_uuid}/${company_uuid}`, {
                headers: {
                    'Security-Token': bear_token
                }
            });
            //console.log(data)
            dispatch(onLoadLocalityData({ data: data.data }));
        } catch (error) {
            // error.response.data?.details_error || error.response.data?.message || 
            dispatch(onErrorData(error.response.data?.details_error || error.response.data?.message || 'Lo sentimos. Algunos de nuestros procesos no funcionó.'));
            setTimeout(() => {
                dispatch(clearErrorMessage());
            }, 10);
        }
    }

    const loadLocalityType = async () => {
        try {
            let bear_token = localStorage.getItem('token')
            const { data } = await localityApi.get('locality/catalog', {
                headers: {
                    'Security-Token': bear_token
                }
            });
            //console.log(data)
            dispatch(onLoadLocalityType({ data: data.data }));
        } catch (error) {
            console.log(error)
            // error.response.data?.details_error || error.response.data?.message || 
            dispatch(onErrorData(''));
            setTimeout(() => {
                dispatch(clearErrorMessage());
            }, 10);
        }
    }

    const loadSearchLocalityData = async ({ uuid_company, uuid_locality_catalog, manzana, villa, piso, departamento, edificio }) => {
        try {
            let bear_token = localStorage.getItem('token')
            const { data } = await localityApi.post('/locality/find', { uuid_company, uuid_locality_catalog, manzana, villa, piso, departamento, edificio }, {
                headers: {
                    'Security-Token': bear_token
                }
            });
            dispatch(onLoadSearchLocalityData({ data: data.data }));
        } catch (error) {
            // error.response.data?.details_error || error.response.data?.message || 
            dispatch(onErrorData(error.response.data?.details_error || error.response.data?.message || 'Lo sentimos. Algunos de nuestros procesos no funcionó.'));
            setTimeout(() => {
                dispatch(clearErrorMessage());
            }, 10);
        }
    }

    const loadResidentData = async (locality_uuid) => {
        try {
            const dataToken = await getToken();
            if (dataToken !== '') {
                //se llama al servicio de localidades 
                const { data } = await localityApi.get(`/locality/resident/list/${locality_uuid}`, {
                    headers: {
                        'Security-Token': dataToken
                    }
                });
                dispatch(onLoadLocalityData({ data: data.data }));
            } else {
                dispatch(onErrorData('Token incorrecto'));
                setTimeout(() => {
                    dispatch(clearErrorMessage());
                }, 10);
            }
        } catch (error) {
            // error.response.data?.details_error || error.response.data?.message || 
            dispatch(onErrorData(error.response.data?.details_error || error.response.data?.message || 'Lo sentimos. Algunos de nuestros procesos no funcionó.'));
            setTimeout(() => {
                dispatch(clearErrorMessage());
            }, 10);
        }
    }

    const updateStateLocality = async ({ uuid_locality, valor, description, modifier_id }) => {
        try {
            let bear_token = localStorage.getItem('token')
            const { data } = await localityApi.post('/locality/activation', { uuid_locality, valor, description, modifier_id }, {
                headers: {
                    'Security-Token': bear_token
                }
            });
            dispatch(onUpdateStateLocality());
            
        } catch (error) {
            // error.response.data?.details_error || error.response.data?.message || 
            dispatch(onErrorData(error.response.data?.details_error || error.response.data?.message || 'Lo sentimos. Algunos de nuestros procesos no funcionó.'));
            setTimeout(() => {
                dispatch(clearErrorMessage());
            }, 10);
        }
    }

    const removeResidentLocality = async ({ uuid_locality, per_uuid, valor, description, modifier_id }) => {
        try {
            let bear_token = localStorage.getItem('token')
            const { data } = await localityApi.post('/resident/activation', { uuid_locality, per_uuid, valor, description, modifier_id }, {
                headers: {
                    'Security-Token': bear_token
                }
            });
            dispatch(onStateResidentLocalityData());
        } catch (error) {
            // error.response.data?.details_error || error.response.data?.message || 
            dispatch(onErrorData(error.response.data?.details_error || error.response.data?.message || 'Lo sentimos. Algunos de nuestros procesos no funcionó.'));
            setTimeout(() => {
                dispatch(clearErrorMessage());
            }, 10);
        }
    }

    const loadDescriptionData = async (locality_uuid) => {
        try {
            let bear_token = localStorage.getItem('token')
            const { data } = await localityApi.get(`/locality/state/${locality_uuid}`, {
                headers: {
                    'Security-Token': bear_token
                }
            });
            dispatch(onLoadDescriptionData({ data: data.data }));

        } catch (error) {
            // error.response.data?.details_error || error.response.data?.message || 
            dispatch(onErrorDescriptionData(error.response.data?.details_error || error.response.data?.message || 'Lo sentimos. Algunos de nuestros procesos no funcionó.'));
            setTimeout(() => {
                dispatch(clearErrorMessageDescription());
            }, 10);
        }
    };

    const searchLocality = async ({ uuid_company, uuid_locality_catalog, manzana, villa, piso, departamento, edificio }) => {
        try {
            const dataToken = await getToken();
            if (dataToken !== '') {
                //se llama al servicio de cambio de estado de la localidad 
                const { data } = await localityApi.post('/locality/find', { uuid_company, uuid_locality_catalog, manzana, villa, piso, departamento, edificio }, {
                    headers: {
                        'Security-Token': dataToken
                    }
                });
                //console.log(searchLocality)
                dispatch(onStateLocalityData());
            } else {
                dispatch(onErrorData('Token incorrecto'));
                setTimeout(() => {
                    dispatch(clearErrorMessage());
                }, 10);
            }
        } catch (error) {
            // error.response.data?.details_error || error.response.data?.message || 
            dispatch(onErrorData(error.response.data?.details_error || error.response.data?.message || 'Lo sentimos. Algunos de nuestros procesos no funcionó.'));
            setTimeout(() => {
                dispatch(clearErrorMessage());
            }, 10);
        }
    }

    //Agregar el residente a la localidad
    const addResidentLocality = async ({ locality_uuid, identification_type, identification, names, surnames, creator_id, enterprise_uuid }) => {
        try {
            const dataToken = await getToken();
            if (dataToken !== '') {
                //se llama al servicio de cambio de estado de la localidad 
                const { data } = await localityApi.post('/locality/find', { locality_uuid, identification_type, identification, names, surnames, creator_id, enterprise_uuid }, {
                    headers: {
                        'Security-Token': dataToken
                    }
                });
                //console.log(searchLocality)
                dispatch(onSaveResidentLocalityData());
            } else {
                dispatch(onErrorData('Token incorrecto'));
                setTimeout(() => {
                    dispatch(clearErrorMessage());
                }, 10);
            }
        } catch (error) {
            // error.response.data?.details_error || error.response.data?.message || 
            dispatch(onErrorData(error.response.data?.details_error || error.response.data?.message || 'Lo sentimos. Algunos de nuestros procesos no funcionó.'));
            setTimeout(() => {
                dispatch(clearErrorMessage());
            }, 10);
        }
    }

    const updateLocalityInformation = async ({ uuid, villa, floor, department, description, address, building, longitud, latitude, locality_group_id, modifier_id, enterprise_uuid, company_uuid }) => {
        try {
            let bear_token = localStorage.getItem('token')
            const { data } = await localityApi.patch('locality/update', { uuid, villa, floor, department, description, address, building, longitud, latitude, locality_group_id, modifier_id, enterprise_uuid, company_uuid }, {
                headers: {
                    'Security-Token': bear_token
                }
            });
            dispatch(onUpdateLocalityData());
        } catch (error) {
            console.log(error)
            // error.response.data?.details_error || error.response.data?.message || 
            dispatch(onErrorData(error.response.data?.details_error || error.response.data?.message || 'Lo sentimos. Algunos de nuestros procesos no funcionó.'));
            setTimeout(() => {
                dispatch(clearErrorMessage());
            }, 10);
        }
    }

    const newLocality = async ({ villa, floor, department, description, address, building, longitud, latitude, locality_group_id, locality_notification_type, creator_id, enterprise_uuid, company_uuid }) => {
        try {
            let bear_token = localStorage.getItem('token')
            //se llama al servicio de cambio de estado de la localidad 
            const { data } = await localityApi.post('/locality/save', { villa, floor, department, description, address, building, longitud, latitude, locality_group_id, locality_notification_type, creator_id, enterprise_uuid, company_uuid }, {
                headers: {
                    'Security-Token': bear_token
                }
            });
            //console.log(searchLocality)
            dispatch(onNewLocality());
        } catch (error) {
            // error.response.data?.details_error || error.response.data?.message || 
            dispatch(onErrorData(error.response.data?.details_error || error.response.data?.message || 'Lo sentimos. Algunos de nuestros procesos no funcionó.'));
            setTimeout(() => {
                dispatch(clearErrorMessage());
            }, 10);
        }
    }

    const newMz = async ({ manzana, description, catalog_id, creator_id, enterprise_uuid, company_uuid }) => {
        try {
            let bear_token = localStorage.getItem('token')
            //se llama al servicio de cambio de estado de la localidad 
            const { data } = await localityApi.post('/locality/group/save', { manzana, description, catalog_id, creator_id, enterprise_uuid, company_uuid }, {
                headers: {
                    'Security-Token': bear_token
                }
            });
            //console.log(searchLocality)
            dispatch(onNewMz());
        } catch (error) {
            // error.response.data?.details_error || error.response.data?.message || 
            dispatch(onErrorData(error.response.data?.details_error || error.response.data?.message || 'Lo sentimos. Algunos de nuestros procesos no funcionó.'));
            setTimeout(() => {
                dispatch(clearErrorMessage());
            }, 10);
        }
    }

    const loadLocalityMz = async (company_uuid, enterprise_uuid) => {
        try {
            let bear_token = localStorage.getItem('token')
            const { data } = await localityApi.get(`locality/group/list/${company_uuid}/${enterprise_uuid}`, {
                headers: {
                    'Security-Token': bear_token
                }
            });
            dispatch(onLoadLocalityMzData({ data: data.data }));

        } catch (error) {
            // error.response.data?.details_error || error.response.data?.message || 
            dispatch(onErrorDescriptionData(error.response.data?.details_error || error.response.data?.message || 'Lo sentimos. Algunos de nuestros procesos no funcionó.'));
            setTimeout(() => {
                dispatch(clearErrorMessageDescription());
            }, 10);
        }
    };

    const localityNotificationList = async (enterprise_uuid) => {
        try {
            let bear_token = localStorage.getItem('token')
            //se llama al servicio de catalogo de notificaciones
            const { data } = await localityApi.post('/locality/catalog/notification/list', { enterprise_uuid }, {
                headers: {
                    'Security-Token': bear_token
                }
            });
            dispatch(onLoadLocalityNotificationData({ data: data.data }));
        } catch (error) {
            dispatch(onErrorData(error.response.data?.details_error || error.response.data?.message || 'Lo sentimos. Algunos de nuestros procesos no funcionó.'));
            setTimeout(() => {
                dispatch(clearErrorMessage());
            }, 10);
        }
    }

    const updateLocalityNotification = async ({ locality_notification_uuid, locality_notification_type, modifier_id }) => {
        try {
            let bear_token = localStorage.getItem('token')
            const { data } = await localityApi.post('locality/notification/update', { locality_notification_uuid, locality_notification_type, modifier_id }, {
                headers: {
                    'Security-Token': bear_token
                }
            });
            dispatch(onUpdateLocalityNotificationData());
        } catch (error) {
            console.log(error)
            // error.response.data?.details_error || error.response.data?.message || 
            dispatch(onErrorData(error.response.data?.details_error || error.response.data?.message || 'Lo sentimos. Algunos de nuestros procesos no funcionó.'));
            setTimeout(() => {
                dispatch(clearErrorMessage());
            }, 10);
        }
    }

    const preAuthorizeData = async ({ pre_locality_id }) => {
        try {
            let bear_token = localStorage.getItem('token')
            const { data } = await localityApi.post('locality/preauthorize/list', { pre_locality_id }, {
                headers: {
                    'Security-Token': bear_token
                }
            });
            //console.log(data)
            dispatch(onLoadPreauthorizeData({data : data.data}));
        } catch (error) {
            console.log(error)
            // error.response.data?.details_error || error.response.data?.message || 
            dispatch(onErrorPreauthorizedData(error.response.data?.details_error || error.response.data?.message || 'Lo sentimos. Algunos de nuestros procesos no funcionó.'));
            setTimeout(() => {
                dispatch(clearErrorMessagePreauthorized());
            }, 10);
        }
    }
    
    //CRUD Preauthorization
    //Nuevo Preautorizado Desde la Web
    const newPreauthorized = async ({ pre_type, pre_value, pre_description, pre_start_date, pre_end_date, pre_file, pre_locality_id, pre_relation, pre_creator_id, pre_company_uuid  }) => {
        try {
            let bear_token = localStorage.getItem('token')
            const { data } = await localityApi.post('locality/preauthorize/save', { pre_type, pre_value, pre_description, pre_start_date, pre_end_date, pre_file, pre_locality_id, pre_relation, pre_creator_id, pre_company_uuid}, {
                headers: {
                    'Security-Token': bear_token
                }
            });
            //console.log(data)
            dispatch(onNewPreauthorized({data : data.data}));
        } catch (error) {
            console.log(error)
            // error.response.data?.details_error || error.response.data?.message || 
            dispatch(onErrorData(error.response.data?.details_error || error.response.data?.message || 'Lo sentimos. Algunos de nuestros procesos no funcionó.'));
            setTimeout(() => {
                dispatch(clearErrorMessage());
            }, 10);
        }
    }

    //Actualizacion de data desde la WEB
    const updatePreauthorized = async ({ pre_id, pre_uuid, pre_type, pre_value, pre_description, pre_start_date, pre_end_date, pre_file, pre_locality_id, pre_relation, pre_modifier_id, pre_company_uuid  }) => {
        try {
            let bear_token = localStorage.getItem('token')
            const { data } = await localityApi.patch('locality/preauthorize/update', { pre_id, pre_uuid, pre_type, pre_value, pre_description, pre_start_date, pre_end_date, pre_file, pre_locality_id, pre_relation, pre_modifier_id, pre_company_uuid }, {
                headers: {
                    'Security-Token': bear_token
                }
            });
            //console.log(data)
            dispatch(onUpdatePreauthorized({data : data.data}));
        } catch (error) {
            console.log(error)
            // error.response.data?.details_error || error.response.data?.message || 
            dispatch(onErrorData(error.response.data?.details_error || error.response.data?.message || 'Lo sentimos. Algunos de nuestros procesos no funcionó.'));
            setTimeout(() => {
                dispatch(clearErrorMessage());
            }, 10);
        }
    }

    //Eliminacion de Preautorizado
    const deletePreauthorized = async ({ pre_uuid, pre_deleter_id, pre_company_uuid }) => {
        try {
            let bear_token = localStorage.getItem('token')
            const { data } = await localityApi.post('locality/preauthorize/delete', { pre_uuid, pre_deleter_id, pre_company_uuid }, {
                headers: {
                    'Security-Token': bear_token
                }
            });
            //console.log(data)
            dispatch(onPreauthorizeDelete({data : data.data}));
        } catch (error) {
            console.log(error)
            // error.response.data?.details_error || error.response.data?.message || 
            dispatch(onErrorData(error.response.data?.details_error || error.response.data?.message || 'Lo sentimos. Algunos de nuestros procesos no funcionó.'));
            setTimeout(() => {
                dispatch(clearErrorMessage());
            }, 10);
        }
    }
    
    //Vehiculos
    
    const clearViewData = () => {
        dispatch(clearLocalityData());
    }

    const clearPreauthorized = async () => {
        dispatch(clearPreauthorizedData());
    }

    const clearPreauthorizedStatus = async () => {
        dispatch(clearLocalityPreauthorizedStatus());
    }

    const getToken = async () => {
        let dataToken = '';
        let bear_token = localStorage.getItem('token')
        try {
            const requestToken = {
                username: 'APP_SAFEENTRY',
                password: '',
                source: {
                    name: 'APP_SAFEENTRY',
                    originID: 'APP_SAFEENTRY',
                    tipoOriginID: 'dev'
                }
            };
            const token = await tokenApi.post('/generate', requestToken, {
                headers: {
                    'Authorization': 'Bearer ' + bear_token
                }
            })
            dataToken = token.data.token;
        } catch (error) {
            console.log(error.message);
        } finally {
            return dataToken;
        }
    }

    return {
        // * Properties
        statusLocalityLoad,
        apiLocalityData,
        apiDescriptionData,
        apiLocalityStateUpdate,
        apiSearchLocalityData,
        errorMessage,
        errorMessageDescription,
        apiLocalityResidentData,
        apiLocalityMzData,
        apiLocalityNotificationData,
        apiPreauthorizeData,
        apiPreauthorizeSave,
        apiPreauthorizeEdit,
        errorMessagePreauthorized,
        errorMessageResidents,
        
        // * Methods
        clearRemoveResident,
        loadLocalityData,
        loadResidentData,
        updateStateLocality,
        removeResidentLocality,
        loadDescriptionData,
        loadResidentLocalityData,
        searchLocality,
        loadLocalityType,
        loadSearchLocalityData,
        updateLocalityInformation,
        addResidentLocality,
        loadLocalityMz,
        newMz,
        newLocality,
        localityNotificationList,
        updateLocalityNotification,
        preAuthorizeData,
        updatePreauthorized,
        newPreauthorized,
        deletePreauthorized,
        clearViewData,
        clearResidentLocality,
        clearPreauthorizedStatus,
        clearPreauthorized,
    }

}
