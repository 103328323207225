import { createSlice } from '@reduxjs/toolkit';

export const localityStateSlice = createSlice({
  name: 'localityState',
  initialState: {
    statusLocalityLoad: '0',
    apiLocalityData: {},
    apiLocalityStateUpdate: {},
    apiDescriptionData: {},
    apiSearchLocalityData: {},
    apiLocalityResidentData: {},
    apiLocalityMzData:{},
    apiLocalityNotificationData:{},
    apiNewMzData:{},
    apiPreauthorizeData:{},
    apiPreauthorizeSave:{},
    apiPreauthorizeEdit:{},
    errorMessage: undefined,
    errorMessageDescription: undefined,
    errorMessagePreauthorized: undefined,
    errorMessageResidents:undefined,
  },
  reducers: {
    
    clearResidentData:(state) =>{
      state.statusLocalityLoad='0';
      state.apiLocalityResidentData=undefined;
    },

    clearRemoveResidentData:(state) => {
      state.statusLocalityLoad='0';
    },

    onLoadSearchLocalityData: (state, { payload }) => {
      state.statusLocalityLoad = '1';
      state.apiSearchLocalityData = payload;
      state.errorMessage = undefined;
    },

    onLoadLocalityData: (state, { payload }) => {
      state.statusLocalityLoad = '1';
      state.apiLocalityData = payload;
      state.errorMessage = undefined;
    },

    onUpdateLocalityData: (state) => {
      //Aqui se cambia
      state.statusLocalityLoad = '2';
      state.errorMessage = undefined;
    },

    onNewLocality: (state) => {
      state.statusLocalityLoad = '3';
      state.errorMessage = undefined;
    },

    onLoadDescriptionData: (state, { payload }) => {
      state.statusLocalityLoad = '5';
      state.apiDescriptionData = payload;
      state.errorMessageDescription = undefined;
    },

    onLoadLocalityResidentData: (state, { payload }) => {
      state.statusLocalityLoad = '1';
      state.apiLocalityResidentData = payload;
      state.errorMessageResidents = undefined;
    },

    onLoadLocalityType: (state, { payload }) => {
      state.statusLocalityLoad = '1';
      state.apiLocalityData = payload;
      state.errorMessage = undefined;
    },

    onStateLocalityData: (state) => {
      state.statusLocalityLoad = '1';
      state.errorMessage = undefined;
    },

    //Este se llama cuando se cambia y queda guardado por eso salia antes el error 
    onUpdateStateLocality: (state, { payload }) => {
      state.statusLocalityLoad = '9';
      state.apiLocalityStateUpdate= payload;
      state.errorMessage = undefined;
    },

    onStateResidentLocalityData: (state) => {
      state.statusLocalityLoad= '4';
      state.errorMessage = undefined;
    },

    onSaveResidentLocalityData: (state) => {
      state.statusLocalityLoad = '3';
      state.errorMessage = undefined;
    },

    onErrorData: (state, { payload }) => {
      state.statusLocalityLoad = '0';
      state.errorMessage = payload;
    },

    onLoadLocalityMzData: (state, { payload }) => {
      state.statusLocalityLoad = '1';
      state.apiLocalityMzData = payload;
      state.errorMessage = undefined;
    },

    onNewMz: (state) => {
      state.statusLocalityLoad = '3';
      state.errorMessage = undefined;
    },

    onErrorDescriptionData: (state, { payload }) => {
      state.statusLocalityLoad = '0';
      state.errorMessageDescription = payload;
    },

    onLoadLocalityNotificationData: (state, { payload }) => {
      state.statusLocalityLoad = '1';
      state.apiLocalityNotificationData = payload;
      state.errorMessage = undefined;
    },

    onUpdateLocalityNotificationData: (state) => {
      state.statusLocalityLoad = '2';
      state.errorMessage = undefined;
    },

    onLoadPreauthorizeData: (state, { payload }) => {
      state.statusLocalityLoad = '1';
      state.apiPreauthorizeData = payload;
      state.errorMessagePreauthorized = undefined;
    },

    onNewPreauthorized : (state, {payload}) => {
      state.statusLocalityLoad = '3';
      state.apiPreauthorizeSave = payload;
      state.errorMessage = undefined;
    },

    onUpdatePreauthorized:(state, {payload}) => {
      state.statusLocalityLoad = '2';
      state.apiPreauthorizeEdit = payload;
      state.errorMessage = undefined;
    },
    
    onPreauthorizeDelete: (state) => {
      state.statusLocalityLoad = '4';
      state.errorMessage = undefined;
    },

    onErrorPreauthorizedData: (state, { payload }) => {
      state.statusLocalityLoad = '0';
      state.errorMessagePreauthorized = payload;
    },

    onErrorLocalityResidentData: (state, { payload }) => {
      state.statusLocalityLoad = '0';
      state.errorMessageResidents = payload;
    },

    clearErrorMessage: (state) => {
      state.errorMessage = undefined;
    },

    clearErrorMessageResident: (state) => {
      state.errorMessageResidents = undefined;
    },

    clearErrorMessageDescription: (state) => {
      state.errorMessageDescription = undefined;
    },

    clearErrorMessagePreauthorized: (state) => {
      state.errorMessagePreauthorized = undefined;
    },

    clearLocalityData: (state) => {
      state.statusLocalityLoad = '0';
      state.errorMessage = undefined;
      state.apiDescriptionData = undefined;
      //state.apiLocalityData = undefined;
    },

    clearPreauthorizedData: (state) => {
      state.statusLocalityLoad = '0';
      state.apiPreauthorizeData = undefined;
    },

    clearLocalityPreauthorizedStatus: (state) => {
      state.statusLocalityLoad = '0';
    }
  }
});

export const { clearRemoveResidentData, onStateResidentLocalityData, clearResidentData, onLoadLocalityData, onLoadLocalityResidentData, onLoadLocalityType, onErrorData, onNewLocality,
  onErrorDescriptionData, onLoadLocalityMzData, clearErrorMessage, clearErrorMessageDescription, onStateLocalityData, onLoadLocalityNotificationData, apiLocalityNotificationData,
  onLoadDescriptionData, onResidentLocalityData, onErrorLocalityResidentData, onErrorPreauthorizedData, onUpdateLocalityData, onUpdateLocalityNotificationData, onUpdateStateLocality,
  onSaveResidentLocalityData, onNewMz, onLoadSearchLocalityData, onLoadPreauthorizeData, onPreauthorizeDelete, onNewPreauthorized, onUpdatePreauthorized, clearLocalityData, clearLocalityPreauthorizedStatus, 
  clearErrorMessagePreauthorized,clearPreauthorizedData, clearErrorMessageResident } = localityStateSlice.actions;