import { createSlice } from '@reduxjs/toolkit';

export const registerSlice = createSlice({
  name: 'register',
  initialState: {
      statusRegisterLoad: '0',
      apiRegisterData: {},
      apiRegisterPhotos: {},
      errorRegisterMessage: undefined,
      errorRegisterPhotosMessage: undefined,
  },
  reducers: {
    onLoadRegisterData: (state, { payload }) => {
      state.statusRegisterLoad = '1';
      state.apiRegisterData = payload;
      state.errorRegisterMessage = undefined;
    },
    onErrorRegisterData: (state, { payload }) => {
      state.statusRegisterLoad = '0';
      state.errorRegisterMessage = payload;
    },
    clearRegisterErrorMessage: ( state ) => {
      state.errorRegisterMessage = undefined;
      state.errorRegisterPhotosMessage = undefined;
    },
    onLoadRegisterPhotosData: (state, { payload }) => {
      state.statusRegisterLoad = '1';
      state.apiRegisterPhotos= payload;
      state.errorRegisterPhotosMessage = undefined;
    },
    onErrorRegisterPhotosData: (state, { payload }) => {
      state.statusRegisterLoad = '0';
      state.errorRegisterPhotosMessage = payload;
    },
  }
});


// Action creators are generated for each case reducer function
export const { 
  onLoadRegisterData, onErrorRegisterData, 
  clearRegisterErrorMessage,
  onLoadRegisterPhotosData, onErrorRegisterPhotosData
} = registerSlice.actions;