import React from 'react'
import { Dialog } from "@progress/kendo-react-dialogs";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { Icon } from "@mui/material";
import {
  FormInput,
  FormComboBox,
} from "../../shared/form-components";

import {
  nameResidentValidator,
  identificationResidentValidator,
  emailResidentValidator,
  surnameResidentValidator,
  typeValidator,
} from "../../shared/form-validators";

export const EditFormResident = (props) => {
  //console.log(props)
  const [typeID, setTypeID] = React.useState()
  const handleChangeTypeIdentification = (event) => {
    // if (event.target.value === 'CÉDULA') {
    //   setTypeID("CED")
    // }
    // if (event.target.value === 'PASAPORTE') {
    //   setTypeID("PAS")
    // }
    // if (event.target.value === 'RUC') {
    //   setTypeID("RUC")
    // }
  }

  
  return (
    
      <Form
        onSubmit={props.onSubmit}
        initialValues={props.item}
        render={(formRenderProps) => (
          <FormElement
            style={{
              maxWidth: 500,
            }}
          >
            <fieldset className={"k-form-fieldset"}>
              <Field
                id="person_identification_type"
                data={["CÉDULA", "PASAPORTE", "RUC"]}
                name={"person_identification_type"}
                component={FormComboBox}
                placeholder="Tipo de Identificación..."
                label={"TIPO DE IDENTIFICACIÓN *"}
                onChange={handleChangeTypeIdentification}
                validator={typeValidator}
                maxLength={32}
              />
              <Field
                id={"person_identification"}
                name={"person_identification"}
                label={"CÉDULA/PASAPORTE/RUC*"}
                component={FormInput}
                validator={identificationResidentValidator}
                maxLength={32}
              />
              <Field
                id={"person_names"}
                name={"person_names"}
                label={"NOMBRES*"}
                component={FormInput}
                validator={nameResidentValidator}
                maxLength={60}
              />
              <Field
                id={"person_surnames"}
                name={"person_surnames"}
                label={"APELLIDOS*"}
                component={FormInput}
                validator={surnameResidentValidator}
                maxLength={60}
              />
              <Field
                id={"person_mail"}
                name={"person_mail"}
                label={"EMAIL*"}
                component={FormInput}
                validator={emailResidentValidator}
                maxLength={100}
              />
            </fieldset>
            <div className="k-form-buttons" style={ {marginLeft: "73%" }}>
              <button
                type={"submit"}
                className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                disabled={!formRenderProps.allowSubmit}
              >
                <Icon fontSize="small" sx={{ color: "white" }}>
                  save
                </Icon>
              </button>
              <button
                type={"submit"}
                className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                onClick={props.cancelEdit}
              ><Icon fontSize="small" sx={{ color: "white" }}>
                  cancel
                </Icon>
              </button>
            </div>
          </FormElement>
        )}
      />
    
  )
}

