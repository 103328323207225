import React from 'react'

//Telerik
import { Grid as GridTelerik, GridToolbar, GridColumn, } from "@progress/kendo-react-grid";
import { Notification, NotificationGroup } from "@progress/kendo-react-notification";
import { Dialog } from "@progress/kendo-react-dialogs";
import { Button } from "@progress/kendo-react-buttons";
import { Input, RadioButton } from "@progress/kendo-react-inputs";
import { ComboBox } from "@progress/kendo-react-dropdowns";
import { AutoComplete } from "@progress/kendo-react-dropdowns";
//mui material
import { Icon } from "@mui/material";

//hooks
import { useLocalityStateStore } from 'hooks';
import { useUserStore } from 'hooks';
import { useResidentStore } from 'hooks';

//Components

import { EditFormVehicle } from './EditFormVehicle';


export const Vehicles = ({ value_localityID, value_company, value_enterprise }) => {
    const { apiLocalityResidentData } = useLocalityStateStore();
    const { apiVehicleSave, apiVehicleData, vehicleSave, errorMessageResident, statusLoad, clearVehicleDataStatus, updateVehicle,
        loadVehicleData, apiVehicleDescription, removeVehicle, apiVehicleEdit } = useResidentStore();


    const user_uuid = localStorage.getItem("user");

    const [editItem, setEditItem] = React.useState();
    const [dataVehicles, setDataVehicles] = React.useState([])
    const [vehicleData, setVehicleData] = React.useState([])
    const [openNewVehicle, setOpenNewVehicle] = React.useState(false) //actualizado
    const [typeVehicle, setTypeVehicle] = React.useState()
    const [plateValue, setPlateValue] = React.useState()
    const [allowCustom, setAllowCustom] = React.useState(false);
    const [residentData, setResidentData] = React.useState(false)

    const [openUpdateVehicle, setOpenUpdateVehicle] = React.useState(false)
    const [identificationValue, setIdentificationValue] = React.useState("");
    const [nameValue, setNameValue] = React.useState("")
    const [content, setContent] = React.useState("<span></span>");
    const [notificationSuccess, setNotificationSuccess] = React.useState(false);
    const viewNotificationSuccess = (state) => {
        setNotificationSuccess(state)
    }

    const [notificationError, setNotificationError] = React.useState(false);
    const viewNotificationError = (state) => {
        setNotificationError(state)
    }

    const [notificationErrorQR, setNotificationErrorQR] = React.useState(false);
    const viewNotificationErrorQR = (state) => {
        setNotificationErrorQR(state)
    }


    const darkModeStyle = {
        color: "rgb(0, 0, 0)",
    };

    const position = {
        bottomRight: {
            bottom: 0,
            right: 0,
            alignItems: "flex-end",
        }
    }


    const today = new Date();
    const tomorrow = new Date();

    const [formState, setFormState] = React.useState({
        startDate: today,
        endDate: tomorrow,
        //**validar */
        //endDate: tomorrow, //reviasr
    });

    const handleChangeTypeVehicle = (event) => {
        //event.preventDefault();
        //console.log(event.target.value)
        //setTypeID(event.target.value)
        // if (event.target.value === 'CÉDULA') {
        //   setTypeID("CED")
        // }
        // if (event.target.value === 'PASAPORTE') {
        //   setTypeID("PAS")
        // }
        // if (event.target.value === 'RUC') {
        //   setTypeID("RUC")
        // }
    }


    const handleSubmit = (event) => {
        event.preventDefault();
        if ((event.target.veh_vehicle_catalog_description.value).length > 125 ) {
            setContent("Cantidad de caracteres inválida.");
            viewNotificationError(true);
            setTimeout(() => {
                viewNotificationError(false);
            }, 4000);
        } else {
            const request_saveVehicle = {
                veh_plate: ((event.target.veh_plate.value).toUpperCase()).trim(),
                veh_color: "",
                veh_vehicle_catalog_id: event.target.veh_vehicle_catalog_id.value === "AUTO" ? 1 : 2,
                veh_vehicle_catalog_description: ((event.target.veh_vehicle_catalog_description.value).toUpperCase()).trim(),
                veh_vehicle_locality_id: value_localityID,
                veh_creator_id: user_uuid,
                veh_company_uuid: user_uuid,
                veh_enterprise_uuid: value_enterprise
            }
            vehicleSave(request_saveVehicle)
        }
        //console.log(request_saveVehicle)
        
    }

    const handleChange = (event) => {
        setFormState({ ...formState, [event.target.name]: event.target.value });
    };

    const CommandCell = (props) => {
        const { dataItem } = props;
        return <td className="k-command-cell">
            <button title="Editar Vehículo" className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary k-grid-edit-command" onClick={() => enterEdit(dataItem)}
            >
                <Icon fontSize="small" sx={{ color: "white" }}>
                    edit
                </Icon>
            </button>
            <button title="Eliminar Vehículo" className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base k-grid-remove-command" onClick={() => remove(dataItem)}
            >
                <Icon fontSize="small" sx={{ color: "white" }}>
                    delete
                </Icon>
            </button>
        </td>;
    };

    const [removeItem, setRemoveItem] = React.useState(undefined);

    const remove = (dataItem) => {
        setRemoveItem(dataItem)
        const request_remove = {
            lve_vehicle_locality_id: dataItem.lve_vehicle_locality_id,
            lve_locality_id: value_localityID,
            veh_deleter_id: user_uuid,
            veh_company_uuid: value_company,
            //pre_locality_id: value_localityID
        }
        //console.log(request_remove)
        removeVehicle(request_remove)
    }


    const handleLoadInformation = () => {
        let valueVehiclePlate = document.getElementById("veh_plate").value;
        loadVehicleData(valueVehiclePlate)
    }

    const handleCancel = () => {
        setOpenNewVehicle(false)
        setNameValue("")
        setPlateValue("")
        setTypeVehicle("")
    }

    const enterEdit = (item) => {
        setOpenUpdateVehicle(true)
        setEditItem(item)
        //AQUI VA EL SERVICIO DE EDITAR VEHICULO
    }


    const [editItemData, setEditItemData] = React.useState(undefined);
    const handleSubmitEdit = (event) => {
        setEditItemData(event)
        const request_update = {
            veh_uuid: event.veh_uuid,
            veh_plate: ((event.veh_plate).toUpperCase()).trim(),
            veh_color: "",
            veh_vehicle_catalog_id: event.veh_catalog_name === "AUTO" ? 1 : 2,
            veh_vehicle_locality_id: value_localityID,
            veh_vehicle_catalog_description: ((event.veh_catalog_description).toUpperCase()).trim(),
            veh_last_modifier_id: user_uuid,
            veh_company_uuid: value_company,
            veh_enterprise_uuid: value_enterprise,
        }
        //console.log(request_update)
        updateVehicle(request_update)
    }

    const handleCancelUpdate = () => {
        setOpenUpdateVehicle(false)
    }


    const addPreauthorized = () => {
        setOpenNewVehicle(true)
        setPlateValue("")
        setNameValue("")
        setTypeVehicle("")
    }

    const handleChangeIdentification = (event) => {
        setIdentificationValue(event.target.value)
    }

    const handleChangePlateValue = (event) => {
        setPlateValue(event.target.value)
    }

    const handleChangeNameValue = (event) => {
        setNameValue(event.target.value)
    }

    const cleanForm = () => {
        setTypeVehicle("")
        setPlateValue("")
        setNameValue("")

    }


    React.useEffect(() => {
        if (apiLocalityResidentData?.data !== undefined) {
            setResidentData(apiLocalityResidentData?.data)
        } else {
            setResidentData([])
        }
    }, [apiLocalityResidentData])

    React.useEffect(() => {
        if (statusLoad === "2") {
            setContent('Registro Actualizado Exitosamente');
            viewNotificationSuccess(true);
            setTimeout(() => {
                viewNotificationSuccess(false);
            }, 4000);
            setOpenUpdateVehicle(false)
        }

        if (statusLoad === '3') {
            setContent('Registro Guardado Exitosamente');
            viewNotificationSuccess(true);
            setTimeout(() => {
                viewNotificationSuccess(false);
            }, 4000);
            setOpenNewVehicle(false)
        }

        if (statusLoad === '4') {
            const newDataState = [...dataVehicles];
            let index = newDataState.findIndex((record) => record.veh_id === removeItem.veh_id);
            newDataState.splice(index, 1);
            setDataVehicles(newDataState)
        }
        clearVehicleDataStatus()
        //clearBlackList()
    }, [statusLoad])

    React.useEffect(() => {
        if (apiVehicleEdit?.data !== undefined) {
            setVehicleData(apiVehicleEdit);
            setDataVehicles(apiVehicleEdit.data.map((dataItem) => ({
                ...dataItem
            })));
        } else {
            setVehicleData([])
            setDataVehicles([]);
        }
    }, [apiVehicleEdit])

    React.useEffect(() => {
        if (apiVehicleSave?.data !== undefined) {
            setVehicleData(apiVehicleSave);
            setDataVehicles(apiVehicleSave.data.map((dataItem) => ({
                ...dataItem
            })));
        } else {
            setVehicleData([])
            setDataVehicles([]);
        }
    }, [apiVehicleSave])


    React.useEffect(() => {
        if (apiVehicleDescription?.data !== undefined) {
            setNameValue(apiVehicleDescription?.data.veh_vehicle_catalog_description)
        } 
    }, [apiVehicleDescription])

    React.useEffect(() => {
        if (errorMessageResident !== undefined) {
            console.log(errorMessageResident);
            setContent(errorMessageResident);
            viewNotificationError(true);
            setTimeout(() => {
                viewNotificationError(false);
            }, 4000);
        }
    }, [errorMessageResident])



    React.useEffect(() => {
        if (apiVehicleData?.data !== undefined) {
            setVehicleData(apiVehicleData);
            setDataVehicles(apiVehicleData.data.map((dataItem) => ({
                ...dataItem
            })));
        } else {
            setVehicleData([])
            setDataVehicles([]);
        }
    }, [apiVehicleData])


    const vehicles_type = [
        "AUTO",
        "MOTO"
    ];

    return (
        <>
            <GridTelerik
                style={{
                    height: "420px",
                }}
                data={dataVehicles}
            >
                <GridToolbar>
                    <div>
                        <button
                            title="Add new"
                            className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                            onClick={addPreauthorized}
                        >
                            <Icon fontSize="medium" sx={{ color: "rgb(235, 234, 232)" }}>
                                add_circle
                            </Icon>
                            Agregar Vehículo
                        </button>
                    </div>
                </GridToolbar>

                <GridColumn field="veh_catalog_name" title="TIPO" />
                <GridColumn field="veh_plate" title="PLACA" />
                <GridColumn field="veh_catalog_description" title="NOMBRES" />
                <GridColumn title="OPCIONES" cell={CommandCell} width="120px" />
            </GridTelerik>

            {openNewVehicle && (
                <Dialog
                    onClose={handleCancel}
                    title={"Añadir Vehículo"}
                    width={"400px"}>
                    <form className="k-form" onSubmit={handleSubmit}
                    >
                        <div className="mb-3" >
                            <label>TIPO DE VEHÍCULO*</label>
                            <ComboBox
                                id="veh_vehicle_catalog_id"
                                placeholder="Tipo de Vehículo"
                                style={darkModeStyle}
                                data={vehicles_type}
                                allowCustom={allowCustom}
                                //value={typeVehicle}
                                onChange={handleChangeTypeVehicle}
                                required
                            />
                        </div>
                        <br />
                        <div className="mb-3">
                            <label>PLACA*</label>
                            <Input
                                id={"veh_plate"}
                                name={"veh_plate"}
                                maxLength={16}
                                validationMessage="Obligatorio"
                                onChange={handleChangePlateValue}
                                value={plateValue}
                                style={{
                                    width: "86%",
                                }}
                                required
                            />
                            <Button type="button" title="Buscar" fillMode="flat" onClick={handleLoadInformation}
                                style={{
                                    paddingTop: "10px",
                                    margin: "0"
                                }}>
                                <Icon fontSize="medium" sx={{ color: "rgb(26, 115, 232, 0.8)" }}>
                                    search
                                </Icon>
                            </Button>
                        </div>
                        <br />
                        <div className="mb-3">
                            <label>NOMBRES*</label>
                            <AutoComplete
                                id="veh_vehicle_catalog_description"
                                placeholder="Seleccione Residente"
                                style={darkModeStyle}
                                data={residentData}
                                maxLength={120}
                                textField={"field"}
                                //allowCustom={allowCustom}
                                value={nameValue}
                                onChange={handleChangeNameValue}
                                required
                            />
                        </div>
                        <div className="k-form-buttons" style={{ marginLeft: "73%" }}>

                            <Button themeColor={"primary"} type="submit" title="Guardar"
                            >
                                <Icon fontSize="small" sx={{ color: "white" }}>
                                    save
                                </Icon>
                            </Button>

                            <Button onClick={cleanForm}>
                                <Icon fontSize="small" sx={{ color: "white" }} >
                                    backspace_rounded
                                </Icon>
                            </Button>

                        </div>
                    </form>
                    {notificationError && (
                        <NotificationGroup
                            style={
                                position.bottomRight
                            }>
                            <Notification
                                key="error"
                                type={{
                                    style: "error",
                                }}

                                closable={true}
                                onClose={() => viewNotificationError(false)}
                            >
                                {content}
                            </Notification>
                        </NotificationGroup>
                    )}
                </Dialog>
            )}

            {openUpdateVehicle && (
                <EditFormVehicle
                    cancelEdit={handleCancelUpdate}
                    onSubmit={handleSubmitEdit}
                    item={editItem}
                >
                    {notificationError && (
                        <NotificationGroup
                            style={
                                position.bottomRight
                            }>
                            <Notification
                                key="error"
                                type={{
                                    style: "error",
                                }}

                                closable={true}
                                onClose={() => viewNotificationError(false)}
                            >
                                {content}
                            </Notification>
                        </NotificationGroup>
                    )}
                </EditFormVehicle>

            )}

            {notificationSuccess && (
                <NotificationGroup
                    style={
                        position.bottomRight
                    }>
                    <Notification
                        key="error"
                        type={{
                            style: "success",
                            //margin: "center"
                        }}

                        closable={true}
                        onClose={() => viewNotificationSuccess(false)}
                    >
                        {content}
                    </Notification>
                </NotificationGroup>
            )}

            {notificationError && (
                <NotificationGroup
                    style={
                        position.bottomRight
                    }>
                    <Notification
                        key="error"
                        type={{
                            style: "error",
                        }}

                        closable={true}
                        onClose={() => viewNotificationError(false)}
                    >
                        {content}
                    </Notification>
                </NotificationGroup>
            )}

        </>
    )
}
