

export class ColorTranslator {
  translateColor = (colorName) => {
    const colorMap = {
      red: 'ROJO',
      blue: 'AZUL',
      green: 'VERDE',
      yellow: 'AMARILLO',
      orange: 'NARANJA',
      purple: 'MORADO',
      pink: 'ROSA',
      brown: 'MARRÓN',
      black: 'NEGRO',
      white: 'BLANCO',
      gray: 'GRIS',
      silver: 'PLATEADO',
      gold: 'DORADO',
      grey: 'GRIS',
      maroon: 'MARRÓN',
      Indeterminado: ' ',
      // Agrega más colores y sus traducciones aquí
    };

    return colorMap[colorName] || colorName;
  };
};
