import * as React from "react";

// Material Dashboard 2 PRO React examples
import MDBox from "components/MDBox";

// @mui material components
import { Card } from "@mui/material";

// Telerik
import { Dialog } from "@progress/kendo-react-dialogs";
import { Loader } from "@progress/kendo-react-indicators";
import {
  TreeView,
  processTreeViewItems,
  handleTreeViewCheckChange,
} from "@progress/kendo-react-treeview";
import {
  Notification,
  NotificationGroup,
} from "@progress/kendo-react-notification";

import { ApiIA } from '../shared/ApiIA';

export const RecordingList = (props) => {

  const [loading, setLoading] = React.useState(false);

  const [check, setCheck] = React.useState({
    ids: [],
    applyCheckIndeterminate: true,
  });

  const onCheckChange = (event) => {
    const settings = {
      singleMode: false,
      checkChildren: true,
      checkParents: false,
    };
    setCheck(handleTreeViewCheckChange(event, check, props.listRecordings, settings));
    if(event.item.checked === undefined)
      downloadRecordings(event);
  };

  const downloadRecordings = async (videoRoutes) => {
    setLoading(true);
    const itemText = videoRoutes.item.text;
    if (itemText.endsWith(".mp4")) {
      await downloadRecording(itemText);
    } else{
      // Validar que solo se pueda descargar 10 grabaciones
      if(videoRoutes.item.items.length <= 8){
        await downloadRecordingZip(videoRoutes.item);
      }
      else{
        setContent('Puede seleccionar un máximo de 8 grabaciones, por favor.');
        setLoading(false);
        viewNotification(true);
        setTimeout(() => {
          viewNotification(false);
        }, 6000);
      }
    }
    setTimeout(() => {
      setLoading(false);
      setCheck({
        ids: [],
        applyCheckIndeterminate: true,
      });
    }, 3000);
  };

  const downloadRecording = async (videoRoute) => {
    const request = {
      apiName: props.company !== undefined && props.company !== null ? props.company.company_name.toLowerCase() : "",
      videoRoute: videoRoute
    }
    const plateData = new ApiIA();
    const result = await plateData.downloadRecording(request);
    if (!result) {
      setContent('Por favor, intente más tarde o comuníquese con el personal de soporte.')
      viewNotification(true);
      setTimeout(() => {
        viewNotification(false);
      }, 6000);
    }
  };

  const downloadRecordingZip = async (videoRoute) => {
    const request = {
      apiName: props.company !== undefined && props.company !== null ? props.company.company_name.toLowerCase() : "",
      videoRoute: videoRoute
    }
    const plateData = new ApiIA();
    const result = await plateData.downloadRecordingsZip(request);
    if (!result) {
      setContent('Por favor, intente más tarde o comuníquese con el personal de soporte.')
      viewNotification(true);
      setTimeout(() => {
        viewNotification(false);
      }, 6000);
    }
  };

  //Notificacion
  const [notification, setNotification] = React.useState(false);
  const viewNotification = (state) => {
    setNotification(state);
  };

  const position = {
    bottomRight: {
      bottom: 5,
      right: 23,
      alignItems: "flex-end",
    },
  };

  const [content, setContent] = React.useState("<span></span>");

  return (
    <>
      <MDBox pt={1} px={0}>
        <Card style={{ height: "53vh" }}>
          <TreeView
            size={"medium"} 
            data={processTreeViewItems(props.listRecordings, {
              check: check,
            })}
            checkboxes={true}
            onCheckChange={onCheckChange}
          />
        </Card>
      </MDBox>
      {notification && (
        <NotificationGroup style={position.bottomRight}>
          <Notification
            key="warning"
            type={{
              style: "warning",
              icon: true,
            }}
            closable={true}
            onClose={() => viewNotification(false)}
          >
            {content}
          </Notification>
        </NotificationGroup>
      )}
      {loading && (
        <Dialog>
          <Loader
            size="large"
            type={"converging-spinner"}
          />
        </Dialog>
      )}
    </>
  )
}
