import { createSlice } from '@reduxjs/toolkit';

export const visitSlice = createSlice({
  name: 'visit',
  initialState: {
      statusVisitLoad: '0',
      apiVisitData: {},
      apiVisitNotification: {},
      apiVisitPhotos: {},
      errorVisitMessage: undefined,
      errorVisitPhotosMessage: undefined,
      errorVisitNotificationsMessage: undefined,
      
      statusVehicleLoad: '0',
      apiVehicleData: {},
      apiVehiclePulses: {},
      errorVehicleMessage: undefined,
      errorVehiclePulsesMessage: undefined,

      statusPedestrianLoad:'0',
      apiPedestrianData: {},
      errorPedestrianMessage: undefined,

      //Multitower Visit
      statusMultitowerVisitLoad: '0',
      apiMultitowerVisitData: {},
      errorMultitowerVisitMessage: undefined,
      
      apiMultitowerAccessData: {},
      errorMultitowerAccessMessage: undefined,
  },
  reducers: {

    //Multitower Visits
    onLoadMultitowerVisitData: (state, { payload }) => {
      state.statusMultitowerVisitLoad = '1';
      state.apiMultitowerVisitData = payload;
      state.errorMultitowerVisitMessage = undefined;
    },

    onErrorMultitowerVisitData: (state, { payload }) => {
      state.statusMultitowerVisitLoad = '0';
      state.errorMultitowerVisitMessage = payload;
    },

    onLoadMultitowerAccessData: (state, { payload }) => {
      state.statusMultitowerVisitLoad = '1';
      state.apiMultitowerAccessData = payload;
      state.errorMultitowerAccessMessage = undefined;
    },

    onErrorMultitowerAccessData: (state, { payload }) => {
      state.statusMultitowerVisitLoad = '0';
      state.errorMultitowerAccessMessage = payload;
    },



    //Safe Peatonal
    onLoadPedestrianData: (state, { payload }) => {
      state.statusPedestrianLoad = '1';
      state.apiPedestrianData = payload;
      state.errorPedestrianMessage = undefined;
    },
    onErrorPedestrianData: (state, { payload }) => {
      state.statusPedestrianLoad = '0';
      state.errorPedestrianMessage = payload;
    },
    clearPedestrianErrorMessage: ( state ) => {
      state.errorPedestrianMessage = undefined;
    },

    onLoadVisitData: (state, { payload }) => {
      state.statusVisitLoad = '1';
      state.apiVisitData = payload;
      state.errorVisitMessage = undefined;
    },
    onErrorVisitData: (state, { payload }) => {
      state.statusVisitLoad = '0';
      state.errorVisitMessage = payload;
    },

    clearVisitErrorMessage: ( state ) => {
      state.errorMultitowerVisitMessage = undefined;
      state.errorMultitowerAccessMessage = undefined;
      state.errorVisitMessage = undefined;
      state.errorVisitPhotosMessage = undefined;
      state.errorVisitNotificationsMessage = undefined;
    },

    onLoadVisitPhotosData: (state, { payload }) => {
      state.statusVisitLoad = '1';
      state.apiVisitPhotos= payload;
      state.errorVisitPhotosMessage = undefined;
    },

    onErrorVisitPhotosData: (state, { payload }) => {
      state.statusVisitLoad = '0';
      state.errorVisitPhotosMessage = payload;
    },

    onLoadVisitNotificationsData: (state, { payload }) => {
      state.statusVisitLoad = '1';
      state.apiVisitNotification= payload;
      state.errorVisitNotificationsMessage = undefined;
    },

    onErrorVisitNotificationsData: (state, { payload }) => {
      state.statusVisitLoad = '0';
      state.errorVisitNotificationsMessage = payload;
    },

    onLoadVehicleData: (state, { payload }) => {
      state.statusVehicleLoad = '1';
      state.apiVehicleData = payload;
      state.errorVehicleMessage = undefined;
    },

    onLoadVehiclePulsesData: (state, { payload }) => {
      state.statusVehicleLoad = '1';
      state.apiVehiclePulses= payload;
      state.errorVehiclePulsesMessage = undefined;
    },

    onErrorVehicleData: (state, { payload }) => {
      state.statusVehicleLoad = '0';
      state.errorVehicleMessage = payload;
    },

    onErrorPulsesData: (state, { payload }) => {
      state.statusVehicleLoad = '0';
      state.errorVehiclePulsesMessage = payload;
    },

    clearVehicleErrorMessage: ( state ) => {
      state.errorVehicleMessage = undefined;
      state.errorVehiclePulsesMessage = undefined;
    }
  
  }
});


// Action creators are generated for each case reducer function
export const {onLoadMultitowerAccessData, onErrorMultitowerAccessData, onLoadMultitowerVisitData, onErrorMultitowerVisitData, onLoadPedestrianData, onErrorPedestrianData, clearPedestrianErrorMessage,
  onLoadVisitData, onErrorVisitData, 
  clearVisitErrorMessage, onErrorPulsesData,
  onLoadVisitPhotosData, onErrorVisitPhotosData,
  onLoadVisitNotificationsData, onErrorVisitNotificationsData, onLoadVehicleData, onErrorVehicleData, clearVehicleErrorMessage, onLoadVehiclePulsesData,
} = visitSlice.actions;