
import React from "react";


// Material Dashboard 2 PRO React components
import MDTypography from "components/MDTypography";

// import MDInput from "components/MDInput";

import { Input } from "@progress/kendo-react-inputs";
import { Button } from "@progress/kendo-react-buttons";
import { Loader } from '@progress/kendo-react-indicators';

// Authentication layout components
//import IllustrationLayout from "auth/pages/IllustrationLayout";
import IllustrationLayoutRecoveryPassword from "auth/pages/IllustrationLayoutRecoveryPassword";

// Images
import bgImage from "assets/images/safe-entry/login_new.jpg";

// Import Pages
import { CodeRecoveryPageApp } from "auth/pages/CodeRecoveryPageApp";


//import { tokenApi } from "api";
// import Recover from "../recover";

// const baseUrl = "http://localhost:1014/user/password/recovery";
// const baseUrl2 = "http://localhost:1014/user/password/set";


//Telerik
import { NotificationGroup, Notification } from '@progress/kendo-react-notification';

//hooks
import { useUserStore } from "hooks/useUserStore";

export default function RecoveryPage() {

  const position = {
    bottomRight: {
      bottom: 0,
      right: 0,
      alignItems: "flex-end",
    },
    topRight: {
      top: 5,
      left: "95%",
      alignItems: "flex-end",
    },
    bottomLeft: {
      bottom: 0,
      left: "92%",
      alignItems: "flex-start",
    },
  }

  const { clearRecoveryPage, sendEmail, apiPasswordData, errorUserMessage, statusUserLoad, statusUserCode } = useUserStore();
  const [recoveryPasswordForm, setRecoveryPasswordForm] = React.useState(true)
  // const [loginForm, setLoginForm] = React.useState(false)
  const [recoveryAction, setRecoveryAction] = React.useState(false)
  const [codeRecoveryAction, setCodeRecoveryAction] = React.useState(false)
  const [email, setEmail] = React.useState()
  const [content, setContent] = React.useState();
  const [buttonState, setButtonState] = React.useState(true)
  const [loadingState, setLoadingState] = React.useState(false)

  const handleChangeEmail = (event) => {
    //console.log(event.target.value)
    setEmail(event.target.value)
  }


  const handleRecovery = (event) => {
    event.preventDefault()
    sendEmail(email);
    //console.log(statusUserLoad);
    setButtonState(false)
    setLoadingState(true)
  }

  const [notification, setNotification] = React.useState(false)

  // const handleContinueRecovery = () => {
  //   setCodeRecoveryAction(true)
  //   //setLoginForm(false)
  //   setRecoveryAction(false)
  //   setRecoveryPasswordForm(false)
  // }

  const viewNotification = (state) => {
    setNotification(state)
  }

  React.useEffect(() => {
    if (errorUserMessage !== undefined) {
      setContent("Correo Electrónico No Registrado")
      setCodeRecoveryAction(false)
      setRecoveryPasswordForm(true)
      setLoadingState(false)
      setButtonState(true)
      console.log(errorUserMessage)
      setNotification(true)
      setTimeout(() => {
        viewNotification(false)
      }, 4000);
    }
  }, [errorUserMessage])

  React.useEffect(() => {
    //console.log(email)
    if (statusUserLoad === '1') {
      setCodeRecoveryAction(true)
      setRecoveryPasswordForm(false)
      clearRecoveryPage();
    }
  }, [statusUserLoad])


  return (
    <IllustrationLayoutRecoveryPassword
      title="SAFE ENTRY"
      //description="Ingrese su correo electrónico y contraseña para iniciar sesión"
      illustration={bgImage}
    >
      <>
        <div className="row">
          <div className="offset-sm-12">
            {recoveryPasswordForm && (
              <form className="k-form" onSubmit={handleRecovery}
              >
                <div>
                  <MDTypography
                    // to="/safeentry/recovery"
                    variant="h3"
                    color="light"
                    fontWeight="medium"
                    textGradient
                  >
                    Restablecer Contraseña
                  </MDTypography>
                  <br />
                  <br />
                  <MDTypography
                    // to="/safeentry/recovery"
                    variant="h6"
                    color="light"
                    fontWeight="medium"
                    textGradient
                  >
                    Ingrese su usuario y le enviaremos un correo electrónico para restablecer su contraseña
                  </MDTypography>
                  <br />
                  <br />
                  <MDTypography
                    // to="/safeentry/recovery"
                    variant="button"
                    color="light"
                    fontWeight="medium"
                    textGradient
                  >
                    Usuario
                  </MDTypography>

                  <Input
                    validityStyles={false}
                    name="email"
                    type="email"
                    style={{
                      width: "100%",
                      color: "rgb(255, 255, 255)",
                      backgroundColor: "rgb(102, 105, 98, 0.6)",
                      borderRadius: "15px",
                      borderColor: "rgb(255, 255, 255, 0.3)",
                    }}
                    required
                    //value={email}
                    onChange={handleChangeEmail}
                    validationMessage="Obligatorio"
                  />
                  {notification && (
                    <NotificationGroup
                      style={
                        position.bottomRight
                      }>
                      <Notification
                        key="error"
                        type={{
                          style: "error",
                          //margin: "center"
                        }}

                        closable={true}
                        onClose={() => viewNotification(false)}
                      >
                        {content}
                      </Notification>

                    </NotificationGroup>
                  )
                  }
                </div>
                <br />
                <div className="k-form-buttons">
                  {buttonState && (
                    <Button
                      style={{
                        width: "100%",
                        height: "42px",
                        color: "rgb(255, 255, 255)",
                        backgroundColor: "rgb(0, 0, 0)",
                        borderRadius: "15px",
                        borderColor: "rgb(102, 105, 98, 0.6)",
                      }}
                      //className="buttons-container-button"
                      // themeColor={"dark"}
                      size="small"
                      type="submit"
                    //disabled={buttonState}
                    //onClick={handleContinueRecovery}
                    //disabled={disabledButton}
                    >
                      CONTINUAR
                    </Button>
                  )}
                  {loadingState && (
                    <Loader
                      style={{
                        width: "100%",
                        height: "42px",
                        color: "rgb(255, 255, 255)",
                        paddingLeft: "45%"
                        //backgroundColor: "rgb(0, 0, 0)",
                      }}
                      size="medium" 
                      type={"converging-spinner"}
                    />
                  )}
                </div>
              </form>
            )}
            {codeRecoveryAction && (
              <CodeRecoveryPageApp user_email={email} />
            )}
          </div>
        </div>
      </>
    </IllustrationLayoutRecoveryPassword>

  )
}


