import {
  LocalityCell,
  CameraCell,
  DateCell,
  ColorCell,
  DriverNameCell,
  DriverLastNameCell,
  AccessCell,
  GenericCell
} from "./custom-cells";

const columns = [
  {
    title: "LOCALIDAD",
    field: "locate",
    cell: LocalityCell,
    show: true,
    filter: 'text'
  },
  {
    title: "CÁMARA",
    field: "camera",
    cell: CameraCell,
    show: true,
    filter: 'text'
  },
  {
    title: "FECHA",
    field: "ts",
    cell: DateCell,
    show: true,
    filter: 'text'
  },
  {
    title: "PLACA",
    field: "plate",
    cell: GenericCell,
    show: true,
    filter: 'text'
  },
  {
    title: "TIPO VEHÍCULO",
    field: "car_type",
    cell: GenericCell,
    show: true,
    filter: 'text'
  },
  {
    title: "MARCA VEHÍCULO",
    field: "mark",
    cell: GenericCell,
    show: true,
    filter: 'text'
  },
  {
    title: "COLOR",
    field: "color",
    cell: ColorCell,
    show: true,
    filter: 'text'
  },
  {
    title: "ACCESO",
    field: "permission",
    cell: AccessCell,
    show: true,
    filter: 'text'
  },
  // {
  //   title: "NOMBRE PRO",
  //   field: "drivers",
  //   cell: DriverNameCell,
  //   show: true,
  //   filter: 'text'
  // },
  // {
  //   title: "APELLIDO PRO",
  //   field: "drivers",
  //   cell: DriverLastNameCell,
  //   show: true,
  //   filter: 'text'
  // },
  // {
  //   title: "IMAGEN PLACA",
  //   field: "crop",
  //   cell: PlateImgCell,
  //   show: true,
  // }
];
export default columns;
