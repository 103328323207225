import * as React from "react";
import moment from "moment";

import {
  Grid as GridTelerik,
  GridColumn as Column,
  GridToolbar
} from "@progress/kendo-react-grid";
import {
  ExcelExport,
  ExcelExportColumn,
} from "@progress/kendo-react-excel-export";
import { Button } from "@progress/kendo-react-buttons";
import { Dialog } from "@progress/kendo-react-dialogs";
import { ScrollView } from "@progress/kendo-react-scrollview";
import {
  Notification,
  NotificationGroup,
} from "@progress/kendo-react-notification";
import { Loader } from "@progress/kendo-react-indicators";

import columns from '../shared/columns';
import { CustomColumnMenu } from '../shared/customColumnMenu';

import { Icon } from "@mui/material";
import { DefaultPhoto } from "config/config";

import { PlatesLoader } from './plates-loader';

import excelImage from "assets/images/safe-entry/excel.png";

import { ApiIA } from '../shared/ApiIA';

import {
  PlateImgCell,
  DriverNameCell,
  DriverLastNameCell
} from "../shared/custom-cells";

export const FilterPlateGrid = (props) => {

  const [dataState, setDataState] = React.useState({
    skip: 0,
    take: 5,
  });

  const [visits, setVisits] = React.useState({
    data: [],
    total: 0
  });

  const dataStateChange = (event) => {
    setDataState(event.dataState);
  };

  const VisitPhotos = ({ dataItem }) => {
    return (
      <td
        style={{
          textAlign: "center",
        }}
      >
        <Button
          title="Fotos"
          onClick={() => modalPhotos(dataItem)}
          fillMode="flat"
        >
          <Icon fontSize="small" sx={{ color: "rgb(26, 115, 232, 0.8)" }}>
            photo
          </Icon>
        </Button>
      </td>
    );
  };

  const photoBase64 = DefaultPhoto;

  const [imgStage, setImgStage] = React.useState('');
  const [photosDialog, setPhotosDialog] = React.useState(false);

  const modalPhotos = (item) => {
    setImgStage(item.stage)
    setPhotosDialog(true);
  };

  const photosToggleDialog = () => {
    setPhotosDialog(!photosDialog);
  };

  const dataReceived = visits => {
    if (visits.data !== undefined && visits.total !== undefined) {
      if (visits.total === 0) {
        setContent('No hay datos disponibles.');
        viewNotification(true);
        setTimeout(() => {
          viewNotification(false);
        }, 5000);
      }
      setVisits(visits);
    } else {
      setVisits({
        data: [],
        total: 0
      });
    }
  };

  //Notificacion
  const [notification, setNotification] = React.useState(false);
  const viewNotification = (state) => {
    setNotification(state);
  };

  const position = {
    bottomRight: {
      bottom: 5,
      right: 23,
      alignItems: "flex-end",
    },
  };

  const [content, setContent] = React.useState("<span></span>");

  const [stateColumns, setStateColumns] = React.useState(columns);

  const onColumnsSubmit = (columnsState) => {
    setStateColumns(columnsState);
  };

  const [loading, setLoading] = React.useState(false);

  const _exporter = React.useRef();

  const [dataExcel, setDataExcel] = React.useState([]);

  const excelExport = async () => {
    setLoading(true);
    // Consultar
    const request = {
      apiName: props.company !== undefined && props.company !== null ? props.company.company_name.toLowerCase() : "",
      timestamp_startDate: moment(props.dataForm.startDate).valueOf() / 1000,
      timestamp_endDate: moment(props.dataForm.endDate).valueOf() / 1000,
      searchPlate: props.dataForm.searchPlate,
      searchCarType: props.dataForm.searchCarType,
      searchColor: props.dataForm.searchColor,
      searchMark: props.dataForm.searchMark,
      searchLocate: props.dataForm.searchLocate,
      searchCamera: props.dataForm.searchCamera,
    }
    const plateData = new ApiIA();
    const data = await plateData.getDataExcel(request);
    if (data !== undefined && data !== null && data !== 0 && data.length !== 0) {
      setDataExcel(data);
      setTimeout(() => {
        save(_exporter);
      }, 3000);
    } else {
      if (data === null) {
        setContent('No hay datos disponibles.');
      }
      else if (data === undefined) {
        setContent('No hay datos disponibles.');
      }else if (data === 0){
        setContent('No hay datos disponibles.');
      }
      setLoading(false);
      viewNotification(true);
      setTimeout(() => {
        viewNotification(false);
      }, 5000);
    }
  };

  const save = (component) => {
    const options = component.current.workbookOptions();
    const rows = options.sheets[0].rows;
    let altIdx = 0;
    rows.forEach((row) => {
      if (row.type === "data") {
        row.cells.forEach((cell) => {
          if (typeof cell.value === "string") {
            cell.value = cell.value.toUpperCase();
          }
          if (typeof cell.value === 'number' && !isNaN(cell.value) && cell.value !== -1) {
            cell.value = moment.unix(cell.value).format('DD/MM/YYYY HH:mm:ss');
          }
        });
        if (altIdx % 2 !== 0) {
          row.cells.forEach((cell) => {
            cell.background = "#aabbcc";
          });
        }
        altIdx++;
      }
    });
    component.current.save(options);
  };

  const handleExport = () => {
    setLoading(false);
  };

  return (
    <>
      <div>
        <ExcelExport
          data={dataExcel}
          ref={_exporter} fileName="Reporte.xlsx" onExportComplete={handleExport} filterable={true}>
          {stateColumns.map(
            (column, idx) =>
              column.show && (
                <ExcelExportColumn
                  key={idx}
                  field={column.field}
                  title={column.title}
                />
              )
          )}
          <ExcelExportColumn field={"drivers[0].name"} title="NOMBRE PROPIETARIO" />
          <ExcelExportColumn field={"drivers[0].last_name"} title="APELLIDO PROPIETARIO" />
        </ExcelExport>
        <GridTelerik 
          pageable={true} 
          {...dataState} 
          data={visits} 
          onDataStateChange={dataStateChange} 
          style={{ height: "55vh" }}
          fixedScroll={true}>
          <GridToolbar>
            <button
              title="Exportar Excel"
              className="k-button k-button-md k-rounded-md k-button-solid"
              onClick={excelExport}
            >
              <img src={excelImage} width="auto" height="25px" alt="" />
              Excel
            </button>
          </GridToolbar>
          {stateColumns.map(
            (column, idx) =>
              column.show && (
                <Column
                  key={idx}
                  field={column.field}
                  title={column.title}
                  cell={column.cell}
                  columnMenu={(props) => (
                    <CustomColumnMenu
                      {...props}
                      columns={stateColumns}
                      onColumnsSubmit={onColumnsSubmit}
                    />
                  )}
                />
              )
          )}
          <Column field={"drivers"} title="NOMBRE PROPIETARIO" cell={DriverNameCell} />
          <Column field={"drivers"} title="APELLIDO PROPIETARIO" cell={DriverLastNameCell} />
          <Column field={"crop"} title="IMAGEN PLACA" cell={PlateImgCell} />
          <Column
            title="FOTOS"
            width="110px"
            cell={VisitPhotos}
          />
        </GridTelerik>
        <PlatesLoader dataState={dataState} onDataReceived={dataReceived} dataForm={props.dataForm} company={props.company} />
      </div>
      {photosDialog && (
        <Dialog title="FOTOS" onClose={photosToggleDialog}>
          <ScrollView
            style={{
              width: 650,
              height: 450,
            }}
            pagerOverlay="dark"
          >
            <div className="image-with-text" key='1'>
              <h2
                style={{
                  color: "#fff",
                  textAlign: "center",
                  padding: "0px",
                  margin: "0px",
                  backgroundColor: "rgba(0, 0, 0, 0.8)",
                  fontSize: "20px",
                }}
              >
                Vehículo
              </h2>
              <img
                src={imgStage}
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null;
                  currentTarget.src = photoBase64;
                }}
                alt=''
                style={{
                  width: 650,
                  height: 450,
                }}
                draggable={false}
              />
            </div>
          </ScrollView>
        </Dialog>
      )}
      {notification && (
        <NotificationGroup style={position.bottomRight}>
          <Notification
            key="warning"
            type={{
              style: "warning",
              icon: true,
            }}
            closable={true}
            onClose={() => viewNotification(false)}
          >
            {content}
          </Notification>
        </NotificationGroup>
      )}
      {loading && (
        <Dialog>
          <Loader
            size="large"
            type={"converging-spinner"}
          />
        </Dialog>
      )}
    </>
  )
}
