import React from 'react'
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import md5 from "md5";
import { Buffer } from "buffer";
import { Button } from "@progress/kendo-react-buttons";

import { useForm, useAuthStore, useUserStore } from "hooks";

import { Input } from "@progress/kendo-react-inputs";
import MDTypography from "components/MDTypography";
import { NotificationGroup, Notification } from '@progress/kendo-react-notification';

import { ApiIA } from 'ia/pages/shared/ApiIA';

const loginFormFields = {
  email: "",
  password: "",
};

export const FormLogin = () => {
  const position = {
    bottomRight: {
      bottom: 0,
      right: 0,
      alignItems: "flex-end",
    },
    topRight: {
      top: 5,
      left: "95%",
      alignItems: "flex-end",
    },
    bottomLeft: {
      bottom: 0,
      left: "92%",
      alignItems: "flex-start",
    },
  }

  const [disabledButton, setDisabledButton] = useState(false);

  const { startLogin, errorMessage, user } = useAuthStore();
  const { loadCompanyData, apiCompanyData, errorUserMessage, statusUserLoad } = useUserStore();


  const { email, password, onInputChange } = useForm(loginFormFields);
  const [content, setContent] = React.useState();
  const [notification, setNotification] = React.useState(false)

  const viewNotification = (state) => {
    setNotification(state)
  }

  const loginSubmit = async (event) => {
    setDisabledButton(true);
    event.preventDefault();
    startLogin({
      user_name: email,
      password: md5(password),
      origen_ip: "127.0.0.1",
      access_type: "WEB",
    });
    // Llamar al servicio de IA para generar el token
    const tokenIA = new ApiIA();
    await tokenIA.getToken();
  };

  React.useEffect(() => {
    setDisabledButton(false);
    if (errorMessage !== undefined) {
      if (errorMessage === "'NoneType' object is not iterable"){
        setContent("No Tiene Localidad Asignada. Comuníquese con Soporte")
      } 
      if (errorMessage === "Invalid Credentials!"){
        
        setContent("Usuario/Contraseña Incorrecta")
      }
      if (errorMessage !== "'NoneType' object is not iterable" && errorMessage !== "Invalid Credentials!") {
        setContent(errorMessage)
      }
      setNotification(true)
      setTimeout(() => {
        setNotification(false);
      }, 4000);
    }
  }, [errorMessage]);

  useEffect(() => {
    //console.log(user.token)
    if (user.token !== undefined) {
      const enterprises = Buffer.from(
        localStorage.getItem("key1"),
        "base64"
      ).toString("ascii");

      const dataEnterprises = JSON.parse(enterprises);
      //console.log(dataEnterprises)
      //console.log(dataEnterprises.length)

      localStorage.setItem(
        "enterprise-select",
        dataEnterprises[0].enterprise_id,
      );
      const companies = Buffer.from(
        localStorage.getItem("key2"),
        "base64"
      ).toString("ascii");
        //console.log(companies)
      const dataCompanies = JSON.parse(companies);
      //console.log(dataCompanies)
      localStorage.setItem("company-select", dataCompanies[0].company_id);


      const lables = Buffer.from(
        localStorage.getItem("key3"),
        "base64"
      ).toString("ascii");
      //console.log(lables)
      //const dataLables = JSON.parse(lables);
      localStorage.setItem("label-select", lables);
      
      
      //localStorage.setItem("label-select", dataLabels);

      document.location.href = "/safeEntry/estadoLocalidad";
      // localStorage.setItem("lac-select", dataLables.lac_id
      // );

    };

  }, [user]);


  return (
    <div className="row">
      <div className="offset-sm-12">
        <form className="k-form" onSubmit={loginSubmit}>
          <div>
            <MDTypography
              // to="/safeentry/recovery"
              variant="button"
              color="light"
              fontWeight="medium"
              textGradient
            >
              Usuario
            </MDTypography>
            <Input
              validityStyles={false}
              name="email"
              type="email"
              style={{
                width: "100%",
                color: "rgb(255, 255, 255)",
                backgroundColor: "rgb(102, 105, 98, 0.6)",
                borderRadius: "15px",
                borderColor: "rgb(255, 255, 255, 0.3)",
              }}
              required={true}
              value={email}
              onChange={onInputChange}
              validationMessage="Formato Incorrecto"
            />
          </div>
          <br />
          <div>
            <MDTypography
              // to="/safeentry/recovery"
              variant="button"
              color="light"
              fontWeight="medium"
              textGradient
            >
              Contraseña
            </MDTypography>
            <Input
              validityStyles={false}
              name="password"
              type="password"
              style={{
                width: "100%",
                color: "rgb(255, 255, 255)",
                backgroundColor: "rgb(102, 105, 98, 0.6)",
                borderRadius: "15px",
                borderColor: "rgb(255, 255, 255, 0.3)",
              }}
              //label="Contraseña"
              required={true}
              value={password}
              onChange={onInputChange}
              validationMessage="Obligatorio"
            />
          </div>
          <br />
          <div className="buttons-container">
            <Button
              style={{
                width: "100%",
                height: "42px",
                color: "rgb(255, 255, 255)",
                backgroundColor: "rgb(0, 0, 0)",
                borderRadius: "15px",
                borderColor: "rgb(102, 105, 98, 0.6)",
              }}
              className="buttons-container-button"
              // themeColor={"dark"}
              size="small"
              type="submit"
              disabled={disabledButton}
              id="login_button"
            >
              INICIAR SESIÓN
            </Button>
          </div>
          {notification && (
            <NotificationGroup
              style={
                position.bottomRight
              }>
              <Notification
                key="error"
                type={{
                  style: "error",
                  //margin: "center"
                }}

                closable={true}
                onClose={() => viewNotification(false)}
              >
                {content}
              </Notification>

            </NotificationGroup>
          )
          }
        </form>
      </div>
    </div>
  );
};
