/** 
  All of the routes for the Material Dashboard 2 PRO React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// @mui icons
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React components
import MDAvatar from "components/MDAvatar";

// Routes components
import { VehiclePage } from "safeentry/pages/vehicle/VehiclePage"
import { VisitPage } from "safeentry/pages/visit/VisitPage"
import { VisitMultitowerPage } from "safeentry/pages/visitMultitower/VisitMultitowerPage"
import { PedestrianPage } from "safeentry/pages/pedestrian/PedestrianPage"
import { RegisterPage } from "safeentry/pages/register/RegisterPage"
import { UploadDataPage } from "safeentry/pages/dataUpload/UploadDataPage"
import { UploadPreauthorizedPage } from "safeentry/pages/dataUpload/UploadPreauthorizedPage"
import { ActivationPage } from "safeentry/pages/sync-emails/ActivationPage";

import { RegisterPlatePage } from "ia/pages/registerPlate/RegisterPlatePage"
import { FilterPlatePage } from "ia/pages/filterPlate/FilterPlatePage"
import { VideoPlatePage } from "ia/pages/videoPlate/VideoPlatePage"
import { RecordingPage } from "ia/pages/recording/RecordingPage"


//import LocalityState from "layouts/locality/CambioEstado" Se debe revisar esta linea
// import SignInBasic from "layouts/authentication/sign-in/basic";
import LoginPage from "auth/pages/LoginPage";
// import VisitApp from "layouts/visits/VisitApp";
//import ResetCover from "layouts/authentication/reset-password/cover";
import RecoveryPage from "layouts/authentication/reset-password/cover/RecoveryPage";


//import LocalitySate from "layouts/locality/state/index"
//import CompanyData from "components/Company/CompanyData";
//import LocalidadesData from "components/Company/LocalidadesData"
//import LayoutFinal from "layouts/locality/state/index";
import { LocalityStatePage } from "safeentry/pages/localityState/LocalityStatePage";
import { SearchLocalityPage } from "safeentry/pages/searchLocality/SearchLocalityPage";

import { SearchResidentPage } from "safeentry/pages/resident/SearchResidentPage";
import { BlacklistPage } from "safeentry/pages/blacklist/BlacklistPage";
import { NewResidentPage } from "safeentry/pages/resident/NewResidentPage";


// Obtener la sesion

// const userRol = localStorage.getItem("rolUser");

// console.log(userRol)

// const routes_mocoli = [ 
//   { 
//     type: "collapse",
//     name: "Usuario",
//     key: "usuario",
//     icon: <MDAvatar alt="Usuario" size="sm" />,
//     collapse: [
//       {
//         name: " Cerrar Sesión",
//         key: "logout",
//         route: "/safeentry/login",
//         component: <LoginPage />,
//       },
//       {
//         name: " Restablecer Contraseña",
//         key: "cover",
//         route: "/safeentry/recovery",
//         component: <ResetCover />,
//       },
//     ],
//   },
//   { type: "divider", key: "divider-0" },
//   {
//     type: "collapse",
//     name: "Registros",
//     key: "register",
//     icon: <Icon fontSize="medium">accessibility_new</Icon>,
//     collapse: [
//       {
//         name: " Registros",
//         key: "registros",
//         route: "/safeentry/accesos",
//         component: <RegisterPage />,
//       },
//     ],
//   },
//   { type: "divider", key: "divider-4" },
// ];

const routes_se = [ 
  { 
    type: "collapse",
    name: "Usuario",
    key: "usuario",
    icon: <MDAvatar alt="Usuario" size="sm" />,
    collapse: [
      {
        name: " Cerrar Sesión",
        key: "logout",
        route: "/safeentry/login",
        component: <LoginPage />,
      },
      {
        name: " Restablecer Contraseña",
        key: "cover",
        route: "/safeentry/recovery",
        component: <RecoveryPage />,
      },
    ],
  },
  
  { type: "divider", key: "divider-1" },
  {
    type: "collapse",
    name: "Localidades",
    key: "localidad",
    icon: <Icon fontSize="medium">maps_home_work</Icon>,
    collapse: [
      {
        name: " Estado Localidad",
        key: "estadoLocalidad",
        route: "/safeEntry/estadoLocalidad",
        component: <LocalityStatePage />,
      },
      // BusquedaLocalidades
      {
        name: " Búsqueda Localidad",
        key: "busquedaLocalidad",
        route: "/safeEntry/busquedaLocalidad",
        component: <SearchLocalityPage />,
      },

      
    ],
  },

  { type: "divider", key: "divider-2" },
  {
    type: "collapse",
    name: "Control de Accesos",
    key: "visit",
    icon: <Icon fontSize="medium">accessibility_new</Icon>,
    collapse: [
      {
        name: " Accesos Peatonal",
        key: "accesosPeatonal",
        route: "/safeentry/accesosPeatonal",
        component: <PedestrianPage />,
      },
      {
        name: " Control de Accesos",
        key: "controlDeAccesos",
        route: "/safeentry/controlDeAccesos",
        component: <VisitMultitowerPage />,
      },
      // {
      //   name: " Accesos de Visitantes",
      //   key: "accesosVisitantes",
      //   route: "/safeentry/accesosVisitantes",
      //   component: <VisitPage />,
      // },
      // {
      //   name: " Accesos de Propietarios",
      //   key: "accesosPropietarios",
      //   route: "/safeentry/accesosPropietarios",
      //   component: <VehiclePage />,
      // },
      {
        name: " Registros de Ingresos",
        key: "registros",
        route: "/safeentry/registros",
        component: <RegisterPage />,
      },
    ],
  }, 

  { type: "divider", key: "divider-3" },
  {
    type: "collapse",
    name: "Importación",
    key: "upload",
    icon: <Icon fontSize="medium">upload</Icon>,
    collapse: [
      //Carga Masiva
      {
        name: " Carga de Localidades",
        key: "cargaLocalidad",
        route: "/safeEntry/cargaLocalidad",
        component: <UploadDataPage />,
      },
      {
        name: " Carga de Preautorizados",
        key: "cargaPreautorizados",
        route: "/safeEntry/cargaPreautorizados",
        component: <UploadPreauthorizedPage />,
      },
      {
        name: " Activacion de Servicio",
        key: "activacionServicio",
        route: "/safeEntry/activacionServicio",
        component: <ActivationPage />,
      },
    ],
  },

  { type: "divider", key: "divider-4" },
  {
    type: "collapse",
    name: "Video Analítica",
    key: "lpr",
    icon: <Icon fontSize="medium">drive_eta</Icon>,
    collapse: [
      //Carga Masiva
      {
        name: " LPR",
        key: "lpr",
        route: "/safeEntry/lpr",
        component: <RegisterPlatePage />,
      },
      {
        name: " Reportes",
        key: "reportes",
        route: "/safeEntry/reportes",
        component: <FilterPlatePage />,
      },
      {
        name: " Videos",
        key: "videos",
        route: "/safeEntry/videos",
        component: <VideoPlatePage />,
      },
      {
        name: " Grabaciones",
        key: "grabaciones",
        route: "/safeEntry/grabaciones",
        component: <RecordingPage />,
      },
    ],
  },
];


const routes = routes_se;

export default routes;
