/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import { Buffer } from "buffer";

// Material Dashboard 2 PRO React examples
import MDBox from "components/MDBox";

// @mui material components
import { Card, Grid, Icon } from "@mui/material";

// Telerik
import { filterBy } from "@progress/kendo-data-query";
import { ComboBox } from "@progress/kendo-react-dropdowns";
import { DateTimePicker } from "@progress/kendo-react-dateinputs";
import { Button } from "@progress/kendo-react-buttons";
import { Input } from "@progress/kendo-react-inputs";
import { ColorPicker } from "@progress/kendo-react-inputs";

import { useUserStore } from 'hooks';

import { FilterPlateGrid } from './FilterPlateGrid';

export const FilterPlateData = () => {

  const { loadCompanyData, apiCompanyData, statusUserLoad } = useUserStore();

  const darkModeStyle = {
    color: "rgb(0, 0, 0)",
  };

  const paletteSettings = {
    palette: [
      "rgba(255, 0, 0, 1)", "rgba(0, 0, 255, 1)", "rgba(0, 128, 0, 1)", "rgba(255, 255, 0, 1)", "rgba(255, 165, 0, 1)",
      "rgba(128, 0, 128, 1)", "rgba(255, 192, 203, 1)", "rgba(165, 42, 42, 1)", "rgba(0, 0, 0, 1)", "rgba(255, 255, 255, 1)",
      "rgba(128, 128, 128, 1)", "rgba(192, 192, 192, 1)", "rgba(255, 215, 0, 1)", "rgba(128, 0, 0, 1)",
    ],
    columns: 5,
    tileSize: 30,
  };

  const colorNames = {
    "rgba(255, 0, 0, 1)": "red",
    "rgba(0, 0, 255, 1)": "blue",
    "rgba(0, 128, 0, 1)": "green",
    "rgba(255, 255, 0, 1)": "yellow",
    "rgba(255, 165, 0, 1)": "orange",
    "rgba(128, 0, 128, 1)": "purple",
    "rgba(255, 192, 203, 1)": "pink",
    "rgba(165, 42, 42, 1)": "brown",
    "rgba(0, 0, 0, 1)": "black",
    "rgba(255, 255, 255, 1)": "white",
    "rgba(128, 128, 128, 1)": "grey",
    "rgba(192, 192, 192, 1)": "silver",
    "rgba(255, 215, 0, 1)": "gold",
    // "rgba(128, 128, 128, 1)": "grey",
    "rgba(128, 0, 0, 1)": "maroon",
  };

  const userData = localStorage.getItem("user");

  const today = new Date();
  const todayStart = new Date();
  todayStart.setHours(todayStart.getHours() - 3)
  const [formState, setFormState] = React.useState({
    startDate: todayStart,
    endDate: today,
    searchLocate: "",
    searchCamera: "",
    searchPlate: "",
    searchCarType: "",
    searchMark: "",
    searchColor: "",
    searchDriverName: "",
    searchDriverLastName: ""
  });


  // TODO: Datos de las empresas segun el usuario
  const base64dataEnterprises = localStorage.getItem("key1");
  const enterprises = Buffer.from(base64dataEnterprises, "base64").toString(
    "ascii"
  );

  const jsonEntData = JSON.parse(enterprises);

  const [dataEnterprises, setDataEnterprises] = React.useState(jsonEntData.slice());
  const [enterprise, setEnterprise] = React.useState(dataEnterprises[0]);

  const filterEntData = (filter) => {
    const data = jsonEntData.slice();
    return filterBy(data, filter);
  };
  const filterEntChange = (event) => {
    const newData =
      event.filter.value.length > 2
        ? filterEntData(event.filter)
        : jsonEntData.slice();
    setDataEnterprises(newData);
  };

  const handleEntChange = React.useCallback((event) => {
    const value = event.target.value;
    if (value === null) {
      setDataCompanies([]);
      setDataCompaniesFilter([]);
      setCompany();
    } else {
      const request_companies = {
        user_uuid: userData,
        enterprise_uuid: value.enterprise_uuid,
      };
      loadCompanyData(request_companies);
    }
    setEnterprise(value);
  }, []);

  // TODO: Datos de las compañias segun el usuario

  const [dataCompaniesFilter, setDataCompaniesFilter] = React.useState();
  const [dataCompanies, setDataCompanies] = React.useState();
  const [company, setCompany] = React.useState();

  const filterConData = (filter) => {
    const data = dataCompaniesFilter.slice();
    return filterBy(data, filter);
  };
  const filterConChange = (event) => {
    const newData =
      event.filter.value.length > 2
        ? filterConData(event.filter)
        : dataCompaniesFilter.slice();
    setDataCompanies(newData);
  };

  const handleConChange = React.useCallback((event) => {
    const value = event.target.value;
    setCompany(value);
  }, []);

  const handleChange = (event) => {
    setFormState({ ...formState, [event.target.name]: event.target.value });
  };

  const [valueColor, setValueColor] = React.useState();
  const handleOnChangeColor = (event) => {
    setValueColor(event.value);
    setFormState({ ...formState, "searchColor": colorNames[event.value] });
  };

  const [showGridComponent, setShowGridComponent] = React.useState(false);
  const [showDivComponent, setShowDivComponent] = React.useState(true);

  const handleSubmit = (event) => {
    event.preventDefault();
    setShowDivComponent(false);
    setShowGridComponent(false);
    setTimeout(() => {
      setShowGridComponent(true);
    }, 100);
  };

  const handleClear = () => {
    setValueColor(null);
    setFormState({
      startDate: todayStart,
      endDate: today,
      searchLocate: "",
      searchCamera: "",
      searchPlate: "",
      searchCarType: "",
      searchMark: "",
      searchColor: "",
      searchDriverName: "",
      searchDriverLastName: ""
    });
  };

  React.useEffect(() => {
    if (statusUserLoad === "1") {
      setDataCompanies(apiCompanyData?.data);
      setCompany(apiCompanyData?.data[0]);
      setDataCompaniesFilter(apiCompanyData?.data.slice());
    } else {
      const request_companies = {
        user_uuid: userData,
        enterprise_uuid: enterprise.enterprise_uuid,
      };
      loadCompanyData(request_companies);
    }
  }, [apiCompanyData, statusUserLoad]);

  return (
    <>
      <form className="k-form" onSubmit={handleSubmit}>
        <MDBox>
          <Card>
            <MDBox p={2}>
              <Grid container spacing={1}>
                <Grid item xs={12} md={6} lg={3}>
                  <MDBox>
                    <h6 style={darkModeStyle}>
                      <b> EMPRESA </b>
                    </h6>
                    <ComboBox
                      data={dataEnterprises}
                      textField="enterprise_name"
                      filterable={true}
                      onFilterChange={filterEntChange}
                      onChange={handleEntChange}
                      placeholder="Seleccione Empresa"
                      style={darkModeStyle}
                      value={enterprise}
                      fillMode="outline"
                      required
                      validationMessage="Obligatorio"
                    />
                  </MDBox>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <MDBox>
                    <h6 style={darkModeStyle}>
                      <b> COMPAÑÍA </b>
                    </h6>
                    <ComboBox
                      data={dataCompanies}
                      textField="company_name"
                      filterable={true}
                      onFilterChange={filterConChange}
                      onChange={handleConChange}
                      placeholder="Seleccione Compañía"
                      style={darkModeStyle}
                      value={company}
                      fillMode="outline"
                      required
                      validationMessage="Obligatorio"
                    />
                  </MDBox>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <MDBox>
                    <h6 style={darkModeStyle}>
                      <b> DESDE </b>
                    </h6>
                    <DateTimePicker
                      width="90%"
                      name="startDate"
                      required
                      format={"dd-MM-yyyy HH:mm:ss"}
                      value={formState.startDate}
                      onChange={handleChange}
                      fillMode="flat"
                      validationMessage={
                        formState.startDate === null
                          ? "OBLIGATORIO"
                          : "FECHA INVÁLIDA"
                      }
                    />
                  </MDBox>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <MDBox>
                    <h6 style={darkModeStyle}>
                      <b> HASTA </b>
                    </h6>
                    <DateTimePicker
                      width="90%"
                      name="endDate"
                      required
                      format={"dd-MM-yyyy HH:mm:ss"}
                      value={formState.endDate}
                      onChange={handleChange}
                      fillMode="flat"
                      validationMessage={
                        formState.endDate === null
                          ? "OBLIGATORIO"
                          : "FECHA INVÁLIDA"
                      }
                      valid={
                        formState.startDate !== null &&
                        formState.endDate !== null &&
                        formState.startDate.getTime() <=
                        formState.endDate.getTime()
                      }
                    />
                  </MDBox>
                </Grid>
              </Grid>
            </MDBox>
          </Card>
        </MDBox>
        <MDBox py={1}>
          <Card>
            <MDBox p={2}>
              <Grid container spacing={1}>
                <Grid item xs={12} md={4} lg={3}>
                  <h6 style={darkModeStyle}>
                    <b> LOCALIDAD </b>
                  </h6>
                  <Input
                    name="searchLocate"
                    style={{
                      width: "100%",
                    }}
                    value={formState.searchLocate}
                    maxLength={128}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} md={4} lg={1.5}>
                  <h6 style={darkModeStyle}>
                    <b> CÁMARA </b>
                  </h6>
                  <Input
                    name="searchCamera"
                    style={{
                      width: "100%",
                    }}
                    value={formState.searchCamera}
                    maxLength={128}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} md={4} lg={1.5}>
                  <MDBox>
                    <h6 style={darkModeStyle}>
                      <b> PLACA </b>
                    </h6>
                    <Input
                      name="searchPlate"
                      style={{
                        width: "100%",
                      }}
                      value={formState.searchPlate}
                      maxLength={8}
                      onChange={handleChange}
                    />
                  </MDBox>
                </Grid>
                <Grid item xs={12} md={5} lg={2.5}>
                  <MDBox>
                    <h6 style={darkModeStyle}>
                      <b> TIPO DE VEHÍCULO </b>
                    </h6>
                    <Input
                      name="searchCarType"
                      style={{
                        width: "100%",
                      }}
                      value={formState.searchCarType}
                      maxLength={128}
                      onChange={handleChange}
                    />
                  </MDBox>
                </Grid>
                <Grid item xs={12} md={5} lg={2.5}>
                  <MDBox>
                    <h6 style={darkModeStyle}>
                      <b> MARCA DE VEHÍCULO</b>
                    </h6>
                    <Input
                      name="searchMark"
                      style={{
                        width: "100%",
                      }}
                      value={formState.searchMark}
                      maxLength={128}
                      onChange={handleChange}
                    />
                  </MDBox>
                </Grid>
                <Grid item xs={12} md={2} lg={1}>
                  <h6 style={darkModeStyle}>
                    <b> COLOR </b>
                  </h6>
                  <ColorPicker
                    paletteSettings={paletteSettings}
                    value={valueColor}
                    onChange={handleOnChangeColor}
                  />
                </Grid>
              </Grid>
            </MDBox>
            <MDBox p={2}>
              <Grid container spacing={1}>
                <Grid item xs={12} md={5} lg={3}>
                  <h6 style={darkModeStyle}>
                    <b> NOMBRE PROPIETARIO </b>
                  </h6>
                  <Input
                    name="searchDriverName"
                    style={{
                      width: "100%",
                    }}
                    value={formState.searchDriverName}
                    maxLength={128}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} md={5} lg={3}>
                  <h6 style={darkModeStyle}>
                    <b> APELLIDO PROPIETARIO </b>
                  </h6>
                  <Input
                    name="searchDriverLastName"
                    style={{
                      width: "100%",
                    }}
                    value={formState.searchDriverLastName}
                    maxLength={128}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} md={1.5} lg={1}>
                  <MDBox>
                    <h6 style={darkModeStyle}>
                      <b> BUSCAR </b>
                    </h6>
                    <Button
                      type="submit"
                      title="Buscar"
                      fillMode="flat"
                      id="search_button"
                    >
                      <Icon
                        fontSize="small"
                        sx={{ color: "rgb(26, 115, 232, 0.8)" }}
                      >
                        search
                      </Icon>
                    </Button>
                  </MDBox>
                </Grid>
                <Grid item xs={12} md={1.5} lg={1}>
                  <MDBox>
                    <h6 style={darkModeStyle}>
                      <b> LIMPIAR </b>
                    </h6>
                    <Button
                      type="button"
                      title="Eliminar"
                      fillMode="flat"
                      onClick={handleClear}
                    >
                      <Icon
                        fontSize="small"
                        sx={{ color: "rgb(255, 0, 0, 0.8)" }}
                      >
                        delete
                      </Icon>
                    </Button>
                  </MDBox>
                </Grid>
              </Grid>
            </MDBox>
          </Card>
        </MDBox>
      </form>
      {showDivComponent && <div> <Card style={{ height: "55vh" }}></Card> </div>}
      {showGridComponent && <FilterPlateGrid dataForm={formState} company={company} />}
    </>
  );
};
