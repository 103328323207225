import React from 'react';
import { Dialog } from "@progress/kendo-react-dialogs";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { Input, Checkbox } from "@progress/kendo-react-inputs";

// @mui icons
import Icon from "@mui/material/Icon";

export const EditForm = (props) => {
    return (
        <Dialog title={`Modificar Estado ${props.item.locality_type}`} onClose={props.cancelEdit}>
             
            {/* <Form
                onSubmit={props.onSubmit}
                initialValues={props.item}
                render={(formRenderProps) => (
                    <FormElement
                        style={{
                            maxWidth: 650,
                        }}
                    >
                        <fieldset className={"k-form-fieldset"}>
                            <div className="mb-3">
                                <Field
                                    //name={"descripcion  ? '' : '' "}
                                    component={Input}
                                    label={"Descripción De Cambio"}
                                    name="descripcion"
                                />
                            </div>
                        </fieldset>
                        <div className="k-form-buttons">
                            <button
                                type={"submit"}
                                className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                                disabled={!formRenderProps.allowSubmit}
                            > Guardar
                                <Icon fontSize="medium">save</Icon>
                            </button>
                        </div>
                    </FormElement>
                )}
            /> */}
        </Dialog>
    );
}
