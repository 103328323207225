import * as React from "react"
import { useState, useEffect } from "react";

// @mui material components
import { Card, Grid, Icon } from "@mui/material";
import { Buffer } from "buffer";
import { ComboBox } from "@progress/kendo-react-dropdowns";
import { EditForm } from "layouts/locality/EditForm";
import { Loader } from "@progress/kendo-react-indicators";
import { filterBy } from '@progress/kendo-data-query';

// @progress telerik components
import { Button } from "@progress/kendo-react-buttons";
import { Grid as GridTelerik, GridColumn as Column } from "@progress/kendo-react-grid";
import { Switch } from "@progress/kendo-react-inputs";
import { process } from "@progress/kendo-data-query";
import { Notification, NotificationGroup } from "@progress/kendo-react-notification";
import { Dialog } from "@progress/kendo-react-dialogs";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { Input } from "@progress/kendo-react-inputs";


// Material Dashboard 2 PRO React examples
import MDBox from "components/MDBox";
import { ColumnMenu } from "layouts/grid/columnMenu";
import { useLocalityStateStore } from 'hooks';
import { useUserStore } from 'hooks';
import { textAlign } from "@mui/system";
import { NotificationTime } from "config/config";
import { item } from "examples/Sidenav/styles/sidenavItem";

export const LocalityStateData = () => {

    const {
        statusLocalityLoad, loadLocalityData, apiLocalityData, errorMessage, errorMessageDescription,
        updateStateLocality, loadDescriptionData, apiDescriptionData, clearViewData, loadResidentLocalityData, apiLocalityResidentData,
        loadLocalityType,
    } = useLocalityStateStore();

    const { loadCompanyData, apiCompanyData, errorUserMessage, statusUserLoad } = useUserStore();


    const darkModeStyle = {
        color: "rgb(0, 0, 0)",
    };

    const position = {
        bottomRight: {
            bottom: 0,
            right: 0,
            alignItems: "flex-end",
        },
    }

    // Obtener los datos del localStogare de la sesion
    const base64dataEnterprises = localStorage.getItem("key1");
    const userData = localStorage.getItem("user");

    const Enterprises = Buffer.from(base64dataEnterprises, "base64").toString("ascii");
    const dataEnterprises = JSON.parse(Enterprises);

    const base64dataCompanies = localStorage.getItem("key2");
    const Companies = Buffer.from(base64dataCompanies, "base64").toString("ascii");
    const dataCompanies = JSON.parse(Companies);

    const data_labels = localStorage.getItem("key3")
    const labels = Buffer.from(data_labels, "base64").toString("ascii");
    const dataLabels = JSON.parse(labels);

    //DataResidentes
    const [enterprise, setEnterprise] = useState(dataEnterprises[0]);
    const handleEntChange = (event) => {
        setEnterprise(event.target.value !== null ? event.target.value : "");
        setCompany([])
        setFilter("")
        if (event.target.value == null) {
            loadLocalityData("", "")
        }
    };

    const [company, setCompany] = useState(dataCompanies[0]);
    const [filter, setFilter] = React.useState(dataCompanies[0].company_name);
    const [adaptiveFilter, setAdaptiveFilter] = React.useState("");
    const [labelCompany, setLabelCompany] = React.useState(
        company !== null && (company.length > 0 || company.length === undefined)
          ? dataLabels[company.company_uuid].map(({ lac_origin, lac_reference }) => ({ lac_origin, lac_reference }))
          : []
      );
    const [companiesData, setCompaniesData] = React.useState([]);
    const filterData = (f) => filterBy(companiesData.slice(), f);
    const filterChange = (event) => {
        //console.log(event)
        const request_companies = {
            user_uuid: userData,
            enterprise_uuid: enterprise.enterprise_uuid,
        };
        if (event.filter.value === '') {

            loadCompanyData(request_companies)
        }
        if (event.target.mobileMode) {
            setAdaptiveFilter(event.filter.value);
        } else {
            setFilter(event.filter.value);
            //loadCompanyData(request_companies)
        }
        setCompaniesData(filterData(event.filter))
    };

    const handleClose = () => {
        setAdaptiveFilter("");
    };

    const handleConChange = (event) => {
        
        setCompany(event.target.value !== null ? event.target.value : []);
        setFilter(event.value.company_name !== null ? event.target.company_name : "");
        setAdaptiveFilter("");
        //console.log(dataLabels[event.value.company_uuid])
        if (event.target.value === null){
            loadLocalityData("", "")
            setLocalities([])
            setLabelCompany([].map(({ lac_origin, lac_reference }) => ({ lac_origin, lac_reference })))         
        } 
        if (dataLabels[event.value.company_uuid] === undefined) {
            setLabelCompany([].map(({ lac_origin, lac_reference }) => ({ lac_origin, lac_reference })))
            setLocalities([])
            viewNotification(true)
            setErrorNotification("Error: Etiquetas No Asignadas. Comuníquese con Soporte")
            setTimeout(() => {
                viewNotification(false)
            }, 4000);
        } 
        
        else{
            setLabelCompany(dataLabels[event.value.company_uuid].map(({ lac_origin, lac_reference }) => ({ lac_origin, lac_reference })))
            
        }
        
        
        //setLabelCompanyGrid(dataLabels[event.value.company_uuid].map(({ lac_origin, lac_reference }) => ({ lac_origin, lac_reference })))
        setLocalities([])
    };

    //INICIA CAMBIO DE ESTADO DE LOCALIDAD
    const MySwitchCell = (props) => <SwitchCell {...props} enterSwitch={enterSwitch} />;

    const SwitchCell = (props) => (
        <td>
            <Button onClick={() => props.enterSwitch(props.dataItem)} fillMode="flat">
                <Switch defaultChecked={props.dataItem[props.field || ""]} onChange={handleSwitchChange} />
                <h6>{props.dataItem[props.field] ? "INACTIVO" : "ACTIVO"}</h6>
            </Button>

            {/* <Switch defaultChecked={props.dataItem[props.field || ""]} onChange={handleSwitchChange} onClick={() => props.enterSwitch(props.dataItem)}>
                <Button onClick={() => props.enterSwitch(props.dataItem)} fillMode="flat" />
            </Switch>
            <h6>{props.dataItem[props.field] ? "INACTIVO" : "ACTIVO"}</h6> */}
        </td>
    );

    const enterSwitch = (item) => {
        //console.log(item)
        setEditItem(item);
        setOpenForm(true);
    };

    const handleSwitchChange = (e) => {
        //console.log(e)
        setOpenForm(true)
    }

    const [editItem, setEditItem] = React.useState();


    const localityState = (props) => (
        <td>
            <Button title="Editar Estado" onClick={() => props.enterEdit(props.dataItem)} fillMode="flat">
                <Icon fontSize="medium" sx={{ color: "rgb(26, 115, 232, 0.8)" }}>
                    person
                </Icon>
            </Button>
        </td>
    );

    //FIN CAMBIO ESTADO LOCALIDAD
    //Se llama tabla de data
    const handleSubmit = (event) => {
        event.preventDefault();
        setType(true);
        //setVisibleResidents(false);
        //LLamar el servio del api
        loadLocalityData(enterprise.enterprise_uuid, company.company_uuid);

        //loadResidentLocalityData("b8f53730-3ae3-43c8-a50b-aa0b02c0b5be");
    };

    //ActualizarEstadoLocalidad
    const [openForm, setOpenForm] = React.useState(false);
    const [openResident, setOpenResident] = React.useState(false);

    const saveData = (event) => {
        event.preventDefault();
        const userData = localStorage.getItem("user");
        // updateStateResident({
        //     uuid_locality: event.locality_uuid,
        //     valor: !event.locality_state,
        //     description: (event.locality_description).toUpperCase(),
        //     modifier_id: userData == null ? 'Safe Entry' : userData
        // })
        // loadLocalityData(enterprise.enterprise_uuid, company.company_uuid);
    }

    const descriptionHandleSubmit = (dataItem) => {
        const userData = localStorage.getItem("user");
        const request_update_state = {
            uuid_locality: editItem.locality_uuid,
            valor: !editItem.locality_state,
            description: (dataItem.description).toUpperCase(),
            modifier_id: userData == null ? 'Safe Entry' : userData
        }
        //console.log(request_update_state)
        updateStateLocality(request_update_state)
        loadLocalityData(enterprise.enterprise_uuid, company.company_uuid);
        setOpenForm(false)
    }

    //Notificacion Ventana
    const [notification, setNotification] = React.useState(false);
    const viewNotification = (state) => {
        setNotification(state)
        //setDescriptions([])
    }
    //FIN NOTIFICACION


    //Notificacion Ventana Exitosa
    const [notificationSuccess, setNotificationSuccess] = React.useState(false);
    const viewNotificationSuccess = (state) => {
        setNotificationSuccess(state)
        //setDescriptions([])
    }

    //Fin Notificacion Ventana Exitosa

    //Loader

    //const [loader, setLoader] = React.useState(false);
    const [type, setType] = React.useState(false);

    //Muestra de descripcion
    const [visibleBinnacle, setVisibleBinnacle] = useState(false);

    const DataRegister = (props) => <DescriptionBinnacleCell {...props} enterBinnacle={enterBinnacle} />;

    const DescriptionBinnacleCell = (props) => (
        <td>
            <Button title={"Registros"} onClick={() => props.enterBinnacle(props.dataItem)} fillMode="flat">
                <Icon fontSize="small" sx={{ color: "rgb(26, 115, 232, 0.8)" }}>
                    folder
                </Icon>
            </Button>
        </td>
    );

    const enterBinnacle = (item) => {
        loadDescriptionData(item.locality_uuid);
    };

    const [visibleResidents, setVisibleResidents] = useState(false);

    const DataResident = (props) => <ResidentBinnacleCell {...props} enterResident={enterResident} />;

    const ResidentBinnacleCell = (props) => (
        <td>
            <Button title={"Residentes"} onClick={() => props.enterResident(props.dataItem)} fillMode="flat">
                <Icon fontSize="medium" sx={{ color: "rgb(26, 115, 232, 0.8)" }}>
                    person
                </Icon>
            </Button>
        </td>
    );

    const enterResident = (item) => {
        loadResidentLocalityData(item.locality_uuid)
    }

    //Finaliza Data Fecha y Descripcion par BITACORA
    const handleCancelEdit = () => {
        setOpenForm(false);
    };

    //FinActualizarEstadoLocalidad

    

    const handleClickCompany = (event) => {
        const userData = localStorage.getItem("user");
        //console.log("Hola Click")
        const request_companies = {
            user_uuid: userData,
            enterprise_uuid: enterprise.enterprise_uuid,
        };
        loadCompanyData(request_companies)
    }

    const labelGrid = labelCompany.map((etiqueta, index) => (
        <Column
            key={index}
            field={(etiqueta.lac_origin) === "MZ" ? 'locality_mz' : `locality_${((etiqueta.lac_origin).toLowerCase())}`} // Usa el valor de la etiqueta como parte del campo
            title={etiqueta.lac_reference}
            width="250px"
        />
    ));

    //Data de los residentess
    const [localities, setLocalities] = useState([]);
    const initialDataState = {
        sort: [
            {
                field: "locality_id",
                dir: "desc",
            },
        ],
        take: 6,
        skip: 0,
    };

    const [dataState, setDataState] = useState(initialDataState);
    const [descriptions, setDescriptions] = React.useState([]);
    const [resident, setResident] = React.useState([])
    const [errorNotification, setErrorNotification] = React.useState()

    React.useEffect(() => {
        if (apiLocalityData?.data !== undefined) {
            setLocalities(apiLocalityData.data);
        } else {
            setLocalities([]);
        }
        //setOpenForm(false);
        setType(false)
        setVisibleBinnacle(false)
    }, [apiLocalityData])

    React.useEffect(() => {
        //console.log(apiDescriptionData?.data)
        if (apiDescriptionData?.data !== undefined) {
            setDescriptions(apiDescriptionData.data);
            setVisibleBinnacle(true);
            setVisibleResidents(false)
            viewNotification(false);
        } else {
            setDescriptions([]);
        }
    }, [apiDescriptionData])



    useEffect(() => {
        if (errorMessageDescription !== undefined) {
            console.log(errorMessageDescription.data)
            viewNotification(true)
            setType(false)
            setErrorNotification("No hay registros en localidad")
            setTimeout(() => {
                viewNotification(false);
            }, 4000);
        }
    }, [errorMessageDescription])

    useEffect(() => {
        console.log(errorMessage)
        if (errorMessage !== undefined) {
            viewNotification(true)
            setType(false)
            setLocalities([])
            setErrorNotification("No hay datos en la localidad")
            setTimeout(() => {
                viewNotification(false);
            }, 4000);
        }
    }, [errorMessage])

    React.useEffect(() => {
        if (apiLocalityResidentData?.data !== undefined) {
            setDescriptions(apiLocalityResidentData.data);
            //loadLocalityData(enterprise.enterprise_uuid, company.company_uuid);
            setVisibleResidents(true);
            setOpenForm(false)
            //viewNotification(false);
            viewNotificationSuccess(true)
            setTimeout(() => {
                viewNotificationSuccess(false);
            }, 4000);
        } else {
            setResident([]);
        }

    }, [apiLocalityData])


    React.useEffect(() => {
        if (statusLocalityLoad === '1') {
            loadLocalityData(enterprise.enterprise_uuid, company.company_uuid);
        }

    }, [statusLocalityLoad])

    React.useEffect(() => {
        //console.log(statusUserLoad)
        //console.log(apiCompanyData?.data)
        if (statusUserLoad === '1') {
            setCompaniesData(apiCompanyData?.data)
        } else {
            setCompaniesData([])
        }
    }, [apiCompanyData])


    const toggleDialogBinnacle = () => {
        setVisibleBinnacle(false);
        clearViewData();
    }

    const toggleDialogResident = () => {
        setVisibleResidents(false);
        clearViewData();
    }

    //PRUEBAS DEL CAMBIO DE ESTADO
    const [toggle, setToggle] = useState(false);


    return (
        <>
            <form className="k-form" onSubmit={handleSubmit}>
                <MDBox py={1.5}>
                    <Card>
                        <MDBox p={2}>
                            <Grid container spacing={1}>
                                <Grid item xs={12} md={5} lg={5}>
                                    <MDBox>
                                        <h6 style={darkModeStyle}>EMPRESA</h6>
                                        <ComboBox
                                            id="cbxEnterprise"
                                            style={darkModeStyle}
                                            data={dataEnterprises}
                                            textField="enterprise_name"
                                            onChange={handleEntChange}
                                            placeholder="Seleccione Empresa"
                                            value={enterprise}
                                            fillMode="outline"
                                            required
                                            validationMessage="Obligatorio"
                                        />
                                    </MDBox>
                                </Grid>
                                <Grid item xs={12} md={5} lg={5}>
                                    <MDBox onClick={handleClickCompany}>
                                        <h6 style={darkModeStyle}>COMPAÑIA</h6>
                                        <ComboBox
                                            id="cbxCompany"
                                            style={darkModeStyle}
                                            data={companiesData}
                                            textField="company_name"
                                            onChange={handleConChange}
                                            placeholder="Seleccione Compañía"
                                            value={company}
                                            fillMode="outline"
                                            required
                                            validationMessage="Obligatorio"
                                            filterable={true}
                                            filter={filter}
                                            adaptiveFilter={adaptiveFilter}
                                            onFilterChange={filterChange}
                                            onClose={handleClose}
                                        />
                                    </MDBox>
                                </Grid>
                                <Grid item xs={12} md={1} lg={1}>
                                    <MDBox>
                                        <h6 style={darkModeStyle}>BUSCAR</h6>
                                        <Button type="submit" title="Buscar" fillMode="flat">
                                            <Icon fontSize="small" sx={{ color: "rgb(26, 115, 232, 0.8)" }}>
                                                search
                                            </Icon>
                                        </Button>
                                    </MDBox>
                                </Grid>
                            </Grid>
                        </MDBox>
                    </Card>
                </MDBox>
            </form>

            <GridTelerik
                pageable={{
                    pageSizes: [6, 10, 30, 50, 100],
                }}
                resizable
                reorderable={false}
                data={process(localities, dataState)}
                {...dataState}
                onDataStateChange={(e) => {
                    setDataState(e.dataState);
                }}
                filterable={true}
                style={{
                    height: "645px",
                    //width: "500",
                }}
            >
                <Column
                    field="locality_type"
                    title="TIPO"
                    //columnMenu={ColumnMenu}
                    width="200px"
                />
                {labelGrid}
                {/* <Column
                    field="locality_mz"
                    title="MZ"
                    width="200px"
                />
                <Column
                    field="locality_villa"
                    title="VILLA"
                    width="200px"
                />
                <Column
                    field="locality_building"
                    title="EDIFICIO"
                    width="200px"
                />

                <Column
                    field="locality_floor"
                    title="PISO"
                    width="200px"
                />
                <Column

                    field="locality_department"
                    title="DEPARTAMENTO"
                    width="200px"

                /> */}
                <Column
                    field="locality_state"
                    title="ESTADO"
                    filter="boolean"
                    width="200px"
                    cell={MySwitchCell}
                />

                <Column
                    title="BITÁCORA"
                    width="200px"
                    cell={DataRegister}
                    filterable={false}
                />

            </GridTelerik>

            {openForm && (
                <Dialog onClose={handleCancelEdit} title='Agregar el Motivo del Cambio de Estado de la Localidad'>
                    <Form
                        onSubmit={descriptionHandleSubmit}
                        initialValues={{
                            description: ""
                        }}
                        render={(formRenderProps) => (
                            <FormElement
                                style={{
                                    maxWidth: 650,
                                }}
                            >
                                <fieldset className={"k-form-fieldset"}>
                                    <div className="mb-3">
                                        <Field
                                            name={"description"}
                                            component={Input}
                                            label={"Descripción"}
                                            maxLength={100}
                                        />
                                    </div>
                                </fieldset>
                                <div className="k-form-buttons">
                                    <button
                                        type={"submit"}
                                        className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                                        disabled={!formRenderProps.allowSubmit}
                                    > Guardar
                                        <Icon fontSize="medium">save</Icon>
                                    </button>
                                </div>
                            </FormElement>
                        )}
                    />
                </Dialog>
            )}

            {visibleResidents && (
                <Dialog title={'Cambios De Estado Localidad'}
                    onClose={toggleDialogResident} >
                    <p
                        style={{
                            margin: "25px",
                            textAlign: "center",
                        }}
                    >
                    </p>
                    <GridTelerik
                        style={{
                            height: "400px",
                            maxWidth: "950px",
                        }}
                        data={resident}
                    >
                        <Column field="person_names" title="Residentes" />

                    </GridTelerik>
                </Dialog>
            )}

            {visibleBinnacle && (
                <Dialog title={'Cambios De Estado Localidad'}
                    onClose={toggleDialogBinnacle} >
                    <p
                        style={{
                            margin: "25px",
                            textAlign: "center",
                        }}
                    >
                    </p>
                    Últimos 5 Registros
                    <GridTelerik
                        style={{
                            height: "400px",
                            maxWidth: "950px",
                        }}

                        data={descriptions}
                    >
                        <Column field="locality_state_date" title="Fecha" />
                        <Column field="locality_old_state" title="Estado Anterior" />
                        <Column field="locality_current_state" title="Estado Nuevo" />
                        <Column field="locality_state_description" title="Descripción de Cambio" />
                    </GridTelerik>
                </Dialog>
            )}

            {notification && (
                <NotificationGroup
                    style={
                        position.bottomRight
                    }>
                    <Notification
                        key="error"
                        type={{
                            style: "error",
                            //margin: "center"
                        }}

                        closable={true}
                        onClose={() => viewNotification(false)}
                    >
                        {errorNotification}
                    </Notification>
                </NotificationGroup>
            )}

            {notificationSuccess && (
                <NotificationGroup
                    style={
                        position.bottomRight
                    }>
                    <Notification
                        key="error"
                        type={{
                            style: "success",
                            //margin: "center"
                        }}

                        closable={true}
                        onClose={() => viewNotificationSuccess(false)}
                    >
                        Registro Ingresado
                    </Notification>
                </NotificationGroup>
            )}

            {type && (
                <Dialog>
                    <Loader size="large" type={"converging-spinner"} />
                </Dialog>
            )}

        </>
    )
}
